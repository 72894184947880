<template>
<!-- 新闻资讯详情页 -->
  <div class="content">
    <div class="content-header">
      <Header :activeId="activeId" />
    </div>
    <div class="ndhead">
      <img src="https://img.alicdn.com/imgextra/i3/4045700848/O1CN01gPOcaV1I8QjFsAcjx_!!4045700848.jpg" alt="" />

      <div class="ndhead-text">
        <div>关于我们</div>
        <div>ABOUT US</div>
      </div>
    </div>
    <div class="nddata">
      <div class="nddlayout">
        <div class="nddleft">
          <div class="tex">
            <span>来源：{{ NewsData[NewsId].source }}</span
            ><span>发布时间：{{ NewsData[NewsId].timer }}</span>
          </div>
          <div class="tit">
            {{ NewsData[NewsId].describes }}
          </div>
          <div v-for="(v,i) in NewsData[NewsId].textData" :key="i">
            <div class="textT">{{v.textT}} </div>
            <div v-for="(item,inx) in v.text1" class="nddmsg" :key="inx">
              {{ item }}
            </div>
            <div class="nddmsg" v-if="v.textImgShow">
              <img  :src="v.textImg" alt="" />
            </div>
          </div>
        </div>
        </div>
      
      <div class="nloction"><Bottom /></div>
    </div>
    <!-- <div style="width:200px;height:200px;position:fixed;z-index: 99;font-size:100px;top:50%;">
        {{NewsId}}
      </div> -->
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Bottom from "@/components/bottomNav/Bottom.vue";
export default {
  components: {
    Header,
    Bottom,
  },
  name:"Culturedetail",
  data() {
    return {
      uid: this.$route.query.uid,
      activeId:1,
      NewsId:0,
      NewsData:[
        {
            source: "若羽臣",
            timer: "2022-01-20",
            create_time:"01-20",
            describes: "虎力全开正当时 新年礼包迎春节",
            textData:[
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/one_1.png'),
                text1:[
                "百节年为首，春节是中华民族最隆重的传统佳节。在春节期间的庆祝活动极为丰富多样，贴年红、大扫除等所有活动，有一个共同的主题——“辞旧迎新”。弘扬传统民俗并融合若羽臣企业特色的设计思路，定制新春礼包。一岁一礼，一寸欢喜。",]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/one_2.png'),
                text1:[]
              },
            ],
        },
        {
            source: "若羽臣",
            timer: "2021-12-25",
            create_time:"12-25",
            describes: "暖冬派对 全「臣」有礼",
            textData:[
              {
                textT:"",
                textImgShow:false,
                textImg:"",
                text1:[
                "平安夜，伴随圣诞欢歌与叮咚铃铛，圣诞树亮起荧荧灯光，圣诞老人悄悄走进若羽臣，在每只袜子里塞满礼物，来自陌生人的祝福带给你出其不意的欢喜，整个公司宛如充满惊喜和爱的童话世界。",
                "童话有圣诞老人，若羽臣有爱你的人。"
                ],
                
              },
              {
                textT:"全「臣」惊喜 盲选礼物山圣诞礼物",
                textImgShow:true,
                textImg:require('../assets/Culuture/two_2.png'),
                text1:[
                "",
                ]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/two_3.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/two_4.png'),
                text1:[]
              },
              {
                textT:"给雪人戴帽子 获惊喜礼物盲盒",
                textImgShow:true,
                textImg:require('../assets/Culuture/two_5.png'),
                text1:["RYCers从来都是工作玩耍两不误，参与者以套圈的方式，站在红线外扔圣诞帽子，帽子套中任何圣诞小礼盒即可带走，若套中最远处的雪人则可以获得大奖。而不管拿到什么奖品，圣诞节最重要的还是能和知己好友齐聚一堂，一起用欢笑共度佳节！"]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/two_6.png'),
                text1:[""]
              },
              {
                textT:"暖冬派对 全「臣」有礼",
                textImgShow:false,
                textImg:"",
                text1:["圣诞“暖冬派对”活动设置的盲选礼物、给雪人戴帽子游戏等环节，筑造若羽臣的暖冬童话，全【臣】的惊喜来自爱与被爱，一齐度过温暖美好的圣诞节。希望小伙伴能尽情享受节日狂欢，与好友、家人、客户同乐，就是认真的圣诞快乐！"]
              },
             
            ],
        },
        
        {
            source: "若羽臣",
            timer: "2021-12-06",
            create_time:"12-06",
            describes: "滚烫人生 无所畏惧",
            textData:[
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_1.jpg'),
                text1:[
                "2021年12月4日，我们迎来了“滚烫人生·无所畏惧”双十一表彰暨年陈颁奖大会。2021年的尾声即将来临，回顾今年，我们依旧在不断突破自我，奋勇进发，过程中也创造了越来越多喜人的成绩。其中也不可缺少若羽臣人们全力发挥的专业和优秀，努力付出和全情投入。"
                ],
                
              },
              {
                textT:"双十一表彰",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_2.png'),
                text1:[
                "今年双十一，从10月20日第一波预售，到11月11日冲刺，战线长达将近一个月的拼搏和战斗中，若羽臣秉持着“以客户为中心，以奋斗者为本”的理念，为品牌客户提供最专业的服务，为品牌销量不断带来新的突破。在这次双十一期间，有许多小伙伴凭借个人及团队的突出表现，获得来自公司的认可，借着表彰大会，给这些优秀的团队与小伙伴进行嘉奖表彰。",
                ]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_3.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_4.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_5.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_6.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_7.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_8.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_9.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_10.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_11.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_12.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_13.png'),
                text1:[""]
              },
              {
                textT:"",
                textImgShow:false,
                textImg:"",
                text1:["每一分耕耘和付出，都一定会获得回报，收获认可。若羽臣全员定能能再接再厉，坚持秉持我们“以客户为中心，以奋斗者为本”的理念，在日后的工作中继续成长，获得新的成果！"]
              },
              {
                textT:"年陈颁奖",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_14.png'),
                text1:["前有优秀的小伙伴和团队先锋创造佳绩，若羽臣的多年经营和发展中，也离不开我们的每一位奋斗者在公司日积月累下默默耕耘，在相信与坚持的力量中，把磨练挑战熬成宝贵财富。从千锤百炼中收获，挑战更高更广阔的世界，虽已不再年少轻狂，但赤子之心未曾改变。"]
              },
              {
                textT:"",
                textImgShow:false,
                textImg:"",
                text1:["若羽臣的每一位成员都是星星火种，散是满天星聚是一团火，感谢每一位在若羽臣的奋斗者一直以来的殷勤拼搏、兢业奉献，未来若羽臣与您共同见证，以客户为中心，以奋斗者为本，锦志未来我们一起走到。"]
              },
              {
                textT:"滚烫人生 无所畏惧",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_15.png'),
                text1:["人生一路走来都不容易，“辛苦”二字让我们很多人不能走自己想选的路。放下那些可以随时搁浅的借口，给自己创造滚烫的机会。让自己翻腾起来，拥抱滚烫的人生！当一个把自己的人生目标和团队联系在一起时，团队才能超越个人的局限，发挥集体的协同作用，进而产生1+1>2的效果，无论什么时候都要以客户为中心，以奋斗者为本，不断拼搏向前，奋斗永无止境，成长就永无止境，要有军队般以胜利为目标而坚决执行的信心和决心。要有对机会的高度敏感、放眼未来的战略视野、团结起来，共同奋斗，持续奋斗，不断在这个过程中乘风破浪披荆斩棘，才是滚烫人生，面对艰难亦无所畏惧！",
                "今年的徒步，并不如往常那般翻山越岭，13公里的路程，说长不长、说短不短，不管对体力还是决心都是一种考验，行走半途已是汗流浃背，大家相互鼓励，最终都克服了路上的崎岖和身体上的疲惫，在轻柔飒爽的微风和沁人心脾的花香中，圆满完成徒步项目。"
                ]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_16.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_17.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_18.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_19.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_20.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_21.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/three_22.png'),
                text1:[
                    "这次表彰大会实际上也是一次动员会，更是一次鼓劲会，每年的双十一结束后对于全体员工，都是非常值得总结、值得深思复盘的重要契机。今年的双十一呈现了更为明显的转折点，十年前双十一的业务运营模式与今年的业务模式有了翻天覆地的变化。在任何一个时代，都会有很多新奇的产业出现，慢慢发展，产业梯队浮现，大浪淘沙龙头展现，产业剧烈变革，出现新的时代，继续出现新的企业，如此循环。那在这个大循环里，公司以上市为阶梯已经到了TOP梯队，我们要拥抱变化，拥抱改革，鼓足干劲冲刺翻倍的目标。",
                    "若羽臣的发展道路需要我们所有小伙伴齐心向前，希望若羽臣的全体伙伴以这次表彰大会为契机，大力弘扬以客户为中心以奋斗者为本的精神，脚踏实地，真抓实干，为年轻的自己在拼搏的年华里不留遗憾！若羽臣对外一定要以客户为中心，以客户的需求为目标，以新的服务产品去实现客户的需求！而未来的竞争是管理的竞争，要在管理上与对手拉开差距，那管理就是对员工的要秉承以奋斗者为本！我们鼓励大家用奋斗者的开放的心态迎接最有挑战的工作，这不仅仅是思想意志的锻炼，而且是业务的需要。",
                    "回首过去的一个多月，公司一直宣导要以客户为中心，以奋斗者为本，树立了明确的使命和价值观，双十一期间大家积极面对困难，迎难而上，克服了诸多不利因素，各项工作取得了长足进展。受到表彰的优秀员工及团队的荣誉是来之不易的，这是辛勤劳动、踏实工作的结果，是作为一名奋斗者拼搏后的成果，也是对过去工作的充分肯定，掌声、赞歌和奖牌是对过去的总结，今后的工作任务和面临的挑战会更加艰巨而繁重。受到表彰的优秀员工及团队能倍加珍惜荣誉称号，谦虚谨慎，戒骄戒躁，始终保持先进本色，继续保持良好的精神状态和工作干劲，发挥好表率和榜样作用，倍加奋发有为、百尺竿头更进一步，树立更高的目标，取得更大的成绩。"
                ]
              },
             
            ],
        },
  {
            source: "若羽臣",
            timer: "2021-09-22",
            create_time:"09-22",
            describes: "月夜越YOUNG",
            textData:[
              {
                textT:"",
                textImgShow:false,
                textImg:"",
                text1:[
                "有人说年轻人对传统节日已经不感兴趣了，觉得只是视觉符号和形式，找个理由来聚会。弘扬民族文化，传承民族精神，过好中国传统节日，RYC打造了一个传统与潮流相结合——专属于年轻人的中秋，整一个最最最最最潮的中秋节，猜最潮的灯谜，玩最潮的游戏！"
                ],
                
              },
              {
                textT:"潮玩灯谜",
                textImgShow:true,
                textImg:require('../assets/Culuture/four_1.png'),
                text1:[
                "上百条彩纸制成的灯谜悬挂在展板上，谜面科学而通俗，猜字、猜成语、猜人名、猜地名，活动集趣味性、知识性于一体，让大家乐中有学，共同领略祖国语言的无穷奥妙。",
                ]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/four_2.png'),
                text1:["活动现场，RYCer穿梭在色彩缤纷的展板前，兴致勃勃，各显其才，纷纷抢摘谜语，投入到谜底竞猜中。"]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/four_3.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/four_4.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/four_5.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/four_6.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:true,
                textImg:require('../assets/Culuture/four_7.png'),
                text1:[]
              },
              {
                textT:"",
                textImgShow:false,
                textImg:"",
                text1:["答对谜题的小伙伴即可领取了一份小礼品，猜灯谜游戏寓教于乐，既是我国中秋节传统游戏，感受到中秋传统节日的魅力，又能增长了文化知识，现场洋溢着欢乐、祥和、喜庆的气氛。",
                "露从今夜白，月是故乡明。每逢佳节，我们总是通过活动将传统文化与年轻文化相结合，雅俗共赏的潮玩灯谜既营造了节日的气氛，又传承了传统文化。中秋已过但喜悦不减，让我们一起月夜越YOUNG！"
                ]
              },
            ],
        },


      ],
     
      
      fontSize1: 25,
      fontSize2: 18,
      pathId:0,
    };
  },
  created() {
    console.log(this.$route.query)
    this.getdata();
    this.getlist();
  },
  methods: {
    nav(e) {
      for(let i = 0;i<this.NewsData.length;i++){
        if (this.NewsData[i].create_time == e){
          this.NewsId = this.NewsData[i].id;
        }
      }
    },
  },

  mounted() {
      this.pathId = this.$route.query.id
      for(let i = 0;i<this.NewsData.length;i++){
        if (this.NewsData[i].create_time == this.pathId){
          this.NewsId = i
        }
      }

  },

};
</script>

<style scoped lang="less">
.content {
  width: 100%;
  margin: 0 auto;
   .content-header{
     width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
  }
  .ndhead {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }

     .ndhead-text{
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      // right: 0;
      margin: auto;
      text-align: center;

      div:first-child{
         color: rgb(255, 255, 255);
        //  font-size:  .2105rem;
        font-size: .3158rem;
         text-align: center;
        //  font-weight: 500;
         margin-bottom: .052632rem;
      }
      div:last-child{
        color: rgb(255, 255, 255);
        // font-size: .1368rem;
        font-size: .0842rem;
        text-align: center;
      }
    }
  }
  .nddata {
    width: 100%;
    position: relative;
    .nddlayout {
      // width: 1200px;
      width: 45%;
      margin: 0 auto;
      padding: 0 0 5% 0;
      display: flex;
      justify-content: space-between;
      .nddleft {
        // width: 854px;
        width: 100%;
        // height: 900px;
        margin-top: 8%;
        // background-color: #ff0;
        .textT{
            font-size: .1053rem;
            // font-weight: 800;
            padding-top: 3%;
            padding-bottom: 2%;
            // color: rgb(59, 59, 59);
            color:  #444444;
        }
        .tit {
          font-size: .2105rem;
          // font-weight: 800;
          // color: rgb(59, 59, 59);
          color:  #444444;
          // padding-bottom: 5%;
        }
        .tex {
          font-size: .0842rem;
          padding-bottom: 1%;
          display: flex;
          // color: #cdcdcd;
          color:  #b8b8b8;
          justify-content: flex-start;
          span {
            margin-right: 4%;
          }
        }
        .nddmsg {
          font-size: .1053rem;
          // color: rgb(148, 148, 148);
          color:  #444444;
          text-indent: 6%;
          line-height: 110%;
          img {
            width: 100%;
            display: block;
            margin: 0 auto;
            padding-top: 2%;
            padding-bottom: 4%;
          }
        }
        .sbicon {
          width: .147368rem;
          height: .336842rem;
          margin: 0 auto;
          margin-top: 10%;
         
        }
      }
      
    }

    .nloction {
      // top: 2764px;
      bottom: 0;
      left: 0;
    }
  }
}
</style>


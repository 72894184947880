<template>
  <!-- 新闻资讯 -->
  <div class="content">
    <div class="content-header">
      <Header :activeId="activeId" />
    </div>

    <div class="journhead">
      <img src="../assets/journalism/newBg.jpg" alt="" />

      <div class="journhead-text">
        <div>{{ $t("theNews.title") }}</div>
        <div>{{ $t("theNews.smallTitle") }}</div>
      </div>
      <!-- <div class="journhead-text">
        <div>新闻资讯</div>
        <div>NEWS</div>
      </div> -->
    </div>
    <div class="information" :style="{width: isMobile ? '80%':'63%'}">
      <h1>RYC <span>/</span></h1>
      <h1>NEWS INFORMATION</h1>
      <h4>{{ $t("theNews.title2") }}</h4>
    </div>
    <div class="hourncont">
      <div class="houlayout" :style="{width: isMobile ? '80%':'63%'}">
        <div class="dlist">
          <div class="hborder"></div>
          <!-- <div
            class="each"
            v-for="(item, i) in joulist"
            :key="i"
            @click="nav(i)"
          >
            <div class="time">{{ item.create_time }}</div>
            <img :src="item.cover" class="imgsize" alt="" />
            <div class="msg">
              {{ item.describes }}
              <p> {{item.describes_time}}</p>
            </div>
            
          </div> -->
          <div class="each" v-for="(item, i) in joulist" :key="i" @click="nav(i)">
            <div class="time">{{ item.YTime }}</div>
            <img :src="item.pic_url" class="imgsize" alt="" />
            <div class="msg">
              <span style="
                  display: -webkit-box;
                  -o-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  line-height: 24px;
                ">{{ item.title }}</span>
              <p>{{ item.YMDTime }}</p>
            </div>
          </div>
        </div>
        <div class="pagt">
          <el-pagination style="font-size: '24rpx'" layout="prev,pager,next" prev-text="上一页" next-text="下一页"
            :page-size="queryParam.page_size" :page-count="total_page" @prev-click="prev()" @next-click="next()"
            :total="total">
          </el-pagination>
          <!-- 下一页 <img src="../assets/journalism/arrow.png" alt="" /> -->
        </div>
      </div>
      <div class="loction">
        <div class="bottom">
          <div class="bottom-son">
            <Bottom />
          </div>
        </div>
      </div>
    </div>

    <!-- <router></router> -->
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Bottom from "@/components/bottomNav/Bottom.vue";
import { findNewsPage } from "../api/index";

export default {
  components: {
    Header,
    Bottom,
  },
  data() {
    return {
      activeId: 4,
      isMobile: false,                //是否移动端
      // globalHttpUrl: this.COMMON.httpUrl,
      joulist: [
        // {
        //   cover:require('../assets/journalism/list_1.jpg'),
        //   create_time: "2022",
        //   describes_time:"2022-01-21",
        //   title_time:"01-21",
        //   describes:
        //     "若羽臣与COSTA达成战略合作 共同打造咖啡电商新格局",
        //   link:'https://mp.weixin.qq.com/s/NYsBcC66_catlSvvT-1toA',
        //   id: 1,
        //   source: "若羽臣官网",
        //   text1:
        //     "2022年1月18日，若羽臣与COSTA正式签署了战略合作协议，若羽臣创始人兼董事长王玉与COSTA中国总经理欧阳庆球出席了仪式现场，双方宣布将在电商渠道拓展、数字营销及消费者运营等领域展开深度合作，共同打造咖啡电商合作新格局。",
        //   text2:
        //     "根据合作协议，若羽臣将围绕店铺运营、营销推广、消费者运营等方面，为COSTA提供高质量、专业化的全链路营销服务，全面支持COSTA在电商渠道的战略规划，赋能COSTA销售业绩的增长。",
        //   text_img:
        //     "https://img2.baidu.com/it/u=1519358925,3330107491&fm=26&fmt=auto",
        //   title: "标题",
        // },
        // {
        //   cover:require('../assets/journalism/list_2.jpg'),
        //   create_time: "2022",
        //   describes_time:"2022-01-19",
        //   title_time:"01-19",
        //   describes:
        //     "连续获评！若羽臣再度上榜「2021 下半年天猫五星服务商」",
        //   link:'https://mp.weixin.qq.com/s/d0BR1oHCO1lDYu6DifUNqg',
        //   id: 2,
        //   source: "若羽臣官网",
        //   text1:
        //     "国务院总理李克强近日主持召开国务院常务会议,部署加快建设社会信用体系构建相适\n应的市场监管新机制;决定进一步推进通关便利化,持续优化口岸营商环境;要求更大力度\n对外开放,促进进出口多元化发展。\n会议指出,信用是市场主体安身立命之本。深化“放管服”改革,简政放权要坚持不懈,\n公正监管必须落实到位,只有管得好才能放得开。加强信用监管是基础,是健全市场体系的\n关键,可以有效提升监管效能、维护公平竟争、降低市场交易成本。一要以统一社会信用代\n码为标识,依法依规建立权威、统一、可查询的市场主体信用记录,并依法互通共享,打破\n部门垄断和信息“孤岛”。对能共享的信息不得要求市场主体重复提供。推广信用承诺制。",
        //   text2:
        //     "会议指出,按照党中央、国务院部署,相关部门积极推进通关便利化,取得明显成效\n截至今年5月,进口和出口整体通关时间已提前实现比2017年压缩一半的目标,通关成本显\n著降低。下一步,要按照推动更高水平对外开放的要求,一是继续简化一体化通关流程,实\n施进口概要申报、完整申报“两步申报”通关模式改革,大幅压缩通关时间。年底前做到国\n际贸易“单一窗口”对主要业务应用率达到100%。二是进一步简化进出口环节监管证件,年\n底前对除安全保密等特殊情况外的监管证件,提前一年全部实现网上申报、网上办理。三是\n各地要抓紧公布口岸经营服务企业操作时限标准,提高口岸作业效率。强化口岸收费目录清\n单管理,落实降费措施,兑现承诺,强化责任,并依法查处垄断收费行为。",
        //   text_img:
        //     "https://img2.baidu.com/it/u=1519358925,3330107491&fm=26&fmt=auto",
        //   title: "标题",
        // },
        // {
        //   cover:require('../assets/journalism/list_3.jpg'),
        //   create_time: "2022",
        //   describes_time:"2022-01-18",
        //   title_time:"01-18",
        //   describes:
        //     "“来穗小金雁”，情满若羽臣",
        //   link:'https://mp.weixin.qq.com/s/gD7l4TRkBuaV7zjLEuSzGA',
        //   id: 3,
        //   source: "若羽臣官网",
        //   text1:
        //     "1月15日上午，值新春开年之际，在黄埔区大沙街党工委组织号召下，若羽臣党支部践行初心使命，积极承担社会责任，参与街道“来穗小金雁”专场关爱活动，街道党工委副书记林常青，街道组织办主任赵伟斌，以及街道党员志愿者等出席本次活动。",
        //   text2:
        //     "随着我国社会经济发展的不断加速，外来务工人员早已成为城市经济建设的主力军。据了解，本次“来穗小金雁”专场关爱活动是大沙街道在年终岁尾，为帮扶来穗务工人员子女所号召举办的公益帮扶献爱心行动。作为广州市民营领军企业，若羽臣以党建工作为指引，积极响应号召，热心参与到活动中来，为帮助外来务工人员及其子女融入当地生活贡献企业的一份力量。",
        //   text_img:
        //     "https://img2.baidu.com/it/u=1519358925,3330107491&fm=26&fmt=auto",
        //   title: "标题",
        // },
        // {
        //   cover:require('../assets/journalism/list_4.jpg'),
        //   create_time: "2022",
        //   describes_time:"2022-01-17",
        //   title_time:"01-17",
        //   describes:
        //     "若羽臣入选 “个十百千万”工程 荣膺“头部直播电商机构”称号",
        //   link:'https://mp.weixin.qq.com/s/MKa8R2zu0NXXGC-8IRn-PQ',
        //   id: 4,
        //   source: "若羽臣官网",
        //   text1:
        //     "近日，以“树立直播电商行业发展标杆，助力打造直播电商之都”为目标的，广州市首批直播电商“个十百千万”工程评选结果正式公布。若羽臣背靠十余年沉淀的一站式综合电商服务能力，以出色的直播电商服务荣膺广州市“头部直播电商机构”称号。",
        //   text2:
        //     "“个十百千万”工程评选工作是依据《广州市直播电商发展行动方案（2020-2022年）》的发展目标，由广州市商务局牵头，在深入调研的基础上，组织各市直单位、广州直播电商研究院、广州市直播电子商务行业协会，以及其他行业商协会、重点企业，经过多轮研究，公开征求社会意见后，责成广州市直播电子商务行业协会发布的行业体系认证标准。",
        //   text_img:
        //     "https://img2.baidu.com/it/u=1519358925,3330107491&fm=26&fmt=auto",
        //   title: "标题",
        // },
        // {
        //   cover:require('../assets/journalism/list_5.jpg'),
        //   create_time: "2022",
        //   describes_time:"2022-01-14",
        //   title_time:"01-14",
        //   describes:
        //     "再获认可，若羽臣斩获智联招聘最佳雇主称号",
        //   link:'https://mp.weixin.qq.com/s/QghKqTb7M2IFxlXHAkJE7Q',
        //   id: 5,
        //   source: "若羽臣官网",
        //   text1:
        //     "1月14日下午，由智联招聘、北京大学社会调查研究中心等机构联合主办的，“2021中国年度最佳雇主颁奖盛典暨中国人力资源国际管理论坛”于广州落下帷幕。头部电子商务综合服务商若羽臣，凭借良好的业界口碑及具有成长潜力的员工培养模型，荣膺2021最佳雇主称号。",
        //   text2:
        //     "据悉，“中国年度最佳雇主”是由中国领先的人力资本生态价值链平台智联招聘，联合北京大学社会调查研究中心于2005年共同发起，旨在以独立客观的立场，通过专业科学的调查评选，发现中国雇主品牌标杆企业，挖掘其在企业管理和人力资本领域的发展趋势，从而为更多企业在管理及雇主品牌建设方面提供参考的专业性奖项。若羽臣在荣膺中企联合最佳雇主称号后，接连又将智联招聘最佳雇主称号收入囊中，再次印证人力资源行业对于若羽臣人才建设、培养以及预留等工作的充分肯定。",
        //   text_img:
        //     "https://img2.baidu.com/it/u=1519358925,3330107491&fm=26&fmt=auto",
        //   title: "标题",
        // },
        // {
        //   cover:require('../assets/journalism/list_6.jpg'),
        //   create_time: "2022",
        //   describes_time:"2022-01-11",
        //   title_time:"01-11",
        //   describes:
        //     "精进的力量 若羽臣荣膺“最佳母婴TP服务商”称号",
        //   link:'https://mp.weixin.qq.com/s/9R1FFZ4J1MsGfJ9QqtQJ4Q',
        //   id: 6,
        //   source: "若羽臣官网",
        //   text1:
        //     "1月11日，以“精进的力量”为主题的2021第七届未来母婴大会暨樱桃大赏年度盛典落下帷幕，头部电商综合运营服务商若羽臣荣膺最佳母婴TP服务商称号。",
        //   text2:
        //     "据悉，大会由中国最具影响力的母婴产业平台、母婴行业领域垂直媒体母婴行业观察主办。自2014年成立以来，母婴行业观察已发展成国内母婴行业风向标内容产业平台、智库，专注在母婴商业领域的创业创新。而其主办的未来母婴大会，也成为国内母婴人一年一度的重磅盛会。凭借在母婴领域深耕细作的运营能力沉淀，以及合作品牌对服务能力的充分认可，若羽臣在本次大会上将2021年度最佳母婴TP服务商称号收入囊中，再次巩固了若羽臣在母婴行业品牌电商服务赛道的优势地位。",
        //   text_img:
        //     "https://img2.baidu.com/it/u=1519358925,3330107491&fm=26&fmt=auto",
        //   title: "标题",
        // },
      ],
      total: 1,
      queryParam: {
        page_no: 1,
        page_size: 10,
      },
      total_page: 1,
    };
  },
  created() {
    this.getList();
    //  console.log('Node：', document.getElementsByClassName('.el-pagination').getElementsByClassName('.btn-prev'))

  },
  methods: {
    renderYear(date) {
      const dateee = new Date(date).toJSON();
      let d = new Date(+new Date(dateee) + 8 * 3600 * 1000);
      return d.getFullYear();
    },
    // renderTime(date) {
    //   const dateee = new Date(date).toJSON();
    //   let d = new Date(+new Date(dateee) + 8 * 3600 * 1000);
    //   let Year1 = d.getFullYear();
    //   let Mouth1 =
    //     d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
    //   let Date1 = d.getDate() >= 10 ? d.getDate() : "0" + d.getDate();
    //   return Year1 + "-" + Mouth1 + "-" + Date1;
    // },
    renderTime(date) {
      const dateee = new Date(date)
      const Year1 = dateee.getFullYear()
      const Mouth1 = dateee.getMonth() + 1 >= 10 ? dateee.getMonth() + 1 : '0' + (dateee.getMonth() + 1)
      const Date1 = dateee.getDate() >= 10 ? dateee.getDate() : '0' + dateee.getDate()
      return Year1 + '-' + Mouth1 + '-' + Date1
    },
    prev() {
      this.queryParam.page_no--;
      this.getList();
    },
    next() {
      this.queryParam.page_no++;
      this.getList();
    },

    nav(e) {
      window.open(this.joulist[e].out_url);
    },
    getList() {
      findNewsPage(this.queryParam).then((res) => {
        const data = res.data;
        let rows = data.rows;
        rows.map((v) => {
          v.YTime = this.renderYear(v.diy_time ? v.diy_time : v.create_time);
          v.YMDTime = this.renderTime(v.diy_time ? v.diy_time : v.create_time);
        });

        this.total = data.total;
        this.total_page = data.total_page;
        this.total_page = data.total_page;
        this.joulist = rows;
      });

      // const url = this.globalHttpUrl + "findNews";
      // // console.log(11111);
      // this.axios.get(url).then(
      //   (res) => {
      //     // console.log("=======>", res);
      //     if (res.data.code == 200) {
      //       console.log("1111111111", res.data.data);
      //       // (this.pagesize = res.data.data.page_size),
      //       //   (this.total = res.data.data.total),
      //       //   (this.joulist = res.data.data.rows);
      //       // console.log(res.data.data.page_size,"sssss")
      //     }
      //   },
      //   (err) => {
      //     console.log("请求失败", err);
      //   }
      // );
    },
  },
  mounted() {
    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
};
</script>

<style lang="less">
.pagt {
  .el-pagination {
    button {
      span:hover {
        color: #009bde;
      }

      span {
        color: #0c0c0c;
        font-size: 0.105263rem;
        font-family: "HarmonyOS_B", "HarmonyOS";
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span::before {
        content: '';
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        width: 23px;
        height: 21px;
        margin: 0 1%;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAVCAYAAACt4nWrAAAA7ElEQVQ4ja3UMUoDQRQG4C+6CFYK3sDKI1jbWmijrZLKGxgvYOI9jKCNN/AE23sDC0HwAoJMMLCEMTNvN3+3MPv9w2NmRm3bCuYCh3go/dYE4Us8YRsjzNYt3grip39wyhSTTeJjzDvfawui+A+u8LhScLcJfFlwvVJwnyvog1cX9MWrCtJRvB1QkPKOLxx0ClKmTems9syiYMhYStlraq5xITu4wW5nWTInTemWVcDPOdjAsSzh8xw8BC/CffEcPMuNN4r/B2fflsh7nuAXnNXAgjvfx1EtnBLZ+SdO8IbXEhzFUz5wjO/iSvwCma8tez8v2HQAAAAASUVORK5CYII=);
      }

      span::after {
        content: '';
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        width: 23px;
        height: 21px;
        margin: 0 1%;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAVCAYAAACt4nWrAAAA7ElEQVQ4ja3UMUoDQRQG4C+6CFYK3sDKI1jbWmijrZLKGxgvYOI9jKCNN/AE23sDC0HwAoJMMLCEMTNvN3+3MPv9w2NmRm3bCuYCh3go/dYE4Us8YRsjzNYt3grip39wyhSTTeJjzDvfawui+A+u8LhScLcJfFlwvVJwnyvog1cX9MWrCtJRvB1QkPKOLxx0ClKmTems9syiYMhYStlraq5xITu4wW5nWTInTemWVcDPOdjAsSzh8xw8BC/CffEcPMuNN4r/B2fflsh7nuAXnNXAgjvfx1EtnBLZ+SdO8IbXEhzFUz5wjO/iSvwCma8tez8v2HQAAAAASUVORK5CYII=);
      }
    }

    .btn-prev {
      span::after {
        display: none;
      }

      span::before {
        transform: rotate(180deg);
        padding: 0 0.08rem;
      }
    }

    .btn-next {
      span::after {
        // transform: rotate(180deg);
        padding: 0 0.08rem;

      }

      span::before {
        display: none;

        // margin-left: 6%;
      }
    }
  }
}

.pagt {
  .el-pagination {
    .el-pager {
      display: none;
    }
  }
}

.content {
  width: 100%;
  margin: 0 auto;

  .content-header {
    width: 100%;
    max-width: 1920px;
    min-width: 1200px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .journhead {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    .journhead-text {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      // right: 0;
      margin: auto;
      text-align: center;

      div:first-child {
        color: rgb(255, 255, 255);
        //  font-size: .2105rem;
        font-size: 0.3158rem;
        text-align: center;
        //  font-weight: 500;
        margin-bottom: 0.052632rem;
      }

      div:last-child {
        color: rgb(255, 255, 255);
        // font-size: .1368rem;
        font-size: 0.0842rem;
        text-align: center;
      }
    }
  }

  .information {
    padding: 4% 0 0 0;
    // width: 63%;
    margin: 0 auto;

    h1 {
      color: #009bde;
      font-size: .2105rem;
      // font-size: 16px;
      // span{
      //   // font-weight: 200;
      // }
    }

    h4 {
      color: #009bde;
      // font-size: .2105rem;
      font-size: 0.1842rem;
      // font-size: 45px;
    }
  }

  .hourncont {
    width: 100%;
    position: relative;

    .houlayout {
      // width: 1200px;
      width: 63%;
      height: 100%;
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;

      .dlist {
        // width: 1200px;
        width: 100%;
        margin-top: 5%;
        margin-bottom: 2%;

        .hborder {
          // width: 1150px;
          width: 100%;
          height: 1px;
          background-color: #ccc;
          margin: 1% auto;
        }

        .each {
          display: flex;
          height: 10%;
          align-items: center;
          // margin: 3% 0;
          border-bottom: thin solid #d7d7d7;
          padding: 2% 0;
          // border-top: thin solid #d7d7d7;
          // color: rgb(95, 95, 95);
          color: #444444;
          cursor: pointer;

          // justify-content: space-between;
          .imgsize {
            width: 15%;
            // height: 90px;
            background-color: #ccc;
            margin-right: 2%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .msg {
            // font-size: .105263rem;
            font-size: 0.1211rem;
            // font-size: 16px;
            width: 85%;
            padding: 0 0 0 15%;
            line-height: 0.210526rem;

            // font-weight: 600;
            // color: rgb(95, 95, 95);
            p {
              line-height: 0.126316rem;
              color: #acacac;
              // color:  #444444;
              // font-size: .1053rem;
              font-size: 0.0842rem;
              // font-weight: normal;
            }
          }

          .time {
            // font-size: .105263rem;
            font-size: 0.1211rem;
            // font-size: 16px;
            width: 15%;
            // color: #acacac;
            // color: rgb(0, 0, 0);
            color: #444444;
          }

          &:hover {
            color: #009bde;
          }
        }
      }

      .pagt {
        color: #0c0c0c;
        font-size: 0.105263rem;
        position: relative;
        text-align: center;
        top: 1%;

        // font-weight: 400;
        img {
          margin: 0 1%;
        }

        //   .el-pager li {
        //     width: 5%;
        //     height: 5%;
        //     font-size: .094737rem;
        //     // line-height: 47px;
        //   }
        //   .el-pagination button {
        //     width: 50%;
        //     // height: 5%;
        //     background-color: #009bde;
        //     color: #fff;
        //     border-radius: .026316rem;
        //     font-weight: 600;

        //   }
      }
    }

    // img {
    //   width: 100%;
    // }
    .loction {
      margin-top: 2%;
      width: 100%;
      height: 2rem;

      .bottom {
        background-color: #f4f4f4;
        width: 100%;
        position: relative;

        &>img {
          width: 100%;
        }

        .bottom-son {
          padding: 0.263158rem 0 0 0;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
}
</style>


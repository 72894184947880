<template>
  <div class="guanyujianjie" :style="{ width: isMobile ? '80%' : '63%' }">
    <div class="chenli">
      <p>{{ $t("Introduce.smallTitle") }}</p>
      <p>{{ $t("Introduce.title") }}</p>
      <!-- <div class="shimin">
        <font>
          使命：为世界优质品牌链接每个中国家庭
       
        <br />
        愿景：成为品牌数字化的生态合作伙伴
         </font>
      </div> -->
      <!-- <p :style="{ fontSize: isMobile ? '12px' : '16px' }"> -->
      <p :class="theLan?'':'pp'">
        {{ $t("Introduce.p1") }}
      </p>
      <!-- <p :style="{ fontSize: isMobile ? '12px' : '16px' }"> -->
      <p>
        {{ $t("Introduce.p2") }}
      </p>
      <!-- <p :style="{ fontSize: isMobile ? '12px' : '16px' }"> -->
      <p>
        {{ $t("Introduce.p3") }}
      </p>
    </div>



    <div class="zeren">
      <ul>
        <li>
          <div>
            <img src="../../assets/about/pespo.jpg" alt="" />
          </div>

          <div class="text">
            <div>{{ $t("Introduce.mission") }}</div>
          </div>
          <p>{{ $t("Introduce.missionText") }}</p>
        </li>
        <li>
          <div>
            <img src="../../assets/about/young.jpg" alt="" />
          </div>

          <div class="text">
            <div>{{ $t("Introduce.vision") }}</div>
          </div>
          <p>{{ $t("Introduce.visionText") }}</p>
        </li>
        <li>
          <div>
            <img src="../../assets/about/creative.jpg" alt="" />
          </div>

          <div class="text">
            <div>{{ $t("Introduce.value") }}</div>
          </div>
          <p>{{ $t("Introduce.valueText") }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,                //是否移动端
      // fontSize1: 18,
    };
  },
  computed: {
    theLan() {
      // 计算数值的总和
      return this.$store.state.isZh
    },
  },
  mounted() {
    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

  },
};
</script>

<style scoped lang="less">
.guanyujianjie {
  // width: 63%;
  //   height: 500px;
  //   background-color: pink;
  padding: 0 0.105263rem;
  margin: 0 auto;

  .chenli {
    p:nth-child(1) {
      font-size: .1053rem;
      // font-size: 16px;
      color: #b8b8b8;
    }

    p:nth-child(3),
    p:nth-child(4),
    p:nth-child(5) {
      color: #b8b8b8;
      margin-bottom: .0833rem;
    }

    p:nth-child(3) {
      margin-top: .2rem;
    }

    p:nth-child(2) {
      font-size: .2105rem;
      // font-size: 45px;
      line-height: 50px;
      // font-weight: 1200;
      color: #444444;
    }

    // width: 75%;
    width: 100%;
    // height: 1.052632rem;
    // background-color: skyblue;
    margin: 0 auto;
    // margin-bottom: .375rem;
    padding: 0.421053rem 0 0 0;
    // font-size: 16px;
    // color: #666666;
    color: #444444;

    // font-weight: 100;
    // text-align: center;
    // line-height: 0.210526rem;
    p {
      // font-weight: 100;
      // color: #666666;
      color: #444444;
      font-size: .1053rem;
      // font-size: 16px;
      text-align: justify;
      line-height: .1895rem;
    }

    // p:first-child {
    // font-weight: 600;
    //   // margin-bottom: 0.184211rem;
    // }
  }

  p {
    padding: 0 0 0 0;
  }

  .shimin {
    margin: 3% 0 3% 0;
    // color: #000;
    color: #444444;
    width: 100%;
    font-size: 18px;
    // font-weight: 500;
    line-height: .1895rem;
  }

  .zeren {
    width: 100%;
    margin: 3% 0 4% 0;

    img {
      width: 100%;
      height: .5053rem;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: left;

      li {
        padding-bottom: 1%;
        // border-bottom: thin solid #d7d7d7;
        width: 31%;

        .text {
          border-bottom: thin solid #d7d7d7;
          padding-bottom: .0333rem;
        }

        .text {
          margin-top: 0.052632rem;

          div {
            // font-weight: 500;
            font-size: .1368rem;
            // font-size: 24px;
            // color: rgb(36, 36, 36);
            color: #444444;
            line-height: 0.157895rem;
          }

        }

        p {
          color: #b8b8b8;
          font-size: .0921rem;
          // font-size: 16px;
          // line-height: .3rem;
          padding-top: .06579rem;
        }
      }

      // li:nth-child(1)::after{
      //   content: '全球优质消费品牌链接每个中国消费者';
      //   color: #b8b8b8;
      //   font-size: .0921rem;
      //   // line-height: .3rem;
      //   padding-top: .1053rem;
      // }
      // li:nth-child(2)::after{
      //   content: '为中国消费者创造新消费时代下的品质生活';
      //   color: #b8b8b8;
      //   font-size: .0921rem;
      //   // line-height: .3rem;
      //   padding-top: .1053rem;
      // }
      // li:nth-child(3)::after{
      //   content: '责任第一 拥抱变化 创造价值';
      //   color: #b8b8b8;
      //   font-size: .0921rem;
      //   // line-height: .3rem;
      //   padding-top: .1053rem;
      // }
    }
    .pp {
      line-height: 14px;
      font-size: 14px;
    }
  }
}
</style>
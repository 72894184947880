<template>
<!-- 加入我们 -->
  <div class="content">
    <div class="content-header">
      <Header :activeId="activeId" />
    </div>
    <div class="joinhead">
      <img src="../assets/joinus/joinUsBg.png" alt="" />
      <div class="joinheadpzhutu">
        <div>加入我们</div>
        <div>JOIN US</div>
      </div>
    </div>

    <div class="joindata">
      <div class="introduce">
        <div class="intlayout">
          <div class="inttit">
            <!-- <img src="../assets/joinus/zhaopinpeizhi.png" alt="" /> -->
            <span>2021届校园招聘岗位介绍</span>
          </div>
          <div class="intmsg">
            <div class="msgeach" v-for="(item, i) in list" :key="i">
              <div class="msgtex">
                <p>
                  <img :src="item.work" alt="">
                </p>
                <h4>{{ item.Category }}</h4>
                <p  v-for="(message , inx) in item.introduce" :key="inx">
                  {{message.detailed}}
                </p>
              </div>
            </div>
          </div>
         
        </div>
      </div>


      <div class="resume" v-for="(item,i) in resumeArr" :key="i">
        <div class="division">
          <img src="../assets/joinus/division.png" alt="">
        </div>
        <div class="resumeBox">
          <h1>{{item.tittle}}</h1>
          <div class="detailedBox" >
            <div :class="i==2? 'label':'' "  v-for="(mes,ins) in item.detailed" :key="ins">
              <p v-if="i==2">{{mes.label}}</p>
              {{mes.text}}
            </div>
          </div>
        </div>
      </div>

     
      
    </div>

    <div class="codeWarp">
        <div class="codeBox">
          <div class="codeText">
            <h1>RUOYUCHEN RECRUITMENT</h1>
            <h2>若羽臣招聘</h2>
            <h4>关注以下公众号或者扫描二维码投递简历</h4>
          </div>
          <div class="codeLogo">
            <img src="../assets/joinus/code.png" alt="">
          </div>
        </div>
    </div>

    <div class="PolarisBox">
      <img src="../assets/joinus/Polaris.png" alt="" />
      <div class="PolarisText">
        若羽臣北极星计划是面向国内一流高校精英学子开展的管培生专项招聘培养计划，通过六个多月的系统培养，让初出茅庐的菜鸟能全面了解电商服务流程，全程一对一的带教，让年轻人从设计、企划、运营与推广等各个环节快速进入电商的核心，成为若羽臣未来发展的核心力量。
      </div>
      <div class="iconBox">
        <img v-for="(item,i) in iconArr" :key="i" :src="item.url" alt="">
      </div>
      <div class="characteristicBox">
        <div v-for="(item,i) in characteristicArr" :key="i">
          <h3>{{item.label}}</h3>
          <p  v-for="(val,inx) in item.message" :key="inx">{{val.te}}</p>
        </div>
      </div>
    </div>
    
    <div class="joinfot">
      <!-- <img src="../assets/joinus/joinus2.jpg" alt="" /> -->
      <div><Bottom /></div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Bottom from "@/components/bottomNav/Bottom.vue";
export default {
  data() {
    return {
      activeId:6,
      // globalHttpUrl: this.COMMON.httpUrl,
      characteristicArr:[
        {
          label:"私人訂制",
          message:[
            { te: "丰富的课程规划，覆盖通用技能、"},
            { te: "专业培训、管理能力培养"}
          ]
        },
        {
          label:"专属导师 ",
          message:[
            { te: "一对一MENTOR"},
            { te: "辅导计划"}
          ]
        },
        {
          label:"挑战自我",
          message:[
            {
               te: "以日常任务形式全面考核各方面的能力，"
            },
            {
              te:"通过综合评估，最终成为一名合格的"
            },
            {
              te:"电商专业人才 "
            }
          ],
        },
      ],
      iconArr:[
        {
          url:require("../assets/joinus/icon_1.png")
        },
        {
          url:require("../assets/joinus/icon_2.png")
        },
        {
          url:require("../assets/joinus/icon_3.png")
        },
        {
          url:require("../assets/joinus/icon_4.png")
        },
        {
          url:require("../assets/joinus/icon_5.png")
        },
      ],
      list: [
        {
          id: 1,
          Category:"策划类",
          work:require('../assets/joinus/Work1.png'),
          introduce:[
            { detailed:"文案策划管培生", },
          ],
        },
        {
          id: 3,
          Category:"设计类",
          work:require('../assets/joinus/Work2.png'),
          introduce:[
            { detailed:"设计管培生", },
          ],
        },
        {
          id: 4,
          Category:"媒体类",
          work:require('../assets/joinus/Work3.png'),
          introduce:[
            { detailed:"投放管培生、媒介管培生", },
          ],
        },
        {
          id: 2,
          Category:"运营类",
          work:require('../assets/joinus/Work4.png'),
          introduce:[
            { detailed:"渠道运营管培生、直播运营管培生", },
            { detailed:"数据管培生、主播管培生", },
          ],
        },
        {
          id: 5,
          Category:"技术类",
          work:require('../assets/joinus/Work5.png'),
          introduce:[
            { detailed:"WEB前端管培生、BI工程师管培生", },
            { detailed:"IT产品管培生、产品实施管培生", },
          ],
        },
         {
          id: 6,
          Category:"其他",
          work:require('../assets/joinus/Work6.png'),
          introduce:[
            { detailed:"商品管培生、采购管培生", },
            { detailed:"经管管培生、财务管培生", },
          ],
        },
      ],
      resumeArr:[
        {
          tittle:"2022线上简历 投递渠道",
          detailed:[
            {
              text:"1、登录前程无忧、智联招聘、拉勾网、实习僧、BOSS直聘等招聘网站，搜索“若羽臣”关键词，查看岗位并进行简历投递。",
            },
            {
              text:"2、关注若羽臣公众号“若羽臣情报局”，点击“JOIN US”菜单，查看岗位并进行简历投递。",
            },
            {
              text:"3、管培生岗位申请，请将简历投递至：ZHAOPIN@GZRUOYUCHEN.COM，邮件主题：2022管培生+姓名+学校。",
            },
          ]
        },
        {
          tittle:"2022线下简历 投递渠道",
          detailed:[
            {
              text:"1、校园招聘会现场投递。",
            },
            {
              text:"2、参与校园专场宣讲会，现场投递简历。",
            },
            {
              text:" ",
            },
            
          ]
        },
        {
          tittle:"福利待遇",
          detailed:[
            {
              label:"富有竞争力的薪酬",
              text:"高出同行的薪酬待遇",
            },
            {
              label:"畅通的晋升通道",
              text:"每年两次晋升调薪的机会",
            },
            {
              label:"便捷的交通",
              text:"珠江新城中心cbd办公区域",
            },
            {
              label:"优美的办公环境",
              text:"2700多平方的现代化办公室",
            },
            {
              label:"个性化的员工假期",
              text:"带薪休假、带薪病假、婚假、产假等",
            },
            {
              label:"其他福利",
              text:"爱心互助基金、父母孝敬金、 年度评优奖金",
            },
            {
              label:"员工活动",
              text:"年会、女神节、六一园游会、 趣味运动会、青芒运动打卡、 电竞比赛、双十一专项活动",
            },
            {
              label:"员工福利",
              text:"月度生日会、部门个性化团建活动、 年度体检、年度旅游、节日礼物、 年陈礼物、结婚生子福利礼包",
            },
            {
              label:"",
              text:"",
            },
          ]
        },
      ]
    };
  },
  components: {
    Header,
    Bottom,
  },
  created() {
    // this.ajaxData();
  },
  methods: {
    // ajaxData() {
    //   const result = axios.get("/homeapp.do")
    //     .then((res) => {
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
  mounted() {
    // 发ajax请求，用以获取数据，此处地址意思是查询 github中 vue 星数最高的项目
    // const url = this.globalHttpUrl + "";
    // this.axios.get("http://172.16.10.65:30001/ryc/findJoin").then(
    //   (res) => {
    //     if (res.data.code == 200) {
    //       console.log("加入我们", res);
    //       this.list = res.data.data;
    //     }
    //   },
    //   (err) => {
    //     console.log("请求失败", err);
    //   }
    // );
  },
};
</script>

<style lang="less">
.content {
  width: 100%;
  position: relative;
  margin: 0 auto;
  .content-header{
     width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
  }
  .joinhead {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }

     .joinheadpzhutu{
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      // right: 0;
      margin: auto;
      text-align: center;

      div:first-child{
         color: rgb(255, 255, 255);
        //  font-size: .2105rem;
        font-size: .3158rem;
         text-align: center;
        //  font-weight: 500;
         margin-bottom: .052632rem;
      }

      div:last-child{
        color: rgb(255, 255, 255);
        // font-size: .126316rem;
        font-size: .1053rem;
        text-align: center;
      }
    }
  }
  .joindata {
    width: 100%;
    position: relative;
    background-color: #fff;
    padding-bottom: 4%;
    .introduce {
      min-width: 6.3158rem;
      width: 63%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      .intlayout {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: .368421rem;
        margin: 0 0 5% 0;
        .inttit {
          width: 100%;
          display: flex;
          align-items: flex-end;
          img {
            width: .263158rem;
            height: .263158rem;
            margin-right: .052632rem;
          }
          span {
            font-size: .1368rem;
            color: #444444;
            // font-weight: bold;
            line-height: .1789rem;
          }
        }
        .intmsg {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: row ;
          height: 2.789474rem;
          margin: 4% 0 0 0;
          text-align: center;
         
          .msgeach {
            width: 32%;
            display: flex;
            flex-direction: column;
            margin: 0 0 0 0;
            background-color: #ffffff;
            flex-shrink: 0;
            position: relative;
            .msgtex {
              p {
                margin: 2%;
                font-size: .1053rem;
                color: rgb(139, 139, 139);
              }
              h4{
                margin: 8% 0 5% 0;
                font-size: .1263rem;
                // font-weight: 600;
                color: #444444;
              }
            }
            
          }
        
        }
      }
    }
    .resume {
      // width: 1200px;
      width: 63%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      padding-top: .210526rem;
      img{
        width: 100%;
      }
      .resumeBox{
        width: 100%;
        display: flex;
        h1{
          width: 20%;
          padding: 2% 3% 0 0;
          font-size: .1789rem;
          color: #009bdd;
          // font-weight: 400;
        }
        .detailedBox{
          width: 80%;
          display: flex;
          flex-wrap: wrap;
          padding: 2% 0% 0 0;
          // border: thin solid #000;
          justify-content: space-between;
          div{
            line-height: 180%;
            width: 48%;
            text-align: justify;
            margin: 0 0 3% 0;
            font-size: .1053rem;
            color: #8c8c8c;
            padding: 0 0 0 0;
            padding: 0 2% 0 0;
          }
          // div:nth-child(1),div:nth-child(2),div:nth-child(4),div:nth-child(5),div:nth-child(7),div:nth-child(8){
          //   width: 36%;
          // }
          //  div:nth-child(3), div:nth-child(6){
          //     width: 25%;
          //  }
          .label{
            width: 48%;
            text-align: justify;
            margin: 0 0 3% 0;
            font-size: .1053rem;
            p{
              font-size: .1263rem;
              text-align: justify;
              margin: 0 0 3% 0;
              color: #444444;
            }
          }
        }
      }
      .restit {
        width: 100%;
        display: flex;
        align-items: flex-end;
        img {
          width: .263158rem;
          height: .263158rem;
          margin-right: .052632rem;
        }
        span {
          font-size: .1263rem;
          color: #000;
          // font-weight: bold;
          line-height: .121053rem;
        }
      }
      .resmsg {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        // margin: 5% 0 0 0;
      }
    }

  }
  .codeWarp{
    background-color: #009bde;
    width: 100%;
    height: 2.210526rem;
    .codeBox{
      padding: 6% 0 0 0;
      width: 63%;
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
      .codeText{
        width: 40%;
        text-align: left;
        color: #fff;
        h1{
          // font-weight: 500;
          font-size: .3158rem;
          line-height: 90%;
        }
        h2{
          // font-weight: 500;
          font-size: .2105rem;
          margin: 3% 0 0 0;
        }
        h4{
          font-size: .1053rem;
        }
      }
      .codeLogo{
        width: 30%;
        text-align: center;
      }
    }
  }
  .PolarisBox{
    text-align: center;
    width: 63%;
    margin: 0 auto;
    .PolarisText{
      text-align: justify;
      // color: #858585;
      color:  #444444;
      font-size: .1053rem;
      line-height: .1684rem;
    }
    .iconBox{
      width: 80%;
      margin: 6% auto;
      display: flex;
      justify-content: space-around;
      img{
        width: .6053rem;
        height: .9316rem;
      }
    }
    .characteristicBox{
      width: 80%;
      min-width: 4.7368rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      border-top: thin solid #d7d7d7;
      border-bottom: thin solid #d7d7d7;
      padding: 3% 0 3% 0;
      margin: 0 0 12% 10%;
      div{
        width: 35%;
        text-align: center;
        h3{
          margin:  0 0 3% 0;
          color: #42aae2;
          font-size: .1579rem;
          // font-weight: 500;
        }
        p{
          font-size: .1053rem;
          line-height: .1684rem;
          // margin: 4% 0 2% 0;
          // color: #777777;
          color:  #b8b8b8;
        }
      }
      div:nth-child(2){
        width: 25%;
        min-width: .5rem;
        text-align: center;
      }
       div:nth-child(3){
        width: 40%;
        min-width: .5rem;
        text-align: center;
      }
    }
  }
  .joinfot {
    width: 100%;
    position: relative;
    & > img {
      width: 100%;
    }
    .botwz {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>


<template>
  <!-- 联系我们 -->
  <div class="callme">
    <div class="callme-header">
      <Header :activeId="activeId" />
    </div>
    <div class="bgimg">
      <img src="https://img.alicdn.com/imgextra/i3/4045700848/O1CN01EBotmr1I8Qj8YSXbN_!!4045700848.png" alt="">
      <div class="bgimg-son">
        <div class="lianxi">{{ $t("Contactus.title") }}</div>
        <div class="contactus">{{ $t("Contactus.smallTitle") }}</div>
        <div class="cooperation">
          <div class="cooperationBox">
            <div><img src="../assets/contactus/bd.png" alt=""></div>
            <div class="shangwu">{{ $t("Contactus.titleson1") }}</div>
            <div class="email">BD@gzruoyuchen.com</div>
          </div>
          <img class="division" src="../assets/contactus/division.png" alt="">
          <div class="cooperationBox">
            <div class="jianli"><img src="../assets/contactus/resume.png" alt=""></div>
            <div class="toudi">{{ $t("Contactus.titleson2") }}</div>
            <div class="email">
              zhaopin@gzruoyuchen.com<br>hr@gzruoyuchen.com
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 地址 -->
    <div class="dizhi">
      <Address />
    </div>
    <!-- 岗位介绍 -->
    <div class="joindata">
      <div class="joinbody">
        <div class="introduce" :style="{ width: isMobile ? '80%' : '63%' }">
          <div class="intlayout">
            <div class="inttit">
              <div>{{ $t("Contactus.smallintrotitle") }}</div>
              <!-- <img src="../assets/joinus/zhaopinpeizhi.png" alt="" /> -->
              <span>{{ $t("Contactus.introtitle") }}</span>
            </div>
            <div v-if="$store.state.isZh" class="intmsg">
              <div class="msgeach" v-for="(item, i) in list" :key="i">
                <div class="msgtex">
                  <p>
                    <img :src="item.work" alt="">
                  </p>
                  <h4>{{ item.Category }}</h4>
                  <p v-for="(message, inx) in item.introduce" :key="inx">
                    {{ message.detailed }}
                  </p>
                </div>
              </div>
            </div>
            <!-- 英文版 -->
            <div v-else class="intmsg">
              <div class="msgeach" v-for="(item, i) in list2" :key="i">
                <div class="msgtex2">
                  <p>
                    <img :src="item.work" alt="">
                  </p>
                  <h4>{{ item.Category }}</h4>
                  <p v-for="(message, inx) in item.introduce" :key="inx">
                    {{ message.detailed }}
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div v-if="$store.state.isZh">
        <div class="resume" v-for="(item, i) in resumeArr" :key="i" :style="{ width: isMobile ? '80%' : '63%' }">
          <div class="division">
            <img src="../assets/joinus/division.png" alt="">
          </div>
          <div class="resumeBox">
            <h1>{{ item.tittle }}</h1>
            <div class="detailedBox">
              <div :class="i == 2 ? 'label' : ''" v-for="(mes, ins) in item.detailed" :key="ins">
                <p v-if="i == 2">{{ mes.label }}</p>
                <span class="textBox">
                  <span style="white-space: pre-wrap;">{{ mes.text }}</span>
                  <span class="erwei" v-if="i == 0 && ins == 2">
                    <img src="../assets/joinus/erwei.png" alt="">
                  </span>
                </span>
                <!-- {{ mes.text }} -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="resume2" v-for="(item, i) in resumeArr2" :key="i" :style="{ width: isMobile ? '80%' : '63%' }">
          <div class="division">
            <img src="../assets/joinus/division.png" alt="">
          </div>
          <div class="resumeBox">
            <h1>{{ item.tittle }}</h1>
            <div class="detailedBox">
              <div :class="i == 2 ? 'label' : ''" v-for="(mes, ins) in item.detailed" :key="ins">
                <p v-if="i == 2">{{ mes.label }}</p>
                <span class="textBox">
                  <span style="white-space: pre-wrap;">{{ mes.text }}</span>
                  <span class="erwei" v-if="i == 0 && ins == 2">
                    <img src="../assets/joinus/erwei.png" alt="">
                  </span>
                </span>
                <!-- {{ mes.text }} -->
              </div>
            </div>
          </div>
        </div>
      </div>




    </div>

    <!-- <div class="codeWarp">
      <div class="codeBox">
        <div class="codeText">
          <h1>RUOYUCHEN RECRUITMENT</h1>
          <h2>若羽臣招聘</h2>
          <h4>关注以下公众号或者扫描二维码投递简历</h4>
        </div>
        <div class="codeLogo">
          <img src="../assets/joinus/code.png" alt="">
        </div>
      </div>
    </div> -->

    <div class="PolarisBox" :style="{ width: isMobile ? '80%' : '63%' }">
      <img src="../assets/joinus/Polaris.png" alt="" />
      <div class="PolarisText" :class="theLan ? '' : 'PolarisText2'">
        {{ $t("Contactus.PolarisText") }}
      </div>
      <div v-if="$store.state.isZh" class="iconBox">
        <img v-for="(item, i) in iconArr" :key="i" :src="item.url" alt="">
      </div>
      <!-- 英文版 -->
      <div v-else class="iconBox">
        <img v-for="(item, i) in iconArr2" :key="i" :src="item.url" alt="">
      </div>
      <div v-if="$store.state.isZh" class="characteristicBox">
        <div v-for="(item, i) in characteristicArr" :key="i">
          <h3>{{ item.label }}</h3>
          <p v-for="(val, inx) in item.message" :key="inx">{{ val.te }}</p>
        </div>
      </div>
      <!-- 英文版 -->
      <div v-else class="characteristicBox2">
        <div v-for="(item, i) in characteristicArr2" :key="i">
          <h3>{{ item.label }}</h3>
          <p v-for="(val, inx) in item.message" :key="inx">{{ val.te }}</p>
        </div>
      </div>
    </div>

    <div>
      <Bottom />
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Bottom from "@/components/bottomNav/Bottom.vue";
import Address from "@/components/address/Address.vue";
export default {
  components: {
    Header,
    Bottom,
    Address,
  },
  data() {
    return {
      isMobile: false,                //是否移动端
      activeId: 5,
      characteristicArr: [
        {
          label: "私人訂制",
          message: [
            { te: "丰富的课程规划，覆盖通用技能、" },
            { te: "专业培训、管理能力培养" }
          ]
        },
        {
          label: "专属导师 ",
          message: [
            { te: "一对一MENTOR" },
            { te: "辅导计划" }
          ]
        },
        {
          label: "挑战自我",
          message: [
            {
              te: "以日常任务形式全面考核各方面的能力，"
            },
            {
              te: "通过综合评估，最终成为一名合格的"
            },
            {
              te: "电商专业人才 "
            }
          ],
        },
      ],
      characteristicArr2: [
        {
          label: "Private customization",
          message: [
            { te: "Rich curriculum planning, covering" },
            { te: "general skills, professional train-" },
            { te: "ing, management ability training" }
          ]
        },
        {
          label: "Dedicated mentors ",
          message: [
            { te: "One-on-one mentoring" },
            { te: "program" }
          ]
        },
        {
          label: "Challenge yourself",
          message: [
            {
              te: "Comprehensively assess the ability of all aspects "
            },
            {
              te: "in the form of daily tasks, and eventually become "
            },
            {
              te: "a qualified e-commerce professional through "
            },
            {
              te: "comprehensive assessment. "
            }
          ],
        },
      ],
      iconArr: [
        {
          url: require("../assets/joinus/icon_1.png")
        },
        {
          url: require("../assets/joinus/icon_2.png")
        },
        {
          url: require("../assets/joinus/icon_3.png")
        },
        {
          url: require("../assets/joinus/icon_4.png")
        },
        {
          url: require("../assets/joinus/icon_5.png")
        },
      ],
      iconArr2: [
        {
          url: require("../assets/joinus/1.png")
        },
        {
          url: require("../assets/joinus/2.png")
        },
        {
          url: require("../assets/joinus/3.png")
        },
        {
          url: require("../assets/joinus/4.png")
        },
        {
          url: require("../assets/joinus/5.png")
        },
      ],
      list: [
        {
          id: 1,
          Category: "策划类",
          work: require('../assets/joinus/Work1.png'),
          introduce: [
            { detailed: "文案策划管培生、内容营销管培生", },
          ],
        },
        {
          id: 3,
          Category: "设计类",
          work: require('../assets/joinus/Work2.png'),
          introduce: [
            { detailed: "设计管培生", },
          ],
        },
        {
          id: 4,
          Category: "媒体类",
          work: require('../assets/joinus/Work3.png'),
          introduce: [
            { detailed: "投放管培生、媒介管培生、CRM管培生", },
          ],
        },
        {
          id: 2,
          Category: "运营类",
          work: require('../assets/joinus/Work4.png'),
          introduce: [
            { detailed: "渠道运营管培生、直播运营管培生", },
            { detailed: "运营管培生、数据管培生、主播管培生", },
          ],
        },
        {
          id: 5,
          Category: "技术类",
          work: require('../assets/joinus/Work5.png'),
          introduce: [
            { detailed: "WEB前端管培生、JAVA管培生", },
            { detailed: "IT产品管培生、产品实施管培生", },
          ],
        },
        {
          id: 6,
          Category: "其他",
          work: require('../assets/joinus/Work6.png'),
          introduce: [
            { detailed: "计划采购管培生、物流管培生、关务管培生", },
            { detailed: "经管管培生、人力管培生、客服管培生", },
          ],
        },
      ],
      list2: [
        {
          id: 1,
          Category: "Planning",
          work: require('../assets/joinus/Work1.png'),
          introduce: [
            { detailed: "Copywriting Management Trainee, ", },
            { detailed: "Content Marketing Management Trainee", },
          ],
        },
        {
          id: 3,
          Category: "Design",
          work: require('../assets/joinus/Work2.png'),
          introduce: [
            { detailed: "Design Management Trainee", },
          ],
        },
        {
          id: 4,
          Category: "Media",
          work: require('../assets/joinus/Work3.png'),
          introduce: [
            { detailed: "Placement Management Trainee, Media ", },
            { detailed: "Management Trainee,CRM Management Trainee Management Trainee, ", },
          ],
        },
        {
          id: 2,
          Category: "Operation",
          work: require('../assets/joinus/Work4.png'),
          introduce: [
            { detailed: "Channel Operation Management Trainee、", },
            { detailed: "Operation Management Trainee, ", },
            { detailed: "Live Streaming Operation Management  ", },
            { detailed: "Trainee, Data Management Trainee,  ", },
            { detailed: "LiveStreamer Management Trainee ", },
          ],
        },
        {
          id: 5,
          Category: "Technical Professionals",
          work: require('../assets/joinus/Work5.png'),
          introduce: [
            { detailed: "WEB Front-end Management Trainee, ", },
            { detailed: "JAVA Management Trainee, IT Product  ", },
            { detailed: "Management Trainee, Product Imple", },
            { detailed: "mentation Management Trainee ", },
          ],
        },
        {
          id: 6,
          Category: "Others",
          work: require('../assets/joinus/Work6.png'),
          introduce: [
            { detailed: "Planning and Procurement Management Trainee, ", },
            { detailed: "Logistics Management Trainee, Customs Affairs ", },
            { detailed: "Management Trainee, Management Trainee in  ", },
            { detailed: "Economics and Management, Human Resources ", },
            { detailed: "Management Trainee, CRM Management Trainee", },
          ],
        },
      ],
      resumeArr: [
        {
          tittle: "2024届网络 投递渠道",
          detailed: [
            {
              text: "1、线上投递:登录“BOSS直聘/实习僧云职校招”等招聘网站，搜索“若羽臣管培生”选择意向岗位进行投递。",
            },
            {
              text: "2、邮箱投递：\nZHAOPIN@GZRUOYUCHEN.COM (主题:2024 XX管培生+姓名+学校);",
            },
            {
              text: "3、扫描右方二维码一键投递。",
            },
          ]
        },
        {
          tittle: "2024届线下 投递渠道",
          detailed: [
            {
              text: "1、现场投递:在校园宣讲会/双选会现场投递简历",
            },
            // {
            //   text: "2、参与校园专场宣讲会，现场投递简历。",
            // },
            // {
            //   text: " ",
            // },

          ]
        },
        {
          tittle: "福利待遇",
          detailed: [
            {
              label: "富有竞争力的薪酬",
              text: "高出同行的薪酬待遇",
            },
            {
              label: "畅通的晋升通道",
              text: "每年两次晋升调薪的机会",
            },
            {
              label: "便捷的交通",
              text: "珠江新城中心cbd办公区域",
            },
            {
              label: "优美的办公环境",
              text: "2700多平方的现代化办公室",
            },
            {
              label: "个性化的员工假期",
              text: "带薪休假、带薪病假、婚假、产假等",
            },
            {
              label: "其他福利",
              text: "爱心互助基金、父母孝敬金、 年度评优奖金",
            },
            {
              label: "员工活动",
              text: "年会、女神节、六一园游会、 趣味运动会、青芒运动打卡、 电竞比赛、双十一专项活动",
            },
            {
              label: "员工福利",
              text: "月度生日会、部门个性化团建活动、 年度体检、年度旅游、节日礼物、 年陈礼物、结婚生子福利礼包",
            },
            {
              label: "",
              text: "",
            },
          ]
        },
      ],
      resumeArr2: [
        {
          tittle: "Online Application for 2024 Graduates",
          detailed: [
            {
              text: "1. Application online: Login to job recruitment websites such as “BOSS zhipin or Shixiseng.com for its function of online recruitment of fresh graduates”, then search for “Management Trainee at RYC” and apply for the job you’re interested in.",
            },
            {
              text: "2. Application by email: \n ZHAOPIN@GZRYC.COM (Subject: 2024 XX Management Trainee + Name + School).",
            },
            {
              text: "3. Scan the QR code on the right to submit your application with one click.",
            },
          ]
        },
        {
          tittle: "Offline Application for 2024 Graduates",
          detailed: [
            {
              text: "1.In-person application: submit your resume in an on-campus recruitment event or job fair.",
            },
            // {
            //   text: "2、参与校园专场宣讲会，现场投递简历。",
            // },
            // {
            //   text: " ",
            // },

          ]
        },
        {
          tittle: "Benefits",
          detailed: [
            {
              label: "Competitive salary",
              text: "higher than our peers’ packages",
            },
            {
              label: "Smooth promotional career paths",
              text: "biannual opportunities for promotion and salary adjustment",
            },
            {
              label: "Convenient transportation ",
              text: "Office in Zhujiang New Town CBD",
            },
            {
              label: "Comfortable workspace",
              text: "modern office spanning over 2,700 m2",
            },
            {
              label: "On-demand employee holidays",
              text: "paid vacation, paid sick leave, marriage \n leave and maternity leave, etc.",
            },
            {
              label: "Other benefits",
              text: "Mutual Aid Fund, Parents' Filial \n Piety Payment, Annual Bonus",
            },
            {
              label: "Activities for employees",
              text: "year-end party, Goddess Day event, \n June 1st garden party, fun sports meeting, \n Qingmang sports event, e-sports \n competition, Double Eleven special events",
            },
            {
              label: "Employee benefits",
              text: "monthly birthday party, unique team-building activities \n for different departments, annual health check-ups, annual \n travel, holiday gifts, gifts for senior employees, welfare packages \n to celebrate employee’s marriage and the birth of newborn",
            },
            {
              label: "",
              text: "",
            },
          ]
        },
      ],
    }
  },
  computed: {
    theLan() {
      // 计算数值的总和
      return this.$store.state.isZh
    },
  },
  mounted() {
    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
}
</script>

<style scoped lang="less">
.callme {
  width: 100%;
  margin: 0 auto;

  .callme-header {
    width: 100%;
    max-width: 1920px;
    min-width: 1200px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .bgimg {
    width: 100%;
    margin: 0 auto;
    position: relative;

    &>img {
      width: 100%
    }

    .bgimg-son {
      // letter-spacing: .0105rem;
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;

      .lianxi {
        color: rgb(255, 255, 255);
        // font-size: .210526rem;
        font-size: .3158rem;
        line-height: .368421rem;
      }

      .contactus {
        color: rgb(255, 255, 255);
        // font-size: .105263rem;
        font-size: .0842rem;
        margin-bottom: .3158rem;
        opacity: 1;
      }

      .cooperation {
        margin: 0 auto;
        width: 38%;
        display: flex;
        justify-content: space-between;

        .division {
          width: .0105rem;
          height: .5263rem;
        }

        .cooperationBox {
          width: 50%;

          div:nth-child(1) {
            margin: 0 0 3% 0;
          }
        }

        .email {
          height: .526316rem;
          // font-size: .1368rem;
          font-size: .0842rem;
          color: rgb(255, 255, 255);
          opacity: 1;
          // font-weight: 100;
        }
      }

      // .toudi{
      //   font-size: .147368rem;
      // font-weight: 300;
      //   color: rgb(255, 255, 255);
      // }

      .zhao,
      .toudi,
      .bd,
      .shangwu {
        font-size: .1368rem;
        // font-weight: 300;
        color: rgb(255, 255, 255);
        // line-height: 50px;
        // font-weight: 400;
        opacity: 1;
      }

      .bd {
        // color: #413232;
        color: #444444;
        height: .263158rem;
      }

    }
  }

  .dizhi {
    background-color: #fff;
    width: 100%;
  }

  .joindata {
    width: 100%;
    position: relative;
    background-color: #fff;
    padding-bottom: 4%;

    .joinbody {
      background-color: #009bde;
    }

    .introduce {
      background-color: #009bde;
      // min-width: 6.3158rem;
      min-width: 920px;
      min-height: 635px;
      width: 63%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      .intlayout {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: .368421rem;
        margin: 0 0 5% 0;

        .inttit {
          width: 100%;
          // display: flex;
          // align-items: flex-end;

          img {
            width: .263158rem;
            height: .263158rem;
            margin-right: .052632rem;
          }

          span {
            font-size: 45px;
            color: #fff;
            // font-weight: bold;
            line-height: 45px;
          }

          div {
            font-size: .1053rem;
            // font-size: 16px;
            // font-weight: 50;
            color: #fff;
          }
        }

        .intmsg {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: row;
          height: 2.789474rem;
          margin: 4% 0 0 0;
          text-align: center;

          .msgeach {
            width: 33%;
            display: flex;
            flex-direction: column;
            margin: 0 0 0 0;
            background-color: #009bde;
            flex-shrink: 0;
            position: relative;

            .msgtex {
              p {
                margin: 2%;
                font-size: .1053rem;
                // font-size: 16px;
                color: #fff;
              }

              h4 {
                margin: 8% 0 5% 0;
                font-size: .1263rem;
                // font-size: 24px;
                // font-weight: 600;
                color: #fff;
              }
            }

            .msgtex2 {
              p {
                margin: 2%;
                font-size: .085rem;
                // font-size: 16px;
                color: #fff;
              }

              h4 {
                margin: 8% 0 5% 0;
                font-size: .1263rem;
                // font-size: 24px;
                // font-weight: 600;
                color: #fff;
              }
            }

          }

        }
      }
    }

    .resume {
      position: relative;
      // width: 1200px;
      width: 63%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      padding-top: .210526rem;

      img {
        width: 100%;
      }

      .resumeBox {
        width: 100%;
        display: flex;

        h1 {
          width: 20%;
          padding: 2% 3% 0 0;
          font-size: .1789rem;
          // font-size: 32px;
          color: #009bdd;
          // font-weight: 400;
        }

        .detailedBox {
          width: 80%;
          display: flex;
          flex-wrap: wrap;
          padding: 2% 0% 0 0;
          // border: thin solid #000;
          justify-content: space-between;

          .textBox {
            display: flex;
            flex-direction: row;

            span {
              flex: 1;
            }

            .erwei {
              img {
                width: .54605rem;
              }
            }
          }


          div {
            word-break: break-all;
            line-height: 180%;
            width: 32%;
            text-align: start;
            margin: 0 0 3% 0;
            font-size: .1053rem;
            // font-size: 16px;
            color: #8c8c8c;
            padding: 0 0 0 0;
            padding: 0 2% 0 0;
          }

          // div:nth-child(2){
          //   width: 48%;
          // }

          // div:nth-child(1),div:nth-child(2),div:nth-child(4),div:nth-child(5),div:nth-child(7),div:nth-child(8){
          //   width: 36%;
          // }
          //  div:nth-child(3), div:nth-child(6){
          //     width: 25%;
          //  }
          .label {
            width: 32%;
            text-align: justify;
            margin: 0 0 3% 0;
            font-size: .1053rem;
            // font-size: 16px;

            p {
              font-size: .1263rem;
              // font-size: 24px;
              text-align: justify;
              margin: 0 0 3% 0;
              color: #444444;
            }
          }
        }
      }

      .restit {
        width: 100%;
        display: flex;
        align-items: flex-end;

        img {
          width: .263158rem;
          height: .263158rem;
          margin-right: .052632rem;
        }

        span {
          font-size: .1263rem;
          color: #000;
          // font-weight: bold;
          line-height: .121053rem;
        }
      }

      .resmsg {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        // margin: 5% 0 0 0;
      }
    }

    .resume2 {
      position: relative;
      // width: 1200px;
      width: 63%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      padding-top: .210526rem;

      img {
        width: 100%;
      }

      .resumeBox {
        width: 100%;
        display: flex;

        h1 {
          width: 20%;
          padding: 2% 3% 0 0;
          font-size: 0.116rem;
          // font-size: 32px;
          color: #009bdd;
          // font-weight: 400;
        }

        h1:last-child {
          font-size: .1263rem
        }

        .detailedBox {
          width: 80%;
          display: flex;
          flex-wrap: wrap;
          padding: 2% 0% 0 0;
          // border: thin solid #000;
          justify-content: space-between;

          .textBox {
            display: flex;
            flex-direction: row;

            span {
              flex: 1;
            }

            .erwei {
              img {
                width: .54605rem;
              }
            }
          }


          div {
            word-break: break-all;
            // line-height: 180%;
            width: 32%;
            text-align: start;
            margin: 0 0 3% 0;
            font-size: 12px;
            // font-size: 16px;
            color: #8c8c8c;
            padding: 0 0 0 0;
            padding: 0 2% 0 0;
          }

          // div:nth-child(2){
          //   width: 48%;
          // }

          // div:nth-child(1),div:nth-child(2),div:nth-child(4),div:nth-child(5),div:nth-child(7),div:nth-child(8){
          //   width: 36%;
          // }
          //  div:nth-child(3), div:nth-child(6){
          //     width: 25%;
          //  }
          .label {
            width: 32%;
            text-align: justify;
            margin: 0 0 3% 0;
            font-size: 12px;
            // font-size: 16px;

            p {
              font-size: .1263rem;
              // font-size: 24px;
              text-align: justify;
              margin: 0 0 3% 0;
              color: #444444;
            }
          }
        }
      }

      .restit {
        width: 100%;
        display: flex;
        align-items: flex-end;

        img {
          width: .263158rem;
          height: .263158rem;
          margin-right: .052632rem;
        }

        span {
          font-size: .1263rem;
          color: #000;
          // font-weight: bold;
          line-height: .121053rem;
        }
      }

      .resmsg {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        // margin: 5% 0 0 0;
      }
    }

  }

  .codeWarp {
    background-color: #009bde;
    width: 100%;
    height: 2.210526rem;

    .codeBox {
      padding: 6% 0 0 0;
      width: 63%;
      display: flex;
      justify-content: space-around;
      margin: 0 auto;

      .codeText {
        width: 40%;
        text-align: left;
        color: #fff;

        h1 {
          // font-weight: 500;
          font-size: .3158rem;
          line-height: 90%;
        }

        h2 {
          // font-weight: 500;
          font-size: .2105rem;
          margin: 3% 0 0 0;
        }

        h4 {
          font-size: .1053rem;
        }
      }

      .codeLogo {
        width: 30%;
        text-align: center;
      }
    }
  }

  .PolarisBox {
    text-align: center;
    width: 63%;
    margin: 0 auto;

    .PolarisText {
      text-align: center;
      // color: #858585;
      color: #b8b8b8;
      font-size: .1053rem;
      // font-size: 16px;
      line-height: .1684rem;
    }

    .PolarisText2 {
      text-align: center;
      // color: #858585;
      color: #b8b8b8;
      font-size: 12px;
      // font-size: 16px;
      // line-height: .1684rem;
    }

    .iconBox {
      width: 80%;
      margin: 6% auto;
      display: flex;
      justify-content: space-around;

      img {
        width: .6053rem;
        height: .9316rem;
      }
    }

    .characteristicBox {
      width: 80%;
      min-width: 4.7368rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      border-top: thin solid #d7d7d7;
      border-bottom: thin solid #d7d7d7;
      padding: 3% 0 3% 0;
      margin: 0 0 12% 10%;

      div {
        width: 35%;
        text-align: center;

        h3 {
          margin: 0 0 3% 0;
          color: #42aae2;
          font-size: .1579rem;
          // font-size: 24px;
          // font-weight: 500;
        }

        p {
          font-size: .1053rem;
          // font-size: 16px;
          line-height: .1684rem;
          // margin: 4% 0 2% 0;
          // color: #777777;
          color: #b8b8b8;
        }
      }

      div:nth-child(2) {
        width: 25%;
        min-width: .5rem;
        text-align: center;
      }

      div:nth-child(3) {
        width: 40%;
        min-width: .5rem;
        text-align: center;
      }
    }

    .characteristicBox2 {
      width: 80%;
      min-width: 4.7368rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      border-top: thin solid #d7d7d7;
      border-bottom: thin solid #d7d7d7;
      padding: 3% 0 3% 0;
      margin: 0 0 12% 10%;

      div {
        width: 30%;
        text-align: center;

        h3 {
          margin: 0 0 3% 0;
          color: #42aae2;
          font-size: .126rem;
          // font-size: 24px;
          // font-weight: 500;
        }

        p {
          font-size: .12px;
          // font-size: 16px;
          line-height: .126rem;
          // margin: 4% 0 2% 0;
          // color: #777777;
          color: #b8b8b8;
        }
      }

      div:nth-child(2) {
        width: 30%;
        min-width: .5rem;
        text-align: center;
      }

      div:nth-child(3) {
        width: 40%;
        min-width: .5rem;
        text-align: center;
      }
    }
  }
}
</style>

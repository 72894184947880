<template>
  <!-- 关于我们 -->
  <div class="about">
    <div class="about-header">
      <Header :activeId="activeId" />
    </div>

    <div class="top">
      <img src="../assets/about/aboutUsBg.jpg" alt="" />

      <div class="topzhutu">
        <div>{{ $t("Aboutus.topTitle") }}</div>
        <div>{{ $t("Aboutus.topsmallTitle") }}</div>
      </div>
    </div>

    <div class="guanyjj">
      <Guanyujianjie />
    </div>

    <div class="center">
      <!-- <img src="../assets/guanyuwomen/beijingtu.jpg" alt="" /> -->

      <div class="center-son">
        <!-- <div class="history">
          <History />
        </div> -->

        <!-- <div class="dizhi">
          <Address />
        </div> -->

        <div class="wenhua">
          <JournalismCon :Ptitle="title" :Pmessage="message" :PArr="Arr" />
        </div>

        <div class="bottom">
          <div class="bottom-son">
            <Bottom />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Bottom from "@/components/bottomNav/Bottom.vue";
import JournalismCon from "@/components/home/JournalismCon.vue"
import Guanyujianjie from "@/components/guanyujianjie/Guanyujianjie.vue"
// import History from "@/components/history/HistoryList.vue";
// import Address from "@/components/address/Address.vue";
export default {
  components: {
    Header,
    JournalismCon,
    Bottom,
    // History,
    // Address,
    Guanyujianjie
  },
  data() {
    return {
      Arr: 0,
      title: "企业文化",
      message: "THE ENTERPRISE CULTURE",
      activeId: 1
      // globalHttpUrl: this.COMMON.httpUrl,
    };
  },
  created() {
    // this.axios.get(this.globalHttpUrl + "findCourse").then((res) => {
    //   // this.searchHint = res.data.keywords;
    //   console.log(res);
    // });

    // this.axios.get(this.globalHttpUrl + "findNews").then((res) => {
    //   // this.searchHint = res.data.keywords;
    //   console.log(res);
    // });
  },
  // beforeRouteEnter(to, from, next) {
  //   // 在渲染该组件的对应路由被 confirm 前调用
  //   // 不！能！获取组件实例 `this`
  //   // 因为当守卫执行前，组件实例还没被创建
  //   next((vm) => {
  //     // 通过 `vm` 访问组件实例
  //     vm.gender = to.query.gender || "male";
  //     console.log(to)
  //   });
  // },
  // beforeRouteUpdate(to, from, next) {
  //   // this.gender = to.query.gender || "male";
  //   next();
  // },
};
</script>

<style scoped lang="less">
.about {
  .bottom {
    // padding: 5% 0 0 0;
    height: 1.157895rem;
    background-color: #f4f4f4;
  }

  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  position: relative;

  .about-header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .top {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    .topzhutu {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      // right: 0;
      margin: auto;
      text-align: center;

      div:first-child {
        color: rgb(255, 255, 255);
        //  font-size: .2105rem;
        font-size: .3158rem;
        text-align: center;
        //  font-weight: 500;
        margin-bottom: .052632rem;
      }

      div:last-child {
        color: rgb(255, 255, 255);
        // font-size:  .1368rem;
        font-size: .0842rem;
        text-align: center;
      }
    }
  }

  .guanyjj {
    width: 100%;
  }

  .center {
    width: 100%;
    height: 100%;
    position: relative;

    &>img {
      width: 100%;
    }

    .center-son {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      .history {
        width: 100%;
        background-color: #009bdd;
        padding: 5% 0 8% 0;
        // height: 25%;
        // margin-top: 2%;
      }

      .dizhi {
        background-color: #fff;
        width: 100%;
      }

      .wenhua {
        padding: 0 0 2% 0;
        width: 100%;
      }

      .center-son-bottom {
        position: absolute;
        bottom: 0px;
        left: 0px;
        // margin-top: 162px;
      }
    }
  }
}</style>


<template>
  <div class="address" :style="{ width: isMobile ? '80%' : '63%' }">
    <div class="address-title">
      <p>{{ $t("Address.smallTitle") }}</p>
      <p>{{ $t("Address.title") }}</p>
      <!-- <div class="xuxian"></div> -->
      <div class="address-diqu">
        <ul v-if="$store.state.isZh">
          <li v-for="(item, i) in address" :key="i" @click="getActive(i)">
            <span :class="{ active: i == index }">{{ item }}</span><span v-if="i != address.length - 1">/</span>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(item, i) in address2" :key="i" @click="getActive(i)">
            <span :class="{ active: i == index }">{{ item }}</span><span v-if="i != address2.length - 1">/</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="lunbo" :style="{ height: isMobile ? '285px' : '' }">
      <el-carousel :height="`${width}px`" arrow="hover" indicator-position="none" :autoplay="false" :initial-index="index"
        @change="(pre, next) => {
          change(pre, next, index);
        }
          " ref="course">
        <el-carousel-item>
          <div class="lunbo-son">
            <div class="lunbo-img">
              <img v-if="$store.state.isZh" src="../../assets/about/gaungzhou.png" alt="" />
              <img v-else src="../../assets/about/map3.png" alt="" />
            </div>

            <div :class="theLan ? 'lunbo-dizhi' : 'lunbo-dizhi2'">
              <div :class="theLan ? 'city' : 'city2'">
                <div class="UnderBox">
                  {{ $t("Address.guangzhou") }}
                  <img class="Under" src="../../assets/about/Underline.png" alt="">
                </div>
              </div>

              <div :class="theLan ? 'city-juti' : 'city-juti2'">
                <img src="../../assets/about/dress.png" alt="">
                {{ $t("Address.guangzhou1") }}
                <br>
                <img src="../../assets/about/dress.png" alt="">
                {{ $t("Address.guangzhou2") }}
                <br>
                <img src="../../assets/about/dress.png" alt="">
                {{ $t("Address.guangzhou3") }}
              </div>
            </div>
          </div>
        </el-carousel-item>

        <!-- <el-carousel-item>
          <div class="lunbo-son">
            <div class="lunbo-img">
              <img src="../../assets/about/beijing.png" alt="" />
            </div>

            <div class="lunbo-dizhi">
              <div class="city">
               
                <div class="UnderBox">
                  北京地址
                  <img class="Under" src="../../assets/about/Underline.png" alt="">
                </div>
              </div>

              <div class="city-juti">
                <img src="../../assets/about/dress.png" alt="">
                北京市 <br> 亦庄隆盛大厦A座
              </div>
            </div>
          </div>
        </el-carousel-item> -->

        <el-carousel-item>
          <div class="lunbo-son">
            <div class="lunbo-img">
              <img v-if="$store.state.isZh" src="../../assets/about/shanghai.png" alt="" />
              <img v-else src="../../assets/about/map2.png" alt="" />
            </div>

            <div :class="theLan ? 'lunbo-dizhi' : 'lunbo-dizhi2'">
              <div :class="theLan ? 'city' : 'city2'">

                <div class="UnderBox">
                  {{ $t("Address.shanghai") }}
                  <img class="Under" src="../../assets/about/Underline.png" alt="">
                </div>
              </div>

              <div :class="theLan ? 'city-juti' : 'city-juti2'">
                <img src="../../assets/about/dress.png" alt="">
                {{ $t("Address.shanghai1") }}
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="lunbo-son">
            <div class="lunbo-img">
              <img v-if="$store.state.isZh" src="../../assets/about/newsland.png" alt="" />
              <img v-else src="../../assets/about/map1.png" alt="" />
            </div>

            <div :class="theLan ? 'lunbo-dizhi' : 'lunbo-dizhi2'">
              <div :class="theLan ? 'city' : 'city2'">
                <div class="UnderBox">
                  {{ $t("Address.NewZealand") }}
                  <img class="Under" src="../../assets/about/Underline.png" alt="">
                </div>
              </div>

              <div :class="theLan ? 'city-juti' : 'city-juti2'">
                <img src="../../assets/about/dress.png" alt="">
                Registered Office18a Neilpark Drive, EastTamaki, Auckland, 2013,NZ
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      address: ["广州", "上海", "新西兰"],
      address2: [" Guangzhou", "Shanghai ", "New Zealand"],
      screenWidth: 0,
      width: 0,
      isMobile: false,                //是否移动端
    };
  },
  // created(){},

  methods: {
    getActive: function (n) {
      //   console.log(n)
      this.index = n;
      this.$refs.course.setActiveItem(n);
    },
    change: function (pre, next, index) {
      console.log(pre, next, index);
      this.index = pre;
      // if()
      // if(pre - next > 0){
      //     if(next == 0){
      //         this.active
      //     }
      //     this.active = pre
      //     // console.log(this.active)

      // }else if(pre -next < 0){
      //     if(pre == 0){
      //         this.active = 0
      //     }else if(next == 10){
      //         this.active = pre
      //     }
      //     else{
      //         this.active = next
      //     }

      // }
      console.log("=====>", this.active);
    },
  },
  computed: {
    theLan() {
      // 计算数值的总和
      return this.$store.state.isZh
    },
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    // this.screenHeight = document.body.clientHeight;

    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

    if (this.screenWidth < 1200) {
      // return
      this.screenWidth = 1200;
    }
    if (this.screenWidth > 1920) {
      this.screenWidth = 1920;
    }
    this.width = (this.screenWidth * 0.62 * 380) / 1147;
    //  this.$set(this.width, 'height' , (this.screenWidth * 0.7 * 380) / 1147);
    const _this = this;
    // console.log(this.width)
    window.addEventListener("resize", () => {
      _this.screenWidth = document.body.clientWidth;
      // this.screenHeight = document.body.clientHeight;
      if (_this.screenWidth < 1200) {
        // return
        _this.screenWidth = 1200;
      }
      if (_this.screenWidth > 1920) {
        this.screenWidth = 1920;
      }
      _this.width = (_this.screenWidth * 0.62 * 380) / 1147;
      console.log("地图", _this.width);
    });
    // window.onresize = () => {
    //   return (() => {
    //     _this.screenWidth = document.body.clientWidth;
    //     // this.screenHeight = document.body.clientHeight;
    //     if (_this.screenWidth < 1200) {
    //       // return
    //       _this.screenWidth = 1200;
    //     }
    //     if (_this.screenWidth > 1920) {
    //       this.screenWidth = 1920;
    //     }
    //     _this.width = (_this.screenWidth * 0.7 * 380) / 1147;
    //     // this.$set(this.width, 'height' , (this.screenWidth * 0.7 * 380) / 1147);
    //     // console.log("aaaaaaa",this.width.height);
    //     // location. reload()
    //   })();
    // };
  },
};
</script>

<style lang="less">
.address {
  // width: 1200px;
  // height: 500px;
  width: 62%;
  //   background-color: pink;
  margin: 2% auto 0;
  padding: 0 0.105263rem;
  box-sizing: border-box;

  // position: relative;
  .address-title {
    width: 100%;
    margin: 4% auto;
    position: relative;

    p:nth-child(2) {
      font-size: .2105rem;
      // font-size: 45px;
      // font-weight: 500;
      color: #444444;
    }

    p:nth-child(1) {
      font-size: .1053rem;
      // font-size: 16px;
      color: #b8b8b8;
      // margin-top:
    }

    .xuxian {
      width: 88%;
      height: 0;
      border-bottom: 0.010526rem dotted #a5a5a5;
      position: absolute;
      right: 2%;
      top: 89%;
    }

    .address-diqu {
      position: absolute;
      right: 0%;
      top: 50%;

      ul {
        display: flex;
        line-height: 0.105263rem;
        font-size: .1053rem;
        // line-height: 16px;
        // font-size: 16px;
        color: #060606;
        cursor: pointer;

        li {

          // padding: 0 5px;
          span {
            color: #b8b8b8;
            margin-left: 0.026316rem;
          }

          .active {
            color: #009bdd;
          }
        }
      }
    }
  }

  .lunbo {
    margin-top: 4%;
    word-break: break-all;

    // background: pink;
    .lunbo-son {
      width: 100%;

      .lunbo-img {

        // flex: 3;
        img {
          width: 100%;
          vertical-align: middle;
        }
      }

      .UnderBox {
        position: relative;
        white-space: pre-wrap;

      }

      .Under {
        position: absolute;
        left: 0%;
        top: 96%;
      }

      .lunbo-dizhi {
        top: 0;
        position: absolute;
        width: 20%;
        padding-left: 4%;

        // flex: 2;
        // padding
        .city {
          width: 100%;
          display: flex;
          margin-top: 20%;
          margin-bottom: 12%;
          text-align: center;
          line-height: .210526rem;

          img {
            // width: 100%;
            vertical-align: middle;
          }

          div:first-child {
            margin-right: 3%;
          }

          div:last-child {
            font-size: .1368rem;
            // font-size: 18px;
            // color: rgb(6, 6, 6);
            color: #444444;
          }
        }

        .city2 {
          width: 100%;
          display: flex;
          margin-top: 10%;
          margin-bottom: 12%;
          text-align: center;
          line-height: .210526rem;

          img {
            // width: 100%;
            vertical-align: middle;
          }

          div:first-child {
            margin-right: 3%;
          }

          div:last-child {
            font-size: .1368rem;
            // font-size: 18px;
            // color: rgb(6, 6, 6);
            color: #444444;
          }
        }

        .city-juti {
          width: 100%;
          font-size: .09868rem;
          // font-size: 12px;
          // color: rgb(92, 92, 92);
          color: #444444;
          text-align: justify;

          img {
            width: .0737rem;
            margin: 0 4% 0 0;
            vertical-align: initial;
          }
        }

        .city-juti2 {
          width: 100%;
          font-size: 12px;
          // font-size: 12px;
          // color: rgb(92, 92, 92);
          color: #444444;
          text-align: justify;
          white-space: pre-wrap;

          img {
            width: .0737rem;
            margin: 0 4% 0 0;
            vertical-align: initial;
          }
        }
      }

      .lunbo-dizhi2 {
        top: 0;
        position: absolute;
        width: 26%;
        padding-left: 2%;

        // flex: 2;
        // padding
        .city {
          width: 100%;
          display: flex;
          margin-top: 20%;
          margin-bottom: 12%;
          text-align: center;
          line-height: .210526rem;

          img {
            // width: 100%;
            vertical-align: middle;
          }

          div:first-child {
            margin-right: 3%;
          }

          div:last-child {
            font-size: .1368rem;
            // font-size: 18px;
            // color: rgb(6, 6, 6);
            color: #444444;
          }
        }

        .city2 {
          width: 100%;
          display: flex;
          margin-top: 8%;
          margin-bottom: 12%;
          text-align: center;
          line-height: .210526rem;

          img {
            // width: 100%;
            vertical-align: middle;
          }

          div:first-child {
            margin-right: 3%;
          }

          div:last-child {
            font-size: .1368rem;
            // font-size: 18px;
            // color: rgb(6, 6, 6);
            color: #444444;
          }
        }

        .city-juti {
          width: 100%;
          font-size: .09868rem;
          // font-size: 12px;
          // color: rgb(92, 92, 92);
          color: #444444;
          text-align: justify;

          img {
            width: .0737rem;
            margin: 0 4% 0 0;
            vertical-align: initial;
          }
        }

        .city-juti2 {
          width: 96%;
          font-size: 12px;
          // font-size: 12px;
          // color: rgb(92, 92, 92);
          color: #444444;
          text-align: justify;
          white-space: pre-wrap;

          img {
            width: .0737rem;
            margin: 0 4% 0 0;
            vertical-align: initial;
          }
        }
      }
    }
  }
}
</style>
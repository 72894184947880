<template>
  <!-- 服务能力 -->
  <div class="service">
    <div class="top">
      <Header :activeId="activeId" />
    </div>

    <div class="zhutu">
      <img src="//img12.360buyimg.com/zx/jfs/t1/124249/5/35771/67468/64c8d9d9F13631e14/e528b027231a7fb1.jpg" alt="" />
      <div class="zhutuability">
        <div>{{ $t("Service.topTitle") }}</div>
        <div>{{ $t("Service.topsmallTitle") }}</div>
      </div>
    </div>

    <div class="box">
      <div class="box-son">
        <!-- <div class="fuwujieshaoBg">
          <div class="fuwujieshao" >
            <div>
              <h2>RUOYUCHEN</h2>
              <p>
                若羽臣深耕电商行业十余年，在店站运营、整合营销、数据挖掘、供应链管理等方面有着深刻的积累和洞察，服务能力覆盖了淘系、
                京东、拼多多、抖音、快手等多个平
                台市场，致力于为企业提供全链路、全渠道、全场景的数字化服务。
                </p>
                 <br>
              <p>
                截至目前，若羽臣已累计服务了宝洁、美赞臣、Swisse等超百个国际国内知名品牌，
                成为其在线生意增长的长期忠实合作伙伴。
              </p>
            </div>
          </div>
        </div> -->


        <div class="nenglijieshao" :style="{ height: isMobile ? '6.263158rem' : '5.263158rem' }">
          <AbilityText />
        </div>
      </div>

      <!-- <div class="banner">
        <img src="../assets/fuwuanli/serviceDemo.png" alt="" />

        <div class="bannerzhutu">
          <div>服务案例</div>
          <div>SERVICE CASE</div>
        </div>
      </div> -->
      <!-- 服务案例 -->
      <div class="servecase">
        <Servicecase />
      </div>


      <div class="bottom">
        <Bottom />
      </div>
    </div>
  </div>
</template>

<script>
import { findSevice, findSeviceCases } from "@/api/index.js";
import Header from "@/components/header/Header.vue";
import AbilityText from "@/components/abilityText/AbilityText.vue";
import Bottom from "@/components/bottomNav/Bottom.vue";
import Servicecase from "./Servicecase.vue";
export default {
  components: {
    Header,
    AbilityText,
    Bottom,
    Servicecase
  },
  data() {
    return {
      isMobile: false,                //是否移动端
      activeId: 2,
      aa: 100,
      // activeId:4,
      style: "",
      trans: 0,                 //子标题移动的距离
      titrans: 0,               //导航栏移动的距离
      reverse: false,
      index: 0,                 //访问的品牌
      introduceIndex: 0,        //访问的子标题
      queryParam: {              //查询参数
        page_no: 1,
        page_size: 50,
      },
      indexId: 0,               //访问的标题的Id
      datas: [
        {
          brief_introduction: '',     //简介
          logo: '',              //logo图
          id: 0,
          img: '',               //大图
          title: '',                 //标题
          create_time: '',
          detail_list: [
            {
              content: '',         //子级标题正文
              title: '',         //子级标题名
              width: ''          //子级标题尺寸
            }
          ],
        }
      ],                //存放获取到的数据
    };
  },
  mounted() {
    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

    // const _this = this;
    // if (document.body.clientWidth < 1680) {
    //   _this.fontSize = 14;
    // }
    // if (document.body.clientWidth >= 1680) {
    //   _this.fontSize = 18;
    // }
    // window.addEventListener("resize", () => {
    //   if (document.body.clientWidth < 1680) {
    //     _this.fontSize = 14;
    //   }
    //   if (document.body.clientWidth >= 1680) {
    //     _this.fontSize = 18;
    //   }
    // });
  },
  methods: {
    // left() {
    //   this.style = "";
    // },
    // right() {
    //   this.style = "transform: translateX(-140px);";
    // },







   

    //获取点击标题的序号
    getIndex(n) {
      // console.log(n);
      this.introduceIndex = 0;
      this.trans = 0;
      this.index = n;
      this.getLabel();
    },
    // 增加索引值
    addIndex() {
      this.introduceIndex = 0;
      this.trans = 0;
      if (this.index < this.datas.length - 1) {
        this.index++;
      }
      if (this.index <= 6) {
        this.titrans = 0;
      } else if (this.index < this.datas.length) {
        this.titrans = this.index - 6
      }
      // console.log(this.titrans);
      this.getLabel();
    },
    // 减少索引值
    subIndex() {
      this.introduceIndex = 0;
      this.trans = 0;
      if (this.index > 0) {
        this.index--;
      }
      if (this.index <= 6) {
        this.titrans = 0;
      } else if (this.index < this.datas.length) {
        this.titrans = this.index - 6
      }
      this.getLabel();
    },
  },
};
</script>

<style scoped lang="less">
.service {
  width: 100%;
  position: relative;

  .top {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .zhutu {
    width: 100%;
    position: relative;

    &>img {
      width: 100%;
    }

    .zhutuability {
      // letter-spacing: .0105rem;
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      // right: 0;
      margin: auto;
      text-align: center;

      div:first-child {
        color: rgb(255, 255, 255);
        //  font-size:  .2105rem;
        font-size: .3158rem;
        text-align: center;
        //  font-weight: 500;
        margin-bottom: .052632rem;
      }

      div:last-child {
        color: rgb(255, 255, 255);
        // font-size: .1368rem;
        font-size: .0842rem;
        text-align: center;
      }
    }
  }

  .banner {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    .bannerzhutu {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      // right: 0;
      margin: auto;
      text-align: center;

      // letter-spacing: .0105rem;
      div:first-child {
        color: rgb(255, 255, 255);
        //  font-size:  .2105rem;
        font-size: .3158rem;
        text-align: center;
        //  font-weight: 500;
        margin-bottom: .052632rem;
      }

      div:last-child {
        color: rgb(255, 255, 255);
        // font-size: .1368rem;
        font-size: .0842rem;
        text-align: center;
      }
    }
  }

  .box {
    height: auto;
    width: 100%;
    position: relative;

    &>img {
      width: 100%;
    }

    .box-son {
      width: 100%;
      position: relative;
      left: 0;
      top: 0;
      white-space: pre-wrap;

      .fuwujieshaoBg {
        height: 2rem;
        background-color: #009bdd;

        .fuwujieshao {
          width: 63%;
          margin: 0 auto;
          padding: 5% 1%;
          font-size: .1053rem;
          color: rgb(255, 255, 255);
          text-align: center;

          div {
            width: 100%;
          }

          h2 {
            font-size: .2105rem;
            margin-bottom: 2%;
          }

          p {
            text-align: justify;
            width: 100%;
            // font-size: .0842rem;
            font-size: .1053rem;
            line-height: .1579rem;
          }
        }

      }

      .nenglijieshao {
        // height: 5.263158rem;
        width: 100%;
      }
    }

    .bottom {
      width: 100%;
      // margin-top: 210px;
      position: relative;
      left: 0;
      bottom: 0;
    }
  }

  .service-case-center {
    width: 100%;

    // position: relative;
    &>img {
      width: 100%;
    }

    .BgLine {
      height: .0053rem;
      border-top: .0053rem solid #b8b8b8;
      position: absolute;
      top: 14%;
      width: 100%;
    }

    .center-box {
      width: 100%;
      // height: 100%;
      // position: relative;
      // left: 0;
      // top: 0;
      background-color: #fff;

      .navCon {
        // width: 6.315789rem;
        // width: 63%;
        padding: 0 .078947rem 0;
        margin: 2% auto;
        display: flex;
        justify-content: space-between;
        border-bottom: thin solid #dedede;
        padding: 0 0 2% 0;

        .left {
          // background-color: red;
          // flex: 1;
          width: .157895rem;
          position: absolute;
          left: 18%;

          div {
            width: .105263rem;
            height: .105263rem;
            border: .015789rem solid #ddd;
            // border-right-color: transparent;
            border-color: #ddd transparent transparent #ddd;
            transform: rotate(-45deg);
            margin-top: .042105rem;
          }
        }

        .right {
          position: absolute;
          right: 18%;
          // background-color: red;
          // flex: 1;
          width: .157895rem;
          cursor: pointer;

          div {
            width: .105263rem;
            height: .105263rem;
            border: 3px solid #ddd;
            // border-right-color: transparent;
            border-color: #ddd #ddd transparent transparent;
            transform: rotate(45deg);
            margin-top: .042105rem;
          }
        }

        .navConcenter {
          width: 6.09rem;
          overflow: hidden;
          margin: 0 auto;

          .navConcenter-son {

            // transition: all 0.3s linear;
            // width: 1200px;
            // overflow-x: scroll;
            // overflow-y: hidden;
            ul {
              // width: 1200px;
              width: 100%;
              display: flex;
              font-size: .1368rem;
              // font-size: 16px;
              // background: skyblue;
              // color: rgb(148, 148, 148);
              color: #444444;
              flex-wrap: row;
              text-align: center;
              justify-content: space-between;

              li {
                // width: 14.2857%;
                flex-shrink: 0;
                white-space: nowrap;
                padding: 0 .217895rem;
                cursor: pointer;
                text-align: center;
                position: relative;
              }

              // li:nth-child(1) {
              //   width: 20%;
              // }

              // li:nth-child(2),
              // li:nth-child(3),
              // :nth-child(4) {
              //   width: 12.345%;
              // }

              .active {
                color: #009bde;
              }
            }
          }
        }
      }

      .neirongCon {
        width: 100%;
        margin: 0.1rem 0 0.4rem;
        background-color: #fff;

        .neirong {
          width: 73%;
          height: 100%;
          margin: 0 auto;
          overflow: hidden;

          .introduceBox {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            overflow-x: visible;
            transition: 0.8s;
          }

          .introduceTwo {
            text-align: center;
            flex-shrink: 0;
            width: 50%;
            cursor: pointer;
            position: relative;

            .logoImg {
              background-color: #fff;
              width: 100%;
              height: 70px;
              // line-height: .473684rem;
              display: flex;
              align-items: center;
              position: absolute;
              top: 4%;
              left: 0%;
              opacity: 1;
              text-align: center;
              z-index: 1;
            }

            .Dot {
              position: absolute;
              font-size: 40px;
              color: #d7d7d7;
              top: 0%;
              left: 47%;
            }

            .title {
              height: .157895rem;
              font-size: 0.118421rem;
              // font-size: 16px;
              margin: 12% 0 2% 0;
              // color: #5f5f5f;
              color: #444444;
            }

            .text {
              // font-weight: 400;
              //  font-size: .0842rem;
              font-size: .1053rem;
              // font-size: 16px;
              line-height: .1316rem;
              padding: 2% 8%;
              // color: #949494;
              color: #b8b8b8;
              text-align: justify;
              text-align-last: left;
              // letter-spacing: -0.0047rem;
              line-height: .1684rem;
            }
          }

          .activeTwo {
            color: #009bde;

            .BgLine {
              height: .0053rem;
              border-top: .0053rem solid #009bde;
              position: absolute;
              top: 14%;
              width: 100%;
            }

            .Dot {
              position: absolute;
              font-size: 40px;
              color: #009bde;
              top: 0%;
              left: 47%;
            }

            .title {
              height: .157895rem;
              font-size: 0.118421rem;
              // font-size: 16px;
              margin: 12% 0 2% 0;
              color: #009bde;
            }

            .text {
              // font-weight: 400;
              text-align: justify;
              text-align-last: left;
              // font-size: .0842rem;
              font-size: .1053rem;
              // font-size: 16px;
              padding: 2% 8%;
              color: #B8B8B8;
              line-height: .1684rem;
            }
          }

          .introduce {
            text-align: center;
            flex-shrink: 0;
            width: 25%;
            cursor: pointer;
            position: relative;

            .logoImg {
              background-color: #fff;
              width: 100%;
              height: .473684rem;
              // line-height: .473684rem;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 4%;
              left: 0%;
              opacity: 1;
              text-align: center;
              z-index: 1;
            }

            .Dot {
              position: absolute;
              font-size: 40px;
              color: #d7d7d7;
              top: 0%;
              left: 47%;
            }

            .title {
              height: .157895rem;
              font-size: 0.118421rem;
              // font-size: 16px;
              margin: 25% 0 2% 0;
              // color: #5f5f5f;
              color: #444444;
            }

            .text {
              text-align: justify;
              text-align-last: left;
              // font-weight: 400;
              //  font-size: .0842rem;
              font-size: .1053rem;
              // font-size: 16px;
              line-height: .1316rem;
              padding: 5% 8%;
              // color: #949494;
              color: #b8b8b8;
              line-height: .1684rem;
            }
          }

          .active {
            color: #009bde;

            .BgLine {
              height: .0053rem;
              border-top: .0053rem solid #009bde;
              position: absolute;
              width: 100%;
            }

            .Dot {
              position: absolute;
              font-size: 40px;
              color: #009bde;
              top: 0%;
              left: 47%;
            }

            .title {
              height: .157895rem;
              font-size: 0.118421rem;
              // font-size: 16px;
              margin: 25% 0 2% 0;
              color: #009bde;
            }

            .text {
              text-align: justify;
              text-align-last: left;
              // font-weight: 400;
              //  font-size: .0842rem;
              font-size: .1053rem;
              // font-size: 16px;
              line-height: .1316rem;
              padding: 5% 8%;
              color: #b8b8b8;
              line-height: .1684rem;
            }
          }
        }
      }

      .changtu {
        width: 70%;
        margin: 0 auto .421053rem;
        text-align: center;

        img {
          width: 100%;
          // display: block;
        }
      }
    }

    .bottomsss {
      width: 100%;
      // position: absolute;
      // bottom: 0;
      // left: 0;
    }
  }
}
</style>

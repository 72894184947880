<template>
  <div class="ability-text" :style="{ width: isMobile ? '80%' : '63%' }">
    <div class="ability-top">
      <p>{{ $t("Service.smallTitle") }}</p>
      <p> {{ $t("Service.title") }} <span>DETAILED INTRODUCTION</span></p>
    </div>
    <div class="box-son">
      <ul v-if="$store.state.isZh" class="icon-box" @click="$router.push('/service')">
        <li v-for="(item, i) in navList" :key="i" @mouseover="mouseOver(i)">
          <span :class="{ active: index == i }">
            <span> {{ item.number }} </span> {{ item.text }}
          </span>
          <!-- <img v-if="index == i" src="../../assets/fuwunengli/arrow.png" alt=""> -->
        </li>
      </ul>
      <!-- 英文版 -->
      <ul v-else class="icon-box" @click="$router.push('/service')">
        <li v-for="(item, i) in navList2" :key="i" @mouseover="mouseOver(i)" :style="{fontSize: theLan? '.1368rem':'14px',lineHeight:  theLan? '.315789rem' : '16px', }">
          <span :class="{ active: index == i }">
            <div style="float: left;height: 40px;padding: 0 1%;color: #b8b8b8;"> {{ item.number }} </div> {{ item.text }}
          </span>
          <!-- <img v-if="index == i" src="../../assets/fuwunengli/arrow.png" alt=""> -->
        </li>
      </ul>

      <div class="fuceng">
        <div class="fuceng-img" v-if="$store.state.isZh">
          <img src="https://img.alicdn.com/imgextra/i1/4045700848/O1CN01WuflHp1I8Qj9DPdWJ_!!4045700848.png" alt=""
            v-if="index == 0" />
          <img src="https://img.alicdn.com/imgextra/i1/4045700848/O1CN01db4JzF1I8QjEOdg22_!!4045700848.png" alt=""
            v-if="index == 1" />
          <img src="https://img.alicdn.com/imgextra/i2/4045700848/O1CN01jsQsEr1I8QjBH5emU_!!4045700848.png" alt=""
            v-if="index == 2" />
          <img src="https://img.alicdn.com/imgextra/i3/4045700848/O1CN01UdDQHy1I8QjCSfBBQ_!!4045700848.png" alt=""
            v-if="index == 3" />
          <img src="https://img.alicdn.com/imgextra/i4/4045700848/O1CN01bIA7PO1I8Qj2hb8SA_!!4045700848.png" alt=""
            v-if="index == 4" />
          <img src="https://img.alicdn.com/imgextra/i3/4045700848/O1CN01bHrxRB1I8QjEOd8iv_!!4045700848.png" alt=""
            v-if="index == 5" />
          <img src="https://img.alicdn.com/imgextra/i2/4045700848/O1CN01CjhL251I8QjBEODhF_!!4045700848.png" alt=""
            v-if="index == 6" />
          <img src="https://img.alicdn.com/imgextra/i2/4045700848/O1CN01l6IzBW1I8QjHKU2aE_!!4045700848.png" alt=""
            v-if="index == 7" />
          <img src="https://img.alicdn.com/imgextra/i2/4045700848/O1CN01JyX3RK1I8QjBQU3OC_!!4045700848.png" alt=""
            v-if="index == 8" />
          <img src="https://img.alicdn.com/imgextra/i4/4045700848/O1CN01q03CnO1I8Qj2hg2MC_!!4045700848.png" alt=""
            v-if="index == 9" />
        </div>
        <div class="fuceng-img" v-else>
          <img src="//img11.360buyimg.com/zx/jfs/t1/196484/11/35777/103899/64cc7a0bFee98d086/d28d49bfbab3c13e.png" alt=""
            v-if="index == 0" />
          <img src="//img14.360buyimg.com/zx/jfs/t1/220288/1/35754/88933/64cc7a0bFdb232a6d/ee8d3da41f0022ee.png" alt=""
            v-if="index == 1" />
          <img src="//img20.360buyimg.com/zx/jfs/t1/142370/34/37865/127634/64cc7a0bF243229b6/24565f7ac5eacb07.png" alt=""
            v-if="index == 2" />
          <img src="//img14.360buyimg.com/zx/jfs/t1/175542/5/38371/63036/64cc7a0bF1f128be0/0827d2cbabd86b1b.png" alt=""
            v-if="index == 3" />
          <img src="//img11.360buyimg.com/zx/jfs/t1/91711/12/37078/72381/64cc7a0cF8ff731dd/f846138398fe2b96.png" alt=""
            v-if="index == 4" />
          <img src="//img14.360buyimg.com/zx/jfs/t1/149144/18/38341/621281/64cc7a0bF28f9eb47/a3f809dbb94cec2e.png" alt=""
            v-if="index == 5" />
          <img src="//img12.360buyimg.com/zx/jfs/t1/192554/7/35804/68266/64cc7a48Faa3a9b7e/4714125fc5a0a2ea.png" alt=""
            v-if="index == 6" />
          <img src="//img14.360buyimg.com/zx/jfs/t1/168604/9/40048/61646/64cc7a48Fa782d408/6f487193823b0c87.png" alt=""
            v-if="index == 7" />
          <img src="//img20.360buyimg.com/zx/jfs/t1/185661/40/35776/137854/64cc7a0dF183d1b55/73a9d201614a2ac1.png" alt=""
            v-if="index == 8" />
          <img src="//img20.360buyimg.com/zx/jfs/t1/177847/30/36116/90765/64cc7a0dF578babbe/3c6ccbe07564312c.png" alt=""
            v-if="index == 9" />
        </div>
        <div class="fuceng-son">
          <!-- <p :style="{ letterSpacing: index == 7|| index == 2? '1px':'0px'}">{{ data[index].describe }}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      // isZh: false,
      navList: [
        {
          "number": "01",
          "text": "全渠道运营",
        },
        {
          "number": "02",
          "text": "数字营销",
        },
        {
          "number": "03",
          "text": "用户运营",
        },
        {
          "number": "04",
          "text": "直播服务",
        },
        {
          "number": "05",
          "text": "内容种草",
        },
        {
          "number": "06",
          "text": "数字化能力",
        },
        {
          "number": "07",
          "text": "视觉设计",
        },
        {
          "number": "08",
          "text": "渠道分销",
        },
        {
          "number": "09",
          "text": "客服管理",
        },
        {
          "number": "10",
          "text": "供应链管理",
        },

      ],
      navList2: [
        {
          "number": "01",
          "text": "Omnichannel Operation",
        },
        {
          "number": "02",
          "text": "Digital Marketing",
        },
        {
          "number": "03",
          "text": "Customer Relationship Management",
        },
        {
          "number": "04",
          "text": "Live Streaming Service",
        },
        {
          "number": "05",
          "text": "Recommendation via \n Content Creation",
        },
        {
          "number": "06",
          "text": "Digitalization",
        },
        {
          "number": "07",
          "text": "Visual Design",
        },
        {
          "number": "08",
          "text": "Channel Distribution",
        },
        {
          "number": "09",
          "text": "Customer Service",
        },
        {
          "number": "10",
          "text": "Supply ChainManagement",
        },

      ],
      data: [
        {
          title: "全渠道综合运营能力",
          describe:
            "若羽臣在深耕淘宝、京东、拼多多、唯品会等电商生态平台的同时。拓展抖音、快手小红书等多个渠道的生意增长机会全面覆盖超过 20 个线上渠道。",
        },
        {
          title: "精细化运营能力",
          describe:
            "以驱动线上生意增长为目标，精准洞察市场趋势和消费者需求，为品牌提供包括品牌定位、创意设计、内容营销、营销技术、媒介投放在内的全链路数字营销服务。",
        },
        {
          title: "数据分析洞察能力",
          describe:
            "基于消费者数据洞察，帮助品牌完成用户数据资产沉淀和分析管理，全链路投放监控系统，在为品牌提供核心媒介推广监控的同时，接入自动化估价工具，实现推广产出的最优化，持续追踪生意转化、消费者画像和舆情趋势，帮助品牌有针对性地调整投放策略。用户精细化运营时代，若羽臣充分应用生态伙伴能力及大数据基础设施，助力品牌深度经营消费者全域旅程，并沉淀出完整的洞察及定制化经营策略,帮助品牌更好地实现用户全生命周期价值提升。",
        },
        {
          title: "直播、视频运营能力",
          describe:
            "拥有专业的直播、短视频团队，阿里官方认证淘宝直播服务商，合作、签约数百名达人主播,直播基地面积达 2000 m,累计粉丝近亿人，可满足品牌方多种形式的内容营销需求",
        },
        {
          title: "内容种草能力",
          describe:
            "基于沉淀多年的全域内容营销经验,若羽臣洞悉小红书、抖音、快手等各大内容平台玩法为品牌方提供低成本高转化的营销服务体验.带动店铺销售额增长。",
        },
        {
          title: "站内外投放能力",
          describe:
            "依托大数据、云计算等技术，围绕运营核心业务场景，自研多套系统，以数字化赋能品牌生意效率的全面提升。围绕运营核心业务场景,向前、中、后台部门针对性输出超过 500 种场景的 DASHBOARD 和报表,并通过促销设置、页面维护的自动化操作,实时捕捉各种异常，提高运营效率，基于消费者数据洞察,帮助品牌完成用户数据资产沉淀和分析管理:全链路投放监控系统，在为品牌提供核心媒介推广监控的同时，接入自动化估价工具.实现推广产出不断优化.帮助品牌有针对性地调整投放策略。",
        },
        {
          title: "视觉设计能力",
          describe:
            "依托自身强大的电商视觉设计团队，帮助品牌店铺构建依托自身强大的电商视觉设计团队，帮助品牌店铺构建脱胎于品牌 VI，又具有数字传播性的电商视觉体系和视觉规范.将创意和生意有机结合,在电商环境中通过视觉击穿用户认知和记忆,促进生意增长和消费者粘性提升。",
        },
        {
          title: "营销资源整合能力",
          describe:
            "若羽臣已构建起包括社交类、自媒体、批采业务、社群团购等全渠道分销网络，全面覆盖目标人群,进一步提升品牌市场份额,年度分销业绩达 2.4 亿。",
        },
        {
          title: "全球端到端供应链管理能力",
          describe:
            "通过数字化智能工具的运用,定制符合多平台、多店铺、多渠道的高效用户服务系统,有效提升客服端智能化水平，从而提高整体承接质量，为品牌电商业务提供综合客户服务能力。除了满足客户咨询、售后服务需求和投诉处理，深度融入消费者与品牌互动的全生命周期，实现品牌用户精细化管理,提升消费者体验。",
        },

        {
          title: "信息技术支持能力",
          describe:
            "供应链管理覆盖全部主流平台，打造了完善的供应链质量管控体系，确保整体产品交付满足消费者差异化需求。为品牌提供一站式跨境物流服务，多仓并发灵活调配。在以双十-为代表的超大型电商促销活动中，体现了较高揽收效率、峰值处理效率以及货物配送效率，得到了品牌的高度认可。",
        },
      ],
      isMobile: false,                //是否移动端
      // fontSize1: 18,
      // fontSize2: 20,
    };
  },
  methods: {
    mouseOver(n) {
      // console.log(this.isZh)
      this.index = n;
    },
  },
  computed: {
    theLan() {
      // 计算数值的总和
      return this.$store.state.isZh
    },
  },
  created() {
    // this.isZh = this.$store.state.isZh;
    // console.log("isZh", this.isZh);
  },
  mounted() {
    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    // const _this = this;
    // if (document.body.clientWidth < 1680) {
    //   _this.fontSize1 = 14;
    //   _this.fontSize2 = 17;
    // }
    // if (document.body.clientWidth >= 1680) {
    //   _this.fontSize1 = 18;
    //   _this.fontSize2 = 20;
    // }
    // window.addEventListener("resize", () => {
    //   if (document.body.clientWidth < 1680) {
    //     _this.fontSize1 = 14;
    //     _this.fontSize2 = 17;
    //   }
    //   if (document.body.clientWidth >= 1680) {
    //     _this.fontSize1 = 18;
    //     _this.fontSize2 = 17;
    //   }
    // });
  },
};
</script>

<style scoped lang="less">
.ability-text {
  width: 63%;
  padding: 0 3% 0 1%;
  margin: 0 auto;

  .ability-top {
    width: 100%;
    border-bottom: thin solid #b8b8b8;
    padding: 9.6% 0 0 0;

    p:nth-child(2) {
      // font-weight: 500;
      font-size: .2105rem;
      position: relative;
      // font-size: 45px;

      span {
        font-size: .1053rem;
        // font-size: 16px;
        color: #b8b8b8;
        // color:  #444444;
        position: absolute;
        left: 75%;
        bottom: 5px;
      }
    }

    p:nth-child(1) {
      color: #b8b8b8;
      // color:  #444444;
      font-size: .1053rem;
      // font-size: 16px;
      font-weight: 400;
    }
  }

  .box-son {
    width: 100%;
    position: relative;
    margin: 4% 0 0 0;
    white-space: pre-wrap;

    .icon-box {
      width: 100%;

      li {
        width: 35%;
        min-height: 45px;
        border-left: .010526rem solid #fcfcfc;
        border-right: .010526rem solid #fcfcfc;
        // color: #6b6b6b;
        color: #444444;
        // font-size: 18px;
        font-size: .1368rem;
        line-height: .315789rem;
        margin: 0 0 0.8% 0;
        position: relative;

        img {
          margin: -1% 0 0 2%;
          width: 8%;
        }

        .aa {
          display: inline-block;
        }

        .bb {
          display: none;
        }

        span {
          display: inline-block;
          width: 80%;
          height: .263158rem;
          padding: 0 1%;
          font-weight: 500;

          span {
            display: inline;
            height: .263158rem;
            padding: 0 1%;
            font-weight: normal;
            color: #b8b8b8;
            // color:  #444444;
          }
        }
      }

      .active {
        cursor: pointer;
        color: #019ede;
        border-bottom: thin solid #019ede;

        span:nth-child(1) {
          color: #019ede;
        }

        .aa {
          display: none;
        }

        .bb {
          display: inline-block;
        }
      }
    }

    .fuceng {
      width: 65%;
      position: absolute;
      left: 35%;
      top: 2%;
      padding: 0 .005263rem;

      .fuceng-img {
        img {
          // width: 4.1684rem;
          width: 100%;
        }
      }

      .fuceng-son {
        width: 100%;
        height: 0.7895rem;
        position: absolute;
        left: 0%;
        top: 77%;
        padding: 0 .2632rem;
        // font-size: 0.0842rem;
        font-size: .0592105rem;
        color: #ffffff;
        display: flex;
        align-items: center;

        h3 {
          text-align: center;
          margin-top: 10%;
          margin-bottom: .157895rem;
          font-weight: 600;
        }

        p {
          text-align: justify;
          line-height: .12rem;
          font-weight: 100;
        }

      }
    }
  }
}
</style>
<template>
  <!-- <div>
        123
    </div> -->
  <div class="home">
    <div class="top">
      <Header :activeId="activeId" />
    </div>
    <div class="home-box">
      <div>
        <Banner />
      </div>

      <div class="home-about">
        <div class="home-about-son">
          <div class="guanyu" :style="{ width: isMobile ? '88.9%' : '63%' }">
            <div class="guanyu-son">
              <p class="title">品牌介绍</p>
              <div class="guanyu-son-text">
                <p class="text">
                  绽家LYCOCELLE，源自新西兰的高端自然植萃品牌。秉持可持续性发展的经营理念，在高效清洁的基础上，绽家LYCOCELLE始终践行绿色可降解的环保理念，为消费者提供家庭清洁解决方案。
                </p>
              </div>
              <p class="title">品牌起源</p>
              <div class="guanyu-son-text">
                <p class="text">
                  2009年，品牌创始人Stephanie
                  Evans开始企划绿色高效的个人清洁护理品牌LYCOCELLE。2017年，经过长达八年的研究，LYCOCELLE品牌正式于新西兰成立，并充分利用了Horopito霍比托叶、KAWAKAWA、Harekeke等新西兰古老植物在除菌、除味等方面的特殊药用价值，将其引入于产品中。2019年，若羽臣正式收购LYCOCELLE,获得其品牌所有权。2020年末，若羽臣以“绽家”为中文名将LYCOCELLE品牌正式引入中国市场，并以子公司“摩亚方舟”作为其运营主体公司，实现了LYCOCELLE品牌在中国的运营和生产。
                </p>
              </div>
              <p class="title">品牌文化</p>
              <div class="guanyu-son-text">
                <p class="text">
                  品牌定位：源自新西兰的高端自然植萃品牌。 <br />
                  品牌使命：以更高效、天然、可持续的洁净方式，为消费者提供全方位的家庭清洁解决方案。<br />
                  所属行业：家清个护。
                </p>
              </div>
              <!-- <p class="title">品牌理念</p>
              <div class="guanyu-son-text">
                <p class="text">
                  采用科学方式进行研发，致力于推动上游研发技术和生产工艺的精进创新，生产出环保创新型产品，降低地球能源的消耗
                </p>
              </div> -->
              <p class="title">品牌荣誉</p>
              <div class="guanyu-son-text">
                <p class="text">
                  2021年，绽家荣登天猫双十一预售爆款内衣洗液类目、双十一当天进口内衣洗液类目TOP1，位列高增速品牌TOP1。<br />
                  2022年，绽家LYCOCELLE荣获“2022年度天猫金妆奖新锐之星品牌奖”。<br />
                  2022年，绽家LYCOCELLE入选2022年度上海网络新消费品牌榜单TOP50。<br />
                  2022年，绽家LYCOCELLE入选FUTURE
                  2022消费新势力名单，获评高潜力消费品牌。<br />
                  2022年，绽家入选腾讯2022「域见超新星」新锐品牌榜单-行业新星品牌日百家清行业。<br />
                  2023年，绽家入选新妆大赏2022-2023年度内生力品牌。<br />
                  2023年，绽家荣登第三届毕马威中国消费50企业榜单-赛道特别奖。<br />
                  2023年，绽家入选2023消费新势力年度品牌榜。<br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <Bottom />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Banner from "@/components/banner/Banner.vue";
import Bottom from "@/components/bottomNav/Bottom.vue";
export default {
  components: {
    Header,
    Bottom,
    Banner,
  },
  data() {
    return {
      isMobile: false, //是否移动端
    };
  },

  mounted() {
    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
  },

  methods: {},
  created() {},
};
</script>

<style scoped lang="less">
.home {
  // width: 1920px;
  width: 100%;
  // min-width: 1920px;
  position: relative;

  .bgBlank {
    height: 3.857895rem;
  }

  .top {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    // background-color: rgba(0, 0, 0, 0);
    color: #444444;
  }

  .home-box {
    width: 100%;
    // background: url("../assets/logo/背景.png");
    background-size: cover;
    background-repeat: no-repeat;

    .home-about {
      width: 100%;
      position: relative;

      & > img {
        width: 100%;
      }

      .home-about-son {
        width: 100%;
        height: 100%;
        position: relative;
        left: 0;
        top: 0;

        .guanyu {
          // width: 63%;
          // height: 100%;
          // background: pink;
          padding: 10px;
          margin: 0 auto;
          position: relative;

          .guanyu-son {
            width: 90%;
            // height: 70%;
            // background-color: skyblue;
            margin: 12% auto;

            .title {
              font-size: 0.2105rem;
              color: #444444;
            }

            .guanyu-son-text {
              margin-top: 0.263158rem;
              margin-bottom: 0.38rem;
              // color: rgb(95, 95, 95);
              color: #444444;

              .sonTitle {
                color: #444444;
                font-size: 0.1368rem;
                line-height: 0.1895rem;
                // font-size: 24px;
                // line-height: 28px;
              }

              .text {
                color: #b8b8b8;
                line-height: 0.1684rem;
                margin: 2% 0 0 0;
                font-size: 0.1053rem;
              }
            }
          }

          .more {
            width: 10%;
            position: absolute;
            right: 6%;
            top: 45%;

            img {
              width: 0.5421rem;
            }
          }
        }
      }

      .home-about-two {
        width: 100%;
        position: absolute;
        left: 0;
        top: 2.036842rem;
      }
    }
  }
}
</style>

<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created() {
    console.log('当前在new 页面', this.getQueryVariable('id'))
  },
  methods: {
  getQueryVariable(variable) {
    console.log(window.location)
      let query = window.location.hash.substring(7);
      if (!query) {
        query = window.location.search.substring(1);
      }
      // console.log("query;", query);
      let vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
};
</script>

<style>
</style>
<template>
  <!-- 新闻资讯详情页 -->
  <div class="content">
    <div class="content-header">
      <Header :activeId="activeId" />
    </div>

    <div class="ndhead">
      <img src="../assets/journalism/newBg.jpg" alt="" />

      <div class="ndhead-text">
        <div>新闻动态</div>
        <div>NEWS</div>
      </div>
    </div>
    <div class="nddata">
      <div class="nddlayout">
        <div class="nddleft">
          <div class="tex">
            <span>来源：{{ NewsData[NewsId].source }}</span><span>发布时间：{{ NewsData[NewsId].timer }}</span>
          </div>
          <div class="tit">
            {{ NewsData[NewsId].describes }}
          </div>
          <div v-for="(v, i) in NewsData[NewsId].textData" :key="i">
            <div v-for="(item, inx) in v.text1" class="nddmsg" :key="inx">
              {{ item }}
            </div>
            <div class="nddmsg">
              <img :src="v.textImg" alt="" />
            </div>
          </div>
        </div>
        <div class="nddrig">
          <div class="rigtit"> <img src="../assets/journalism/moreArrow.png" alt=""> MORE NEWS</div>
          <div class="rigbgcolo">
            <div class="rigscroll">
              <div class="rigeach" v-for="(item, i) in newlist" :key="i" @click="nav(item.create_time)">
                <div class="rigeach_text">
                  <h4>{{ item.tittle }}</h4>
                  <div class="rigtex">
                    <span>{{ item.describes }}</span>
                  </div>
                  <div class="rigtime">{{ item.create_time }}</div>
                </div>
                <div class="icon">
                  <img :src="item.cover" alt="">
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="nloction">
        <Bottom />
      </div>
    </div>
    <!-- <div style="width:200px;height:200px;position:fixed;z-index: 99;font-size:100px;top:50%;">
        {{NewsId}}
      </div> -->
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Bottom from "@/components/bottomNav/Bottom.vue";
export default {
  components: {
    Header,
    Bottom,
  },
  name: "newdetail",
  data() {
    return {
      uid: this.$route.query.uid,
      activeId: 4,
      NewsId: 0,
      NewsData: [
        {
          source: "人民网",
          timer: "2021-10-31",
          describes: "李克强主持召开国务院常务会议，决定进一步推进通关便利化李克强主持召开国务院常务会议，决定进一步推进通关便利化",
          textData: [
            {
              textImg: require('../assets/journalism/newsDetil.png'),
              text1: [
                "国务院总理李克强近日主持召开国务院常务会议,部署加快建设社会信用体系构建相适\n应的市场监管新机制;决定进一步推进通关便利化,持续优化口岸营商环境;要求更大力度\n对外开放,促进进出口多元化发展。\n会议指出,信用是市场主体安身立命之本。深化“放管服”改革,简政放权要坚持不懈,\n公正监管必须落实到位,只有管得好才能放得开。加强信用监管是基础,是健全市场体系的\n关键,可以有效提升监管效能、维护公平竟争、降低市场交易成本。一要以统一社会信用代\n码为标识,依法依规建立权威、统一、可查询的市场主体信用记录,并依法互通共享,打破\n部门垄断和信息“孤岛”。对能共享的信息不得要求市场主体重复提供。推广信用承诺制。",
                "会议指出,按照党中央、国务院部署,相关部门积极推进通关便利化,取得明显成效\n截至今年5月,进口和出口整体通关时间已提前实现比2017年压缩一半的目标,通关成本显\n著降低。下一步,要按照推动更高水平对外开放的要求,一是继续简化一体化通关流程,实\n施进口概要申报、完整申报“两步申报”通关模式改革,大幅压缩通关时间。年底前做到国\n际贸易“单一窗口”对主要业务应用率达到100%。二是进一步简化进出口环节监管证件,年\n底前对除安全保密等特殊情况外的监管证件,提前一年全部实现网上申报、网上办理。三是\n各地要抓紧公布口岸经营服务企业操作时限标准,提高口岸作业效率。强化口岸收费目录清\n单管理,落实降费措施,兑现承诺,强化责任,并依法查处垄断收费行为。",]
            },
          ],
        },
        {
          source: "若羽臣",
          timer: "2021-01-21",
          describes: "若羽臣与COSTA达成战略合作 共同打造咖啡电商新格局",
          textData: [
            {
              textImg: require('../assets/journalism/one_1.png'),
              text1: [
                "2022年1月18日，若羽臣与COSTA正式签署了战略合作协议，若羽臣创始人兼董事长王玉与COSTA中国总经理欧阳庆球出席了仪式现场，双方宣布将在电商渠道拓展、数字营销及消费者运营等领域展开深度合作，共同打造咖啡电商合作新格局。",]
            },
            {
              textImg: require('../assets/journalism/one_2.png'),
              text1: [
                "根据合作协议，若羽臣将围绕店铺运营、营销推广、消费者运营等方面，为COSTA提供高质量、专业化的全链路营销服务，全面支持COSTA在电商渠道的战略规划，赋能COSTA销售业绩的增长。",
                "欧阳庆球表示，COSTA已经制定了明确的发展策略，除即饮咖啡、自助咖啡机、线下门店等领域外，未来还将重点发力快消咖啡这一赛道。目前，在即饮咖啡领域，COSTA已实现迅猛增长的好势头。他相信，在若羽臣的帮助下，COSTA将持续聚焦家用咖啡并大力拓展电商渠道，不断拉近与消费者的距离，进一步扩大市场份额。",
                "“这不但是对我们自己的信心，更是对若羽臣服务能力的信任。若羽臣的整个服务团队，不仅有制定目标的雄心，更有把目标拆解后落地完成的决心。同时，我也相信，在双方的共同创新下，COSTA将会释放出更大的市场潜力。”他说。",
                "王玉表示：“2021年，若羽臣提出了‘以客户为中心’的经营理念。在此基础上，我们需要做的就是提供优质服务，保证我们的交付质量，实现对客户的承诺。在与COSTA之后的合作中，我们会主动创新，不断更新对品牌生意的理解和思考，携手COSTA一起在快速变化的电商环境下探索出一条特色化的发展路线。同时，我相信在COSTA的全力支持下，若羽臣的优势能够得到最大发挥，并以行业领先的全链路营销服务能力，赋能COSTA业绩上的长效增长。”",
              ]
            },
            {
              textImg: "",
              text1: [
                "COSTA作为全球知名咖啡连锁品牌，以“让全世界爱上好咖啡”为使命，始终将咖啡品质放在第一位。COSTA于2006年正式进入中国市场，之后便进入快速发展阶段。2020年，COSTA推出即饮咖啡和家用咖啡系列，面世即收获了消费者的大量购买和广泛好评。截至2021年，COSTA门店已经遍及中国32个城市，门店数量超400家。无论是在品牌层面还是产品层面，COSTA都在通过不断的创新为消费者提供更多高品质的多元化咖啡体验。未来，COSTA也将持续推行丰富的消费场景、多样化的消费渠道，以更好的产品触达更多的消费者。",
                "若羽臣作为一家面向全球优质消费品牌的电子商务综合服务提供商，在十余年的发展过程中，积淀了大量的运营方法论，能够捕捉新的发展趋势和流量风口，充分结合品牌所处阶段和产品、人群特点，精准借力匹配平台资源，将营销和运营有机融合，通过数据深度洞察，为品牌方挖掘机遇，提供生意增长策略。未来，若羽臣将通过公司的全链路营销服务模式，为COSTA输出从运营到营销的全方位解决方案，创造新的增长机会点。",
                "本次战略合作协议的签署，开启了若羽臣与COSTA合作的新征程，必将为双方带来新的发展机遇，拓展更大的合作空间。若羽臣也希望与COSTA一起，携手共进，深耕电商渠道，布局快消咖啡赛道，在助力COSTA发展的同时不断提升公司的行业影响力。",
              ]
            },
          ],
        },

        {
          source: "若羽臣",
          timer: "2022-01-19",
          describes: "连续获评！若羽臣再度上榜「2021 下半年天猫五星服务商」",
          textData: [
            {
              textImg: require('../assets/journalism/two_1.png'),
              text1: [
                "日前，天猫重磅发布“2021 年下半年天猫星级服务商榜单”，面向全球优质消费品牌的电子商务综合服务提供商若羽臣，凭借过硬的综合运营服务实力再获“五星服务商”认证。",
              ]
            },
            {
              textImg: "",
              text1: [
                "本次评选基于 2021 年天猫全新升级的天猫生态服务商专业能力评估体系，首次明确划分品牌力&消费者心智运营能力、私域&全域会员运营能力、全域消费者资产运营能力、货品全生命周期运营能力等四个专业领域，通过“数据指标+工具使用+解决方案”等多维度，考核服务商为品牌实现长期价值的综合能力。",
                "由此可见，天猫对星级服务商评选已不仅在以GMV取胜，更是注重其背后的细分专业领域能力。",
                "在电商红利逐步消退的背景下，无论平台还是品牌，都逐渐转变了对运营的认知，单纯的“流量”获取不再，取而代之的是对“人”的管理，而电商运营服务也由传统的快消式巷战打法转向精细化运营管理，从用户全生命周期管理去探索生意的长效增长。",
                "在此背景下，若羽臣积极积极拥抱时代变化，不断整合全链路营销服务能力，从品牌生意增长策略、品牌整合营销、站内外推广，到公私域运营落地，帮助品牌完成用户从认知、兴趣、购买到忠诚的全生命周期价值管理。",
                "以2021年双十一为例，若羽臣为众多品牌定制了消费者精细化运营策略，帮助品牌方快速沉淀人群资产。其中，Swisse品牌人群资产总量超过8700万，核心策略人群渗透增长60%；超24万养生玩家下单，人均价值同比提升30%+；老客回访量超7万，贡献占比达40%+，品牌连续6年蝉联天猫保健行业TOP1。",
                "同时，selsun洗护海外旗舰店锁定海外洗发水类目TOP1，双十一期间店铺销量同比增长50.57%，品牌全周期AIPL增长228%，店播种草成交同比增长236.83%。",
                "作为天猫平台优秀的生态合作伙伴，同时也是业内的头部电子商务综合服务商，若羽臣在十余年的发展过程中，已经沉淀出大量的运营方法论，并始终坚持积极创新，捕捉新的发展趋势和流量风口，充分结合品牌所处阶段和产品、人群特点，精准借力匹配平台资源，将营销和运营有机融合，通过数据深度洞察，为品牌方挖掘机遇，提供生意增长策略。",
                "此外，若羽臣还深谙平台的工具矩阵，通过策略性组合运用，高效挖掘、打通各平台之间的用户数据资产，并基于消费者洞察，向前渗透到产品研发端，为品牌方输出从运营到营销的全方位解决方案，帮助品牌杀出重围，创造新的增长机会点。",
                "过去一年，若羽臣与时俱进，持续升级数据洞察、全场景营销、全链路经营等方面的能力，并成功帮助品牌店铺操盘了上亿级广告的精准营销投放，ROI同比最大增幅超100%，平均ROI已经达到6。",
                "此次的服务商星级认证，是官方平台认证体系给予优秀服务商年度综合能力的高度肯定，新的一年，若羽臣将带着这份认可，不断强化全链路、全渠道、全场景的数字化服务能力，更加关注品牌用户全生命周期的价值管理，从品牌视角出发赋能合作伙伴，为更多品牌提供全域生意解决方案，实现跨越式新增长！",
              ]
            },
          ],
        },
        {
          source: "若羽臣",
          timer: "2022-01-18",
          describes: "“来穗小金雁”，情满若羽臣",
          textData: [
            {
              textImg: require('../assets/journalism/three_1.png'),
              text1: [
                "1月15日上午，值新春开年之际，在黄埔区大沙街党工委组织号召下，若羽臣党支部践行初心使命，积极承担社会责任，参与街道“来穗小金雁”专场关爱活动，街道党工委副书记林常青，街道组织办主任赵伟斌，以及街道党员志愿者等出席本次活动。",
              ]
            },
            {
              textImg: require('../assets/journalism/three_2.png'),
              text1: [
                "随着我国社会经济发展的不断加速，外来务工人员早已成为城市经济建设的主力军。据了解，本次“来穗小金雁”专场关爱活动是大沙街道在年终岁尾，为帮扶来穗务工人员子女所号召举办的公益帮扶献爱心行动。作为广州市民营领军企业，若羽臣以党建工作为指引，积极响应号召，热心参与到活动中来，为帮助外来务工人员及其子女融入当地生活贡献企业的一份力量。",
                "大沙街道相关领导对若羽臣积极投身公益活动表示感谢，并高度评价若羽臣在公益事业中发挥的积极作用。该领导表示，“若羽臣主动承担社会责任，践行社会主义核心价值观，不忘初心，勇于担当，为街道内其他企业树立了一个好的榜样。在未来的工作当中，大沙街道也将继续为企业做好服务，提供优质的营商环境，助力企业做大做强。希望双方政企同心、勇毅前行，助推更高质量发展，以优异的成绩迎接党的二十大胜利召开。”",
              ]
            },
            {
              textImg: "",
              text1: [
                "据中新社报道，2022年初，广东省各级工会将投入3.5亿余元开展新春行动，向春节期间全省在粤过年和踏上归途的劳动者赠送购物券包、慰问年货、景点门票、错峰返乡车票补贴等新春礼包，预计惠及1000多万名一线异地务工人员。作为广州本土企业，若羽臣将回馈社会牢记担当铭刻在企业的发展基因当中，近年来，若羽臣也不断通过参与公益事业来帮助社会中的困难群体，积极回馈社会，目前，已经携手政府、机构及社会公益组织合作开展过“护苗计划”、“关爱儿童公益月”、“正果镇关爱困境儿童”、“公益助农直播”等活动。",
                "取之于社会、回馈于社会。未来，若羽臣将继续发挥头部企业的示范效能，不忘初心、践行公益，为共同富裕的和谐社会建设添砖加瓦。",
              ]
            },
          ],
        },
        {
          source: "若羽臣",
          timer: "2021-01-17",
          describes: "若羽臣入选 “个十百千万”工程 荣膺“头部直播电商机构”称号",
          textData: [
            {
              textImg: require('../assets/journalism/four_1.png'),
              text1: [
                "近日，以“树立直播电商行业发展标杆，助力打造直播电商之都”为目标的，广州市首批直播电商“个十百千万”工程评选结果正式公布。若羽臣背靠十余年沉淀的一站式综合电商服务能力，以出色的直播电商服务荣膺广州市“头部直播电商机构”称号。",
                "“个十百千万”工程评选工作是依据《广州市直播电商发展行动方案（2020-2022年）》的发展目标，由广州市商务局牵头，在深入调研的基础上，组织各市直单位、广州直播电商研究院、广州市直播电子商务行业协会，以及其他行业商协会、重点企业，经过多轮研究，公开征求社会意见后，责成广州市直播电子商务行业协会发布的行业体系认证标准。",]
            },
            {
              textImg: "",
              text1: [
                "据了解，本次评选涵盖广州市10个区，26家机构及企业申报的46个工程项目。专家评委会成员经过对申报单位的实地走访，以及对提报资料的审查等工作，经过半个月细致研判，确定2个直播电商产业聚集区、6个头部直播电商机构、6个有影响力直播电商MCN机构、16个直播电商优秀品牌和8名直播电商带货达人为首批“直播电商工程”入选单位。若羽臣作为6家入选的头部直播电商机构之一，充分体现了参评专家组对于若羽臣电商直播服务能力的认可。",
                "作为成立于广州的电商服务企业，若羽臣依托广州市政府对于电商产业的不断扶持，以及自身十余年的精细沉淀，目前已成长为电商综合运营服务的头部企业，为合作伙伴提供全链路、全场景、全渠道的一站式数字化服务。直播电商作为电商服务的重要一环，若羽臣同样表现亮眼。在刚刚过去的双十一，凭借沟通场景的精准打造、创意内容的贴切匹配，以及平台资源的高效沟通，若羽臣服务品牌店播总霸榜高达660次，实现车轮战直播总时长4471小时，店播同比增幅最高超30倍，店播最高渗透率达34.78%，服务品牌均超越所属行业店播渗透率平均水平。",
                "展望未来，若羽臣将充结合公司实际发展，充分发挥自身在技术、运营、市场等方面的优势能力，秉承“以客户为中心”的经营理念，洞察电商直播对于助力经济复苏、推动消费升级、促进消费转化的重要商业价值，以更专业、更全面、更高效的电子商务综合服务能力，为合作伙伴打造逆势发展的新亮点和新引擎，赢得伙伴信赖、回报社会认可。",
              ]
            },
          ],
        },
        {
          source: "若羽臣",
          timer: "2021-01-14",
          describes: "再获认可，若羽臣斩获智联招聘最佳雇主称号",
          textData: [
            {
              textImg: require('../assets/journalism/five_1.png'),
              text1: [
                "1月14日下午，由智联招聘、北京大学社会调查研究中心等机构联合主办的，“2021中国年度最佳雇主颁奖盛典暨中国人力资源国际管理论坛”于广州落下帷幕。头部电子商务综合服务商若羽臣，凭借良好的业界口碑及具有成长潜力的员工培养模型，荣膺2021最佳雇主称号。",]
            },
            {
              textImg: "",
              text1: [
                "据悉，“中国年度最佳雇主”是由中国领先的人力资本生态价值链平台智联招聘，联合北京大学社会调查研究中心于2005年共同发起，旨在以独立客观的立场，通过专业科学的调查评选，发现中国雇主品牌标杆企业，挖掘其在企业管理和人力资本领域的发展趋势，从而为更多企业在管理及雇主品牌建设方面提供参考的专业性奖项。若羽臣在荣膺中企联合最佳雇主称号后，接连又将智联招聘最佳雇主称号收入囊中，再次印证人力资源行业对于若羽臣人才建设、培养以及预留等工作的充分肯定。",
                "面对日新月异的市场变化以及激烈的市场竞争，若羽臣将可持续的人才培养机制，作为企业长久生存发展的根基。通过完善公司内部的人才选拔、任用、晋升机制，形成科学的人才培养管理体系，为公司快速发展提供人才保障。",
                "据了解，自上市以来，若羽臣结合公司发展规划及业务增长现状，不断从行业内吸纳各类优秀人才，通过新血液带来的新思想、新理念，助推公司业务核心竞争力的持续提升。同时，针对内部人才预留以及晋升机制，不断优化、推动公司“北极星”人才培养计划的持续实施。",
                "通过内外双效的人才搭建计划，若羽臣正处于高速发展的快车道上。也正是基于这样的人才体系加持，依托不断扩容的高质量人才服务队伍，若羽臣秉承“以客户为中心”的经营理念，不断保持服务能力的迭代升级，坚持锐意进取、务实创新的服务精神，为品牌方提供了极具竞争力的一站式电商解决方案，为其线上生意增长持续创造价值。",
                "作为面向全球优质消费品牌的电子商务综合服务商，若羽臣将不断洞察职场需求变化，通过人才队伍的持续搭建，为电商从业者提供一个优质的工作环境和工作平台，同通过行业内优质人才的不断引进，进一步加强与品牌方的合作互信，实现公司、员工与合作伙伴的“三赢”发展路径。"
              ]
            },
          ],
        },
        {
          source: "若羽臣",
          timer: "2021-01-11",
          describes: "精进的力量 若羽臣荣膺“最佳母婴TP服务商”称号",
          textData: [
            {
              textImg: require('../assets/journalism/six_1.png'),
              text1: [
                "1月11日，以“精进的力量”为主题的2021第七届未来母婴大会暨樱桃大赏年度盛典落下帷幕，头部电商综合运营服务商若羽臣荣膺最佳母婴TP服务商称号。",
                "据悉，大会由中国最具影响力的母婴产业平台、母婴行业领域垂直媒体母婴行业观察主办。自2014年成立以来，母婴行业观察已发展成国内母婴行业风向标内容产业平台、智库，专注在母婴商业领域的创业创新。而其主办的未来母婴大会，也成为国内母婴人一年一度的重磅盛会。凭借在母婴领域深耕细作的运营能力沉淀，以及合作品牌对服务能力的充分认可，若羽臣在本次大会上将2021年度最佳母婴TP服务商称号收入囊中，再次巩固了若羽臣在母婴行业品牌电商服务赛道的优势地位。",]
            },
            {
              textImg: "",
              text1: [
                "若羽臣作为面向全球优质消费品牌的电子商务综合运营服务商，致力于通过全链路的数字化服务助力品牌方拓展线上市场。自2013年战略性切入母婴领域的电商综合服务市场后，经过自身不断地沉淀和探索，构建了一套行之有效的母婴行业线上店铺运营和营销推广经验，逐步构建起自身在母婴领域的核心竞争优势。截至目前，若羽臣已为美赞臣、佳贝艾特、尤妮佳、康贝等众多母婴品牌提供了综合数字化运营服务。",
                "过去的2021年，若羽臣秉承“以客户为中心”的服务理念，持续精进自身综合服务服务能力，从客户实际需求出发，搭建更畅通的服务场景，提供更具价值的运营服务体验。步入新的一年，为客户创造价值依然是刻在若羽臣人基因中的传承。以持续突破桎梏，拓宽行业边界；以不断超越自我，引领行业发展。",]
            },
          ],
        },


      ],

      newlist: [
        {
          tittle: "News",
          cover:
            require('../assets/journalism/list_1.jpg'),
          tittle_time: "2022-01-21",
          create_time: "01-21",
          describes:
            "若羽臣与COSTA达成战略合作 共同打造咖啡电商新格局",
          id: 1,
          source: "若羽臣",
          text1:
            "国务院总理李克强近日主持召开国务院常务会议,部署加快建设社会信用体系构建相适\n应的市场监管新机制;决定进一步推进通关便利化,持续优化口岸营商环境;要求更大力度\n对外开放,促进进出口多元化发展。\n会议指出,信用是市场主体安身立命之本。深化“放管服”改革,简政放权要坚持不懈,\n公正监管必须落实到位,只有管得好才能放得开。加强信用监管是基础,是健全市场体系的\n关键,可以有效提升监管效能、维护公平竟争、降低市场交易成本。一要以统一社会信用代\n码为标识,依法依规建立权威、统一、可查询的市场主体信用记录,并依法互通共享,打破\n部门垄断和信息“孤岛”。对能共享的信息不得要求市场主体重复提供。推广信用承诺制。",
          text2:
            "会议指出,按照党中央、国务院部署,相关部门积极推进通关便利化,取得明显成效\n截至今年5月,进口和出口整体通关时间已提前实现比2017年压缩一半的目标,通关成本显\n著降低。下一步,要按照推动更高水平对外开放的要求,一是继续简化一体化通关流程,实\n施进口概要申报、完整申报“两步申报”通关模式改革,大幅压缩通关时间。年底前做到国\n际贸易“单一窗口”对主要业务应用率达到100%。二是进一步简化进出口环节监管证件,年\n底前对除安全保密等特殊情况外的监管证件,提前一年全部实现网上申报、网上办理。三是\n各地要抓紧公布口岸经营服务企业操作时限标准,提高口岸作业效率。强化口岸收费目录清\n单管理,落实降费措施,兑现承诺,强化责任,并依法查处垄断收费行为。",
          text_img: require('../assets/journalism/list_1.jpg'),
          title: "标题",
        },
        {
          tittle: "News",
          cover:
            require('../assets/journalism/list_2.jpg'),
          tittle_time: "2022-01-19",
          create_time: "01-19",
          describes:
            "连续获评！若羽臣再度上榜「2021 下半年天猫五星服务商」",
          id: 2,
          source: "若羽臣",
          text1:
            "国务院总理李克强近日主持召开国务院常务会议,部署加快建设社会信用体系构建相适\n应的市场监管新机制;决定进一步推进通关便利化,持续优化口岸营商环境;要求更大力度\n对外开放,促进进出口多元化发展。\n会议指出,信用是市场主体安身立命之本。深化“放管服”改革,简政放权要坚持不懈,\n公正监管必须落实到位,只有管得好才能放得开。加强信用监管是基础,是健全市场体系的\n关键,可以有效提升监管效能、维护公平竟争、降低市场交易成本。一要以统一社会信用代\n码为标识,依法依规建立权威、统一、可查询的市场主体信用记录,并依法互通共享,打破\n部门垄断和信息“孤岛”。对能共享的信息不得要求市场主体重复提供。推广信用承诺制。",
          text2:
            "会议指出,按照党中央、国务院部署,相关部门积极推进通关便利化,取得明显成效\n截至今年5月,进口和出口整体通关时间已提前实现比2017年压缩一半的目标,通关成本显\n著降低。下一步,要按照推动更高水平对外开放的要求,一是继续简化一体化通关流程,实\n施进口概要申报、完整申报“两步申报”通关模式改革,大幅压缩通关时间。年底前做到国\n际贸易“单一窗口”对主要业务应用率达到100%。二是进一步简化进出口环节监管证件,年\n底前对除安全保密等特殊情况外的监管证件,提前一年全部实现网上申报、网上办理。三是\n各地要抓紧公布口岸经营服务企业操作时限标准,提高口岸作业效率。强化口岸收费目录清\n单管理,落实降费措施,兑现承诺,强化责任,并依法查处垄断收费行为。",
          text_img: require('../assets/journalism/list_2.jpg'),
          title: "标题",
        },
        {
          tittle: "News",
          cover:
            require('../assets/journalism/list_3.jpg'),
          tittle_time: "2022-01-18",
          create_time: "01-18",
          describes:
            "“来穗小金雁”，情满若羽臣",
          id: 3,
          source: "若羽臣",
          text1:
            "国务院总理李克强近日主持召开国务院常务会议,部署加快建设社会信用体系构建相适\n应的市场监管新机制;决定进一步推进通关便利化,持续优化口岸营商环境;要求更大力度\n对外开放,促进进出口多元化发展。\n会议指出,信用是市场主体安身立命之本。深化“放管服”改革,简政放权要坚持不懈,\n公正监管必须落实到位,只有管得好才能放得开。加强信用监管是基础,是健全市场体系的\n关键,可以有效提升监管效能、维护公平竟争、降低市场交易成本。一要以统一社会信用代\n码为标识,依法依规建立权威、统一、可查询的市场主体信用记录,并依法互通共享,打破\n部门垄断和信息“孤岛”。对能共享的信息不得要求市场主体重复提供。推广信用承诺制。",
          text2:
            "会议指出,按照党中央、国务院部署,相关部门积极推进通关便利化,取得明显成效\n截至今年5月,进口和出口整体通关时间已提前实现比2017年压缩一半的目标,通关成本显\n著降低。下一步,要按照推动更高水平对外开放的要求,一是继续简化一体化通关流程,实\n施进口概要申报、完整申报“两步申报”通关模式改革,大幅压缩通关时间。年底前做到国\n际贸易“单一窗口”对主要业务应用率达到100%。二是进一步简化进出口环节监管证件,年\n底前对除安全保密等特殊情况外的监管证件,提前一年全部实现网上申报、网上办理。三是\n各地要抓紧公布口岸经营服务企业操作时限标准,提高口岸作业效率。强化口岸收费目录清\n单管理,落实降费措施,兑现承诺,强化责任,并依法查处垄断收费行为。",
          text_img: require('../assets/journalism/list_3.jpg'),
          title: "标题",
        },
        {
          tittle: "News",
          cover:
            require('../assets/journalism/list_4.jpg'),
          tittle_time: "2022-01-17",
          create_time: "01-17",
          describes:
            "若羽臣入选 “个十百千万”工程 荣膺“头部直播电商机构”称号",
          id: 4,
          source: "若羽臣",
          text1:
            "国务院总理李克强近日主持召开国务院常务会议,部署加快建设社会信用体系构建相适\n应的市场监管新机制;决定进一步推进通关便利化,持续优化口岸营商环境;要求更大力度\n对外开放,促进进出口多元化发展。\n会议指出,信用是市场主体安身立命之本。深化“放管服”改革,简政放权要坚持不懈,\n公正监管必须落实到位,只有管得好才能放得开。加强信用监管是基础,是健全市场体系的\n关键,可以有效提升监管效能、维护公平竟争、降低市场交易成本。一要以统一社会信用代\n码为标识,依法依规建立权威、统一、可查询的市场主体信用记录,并依法互通共享,打破\n部门垄断和信息“孤岛”。对能共享的信息不得要求市场主体重复提供。推广信用承诺制。",
          text2:
            "会议指出,按照党中央、国务院部署,相关部门积极推进通关便利化,取得明显成效\n截至今年5月,进口和出口整体通关时间已提前实现比2017年压缩一半的目标,通关成本显\n著降低。下一步,要按照推动更高水平对外开放的要求,一是继续简化一体化通关流程,实\n施进口概要申报、完整申报“两步申报”通关模式改革,大幅压缩通关时间。年底前做到国\n际贸易“单一窗口”对主要业务应用率达到100%。二是进一步简化进出口环节监管证件,年\n底前对除安全保密等特殊情况外的监管证件,提前一年全部实现网上申报、网上办理。三是\n各地要抓紧公布口岸经营服务企业操作时限标准,提高口岸作业效率。强化口岸收费目录清\n单管理,落实降费措施,兑现承诺,强化责任,并依法查处垄断收费行为。",
          text_img: require('../assets/journalism/list_4.jpg'),
          title: "标题",
        },
        {
          tittle: "News",
          cover:
            require('../assets/journalism/list_5.jpg'),
          tittle_time: "2022-01-14",
          create_time: "01-14",
          describes:
            "再获认可，若羽臣斩获智联招聘最佳雇主称号",
          id: 5,
          source: "若羽臣",
          text1:
            "国务院总理李克强近日主持召开国务院常务会议,部署加快建设社会信用体系构建相适\n应的市场监管新机制;决定进一步推进通关便利化,持续优化口岸营商环境;要求更大力度\n对外开放,促进进出口多元化发展。\n会议指出,信用是市场主体安身立命之本。深化“放管服”改革,简政放权要坚持不懈,\n公正监管必须落实到位,只有管得好才能放得开。加强信用监管是基础,是健全市场体系的\n关键,可以有效提升监管效能、维护公平竟争、降低市场交易成本。一要以统一社会信用代\n码为标识,依法依规建立权威、统一、可查询的市场主体信用记录,并依法互通共享,打破\n部门垄断和信息“孤岛”。对能共享的信息不得要求市场主体重复提供。推广信用承诺制。",
          text2:
            "会议指出,按照党中央、国务院部署,相关部门积极推进通关便利化,取得明显成效\n截至今年5月,进口和出口整体通关时间已提前实现比2017年压缩一半的目标,通关成本显\n著降低。下一步,要按照推动更高水平对外开放的要求,一是继续简化一体化通关流程,实\n施进口概要申报、完整申报“两步申报”通关模式改革,大幅压缩通关时间。年底前做到国\n际贸易“单一窗口”对主要业务应用率达到100%。二是进一步简化进出口环节监管证件,年\n底前对除安全保密等特殊情况外的监管证件,提前一年全部实现网上申报、网上办理。三是\n各地要抓紧公布口岸经营服务企业操作时限标准,提高口岸作业效率。强化口岸收费目录清\n单管理,落实降费措施,兑现承诺,强化责任,并依法查处垄断收费行为。",
          text_img: require('../assets/journalism/list_5.jpg'),
          title: "标题",
        },
        {
          tittle: "News",
          cover:
            require('../assets/journalism/list_6.jpg'),
          tittle_time: "2022-01-11",
          create_time: "01-11",
          describes:
            "精进的力量 若羽臣荣膺“最佳母婴TP服务商”称号",
          id: 6,
          source: "若羽臣",
          text1:
            "国务院总理李克强近日主持召开国务院常务会议,部署加快建设社会信用体系构建相适\n应的市场监管新机制;决定进一步推进通关便利化,持续优化口岸营商环境;要求更大力度\n对外开放,促进进出口多元化发展。\n会议指出,信用是市场主体安身立命之本。深化“放管服”改革,简政放权要坚持不懈,\n公正监管必须落实到位,只有管得好才能放得开。加强信用监管是基础,是健全市场体系的\n关键,可以有效提升监管效能、维护公平竟争、降低市场交易成本。一要以统一社会信用代\n码为标识,依法依规建立权威、统一、可查询的市场主体信用记录,并依法互通共享,打破\n部门垄断和信息“孤岛”。对能共享的信息不得要求市场主体重复提供。推广信用承诺制。",
          text2:
            "会议指出,按照党中央、国务院部署,相关部门积极推进通关便利化,取得明显成效\n截至今年5月,进口和出口整体通关时间已提前实现比2017年压缩一半的目标,通关成本显\n著降低。下一步,要按照推动更高水平对外开放的要求,一是继续简化一体化通关流程,实\n施进口概要申报、完整申报“两步申报”通关模式改革,大幅压缩通关时间。年底前做到国\n际贸易“单一窗口”对主要业务应用率达到100%。二是进一步简化进出口环节监管证件,年\n底前对除安全保密等特殊情况外的监管证件,提前一年全部实现网上申报、网上办理。三是\n各地要抓紧公布口岸经营服务企业操作时限标准,提高口岸作业效率。强化口岸收费目录清\n单管理,落实降费措施,兑现承诺,强化责任,并依法查处垄断收费行为。",
          text_img: require('../assets/journalism/list_6.jpg'),
          title: "标题",
        },
      ],

      fontSize1: 25,
      fontSize2: 18,
      pathId: 0,
    };
  },
  created() {
    console.log(this.$route.query)
    this.getdata();
    this.getlist();
  },
  methods: {

    getdata() {
      // const url = this.globalHttpUrl + "findNewsById?id=" + this.uid;
      // this.axios.get(url).then(
      //   (res) => {
      //     // console.log("res",res)
      //     if (res.data.code == 200) {
      //       // console.log("2222", res.body.data);
      //       // this.timer = res.data.data.create_time;
      //       // this.source = res.data.data.source;
      //       // this.describes = res.data.data.describes;
      //       // this.text1 = res.data.data.text1;
      //       // this.text2 = res.data.data.text2;
      //       // this.textImg = res.data.data.text_img;
      //     }
      //   },
      //   (err) => {
      //     console.log("请求失败", err);
      //   }
      // );
    },
    getlist() {
      // const url = this.globalHttpUrl + "findNews";
      // this.axios.get(url).then(
      //   (res) => {
      //     // console.log("newD",res)
      //     if (res.data.code == 200) {
      //       // console.log("1111111111", res.body.data.rows);
      //       // this.newlist = res.data.data.rows;
      //       // this.ltimer = res.body.data.create_time;
      //       // this.ldescribes = res.body.data.describes;
      //     }
      //   },
      //   (err) => {
      //     console.log("请求失败", err);
      //   }
      // );
    },
    // choos(e) {
    //   console.log(e)
    //   this.uid = e;
    //   this.getdata();
    // },
    nav(e) {
      // this.$router.push({
      //   path: "/news/newdetail",
      //   query: {
      //     id: e,
      //   },
      // });
      for (let i = 0; i < this.newlist.length; i++) {
        if (this.newlist[i].create_time == e) {
          this.NewsId = this.newlist[i].id;
        }
      }
    },
  },

  mounted() {
    this.pathId = this.$route.query.id
    for (let i = 0; i < this.newlist.length; i++) {
      if (this.newlist[i].create_time == this.pathId) {
        this.NewsId = i + 1
      }
    }

    // const _this = this;
    // if (document.body.clientWidth < 1680) {
    //   _this.fontSize1 = 20;
    //   _this.fontSize2 = 14;
    // }
    // if (document.body.clientWidth >= 1680) {
    //   _this.fontSize1 = 25;
    //   _this.fontSize2 = 18;
    // }
    // window.addEventListener("resize", () => {
    //   if (document.body.clientWidth < 1680) {
    //     _this.fontSize1 = 20;
    //     _this.fontSize2 = 14;
    //   }
    //   if (document.body.clientWidth >= 1680) {
    //     _this.fontSize1 = 25;
    //     _this.fontSize2 = 18;
    //   }
    // });
  },

};
</script>

<style scoped lang="less">
.content {
  width: 100%;
  margin: 0 auto;

  .content-header {
    // width: 100%;
    max-width: 1920px;
    min-width: 1200px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .ndhead {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    .ndhead-text {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      // right: 0;
      margin: auto;
      text-align: center;

      // letter-spacing: .0105rem;
      div:first-child {
        color: rgb(255, 255, 255);
        //  font-size:  .2105rem;
        font-size: .3158rem;
        text-align: center;
        //  font-weight: 500;
        margin-bottom: .052632rem;
      }

      div:last-child {
        color: rgb(255, 255, 255);
        // font-size: .1368rem;
        font-size: .0842rem;
        text-align: center;
      }
    }
  }

  .nddata {
    width: 100%;
    position: relative;

    .nddlayout {
      // width: 1200px;
      width: 70%;
      margin: 0 auto;
      padding: 0 0 5% 0;
      display: flex;
      justify-content: space-between;

      .nddleft {
        // width: 854px;
        width: 55%;
        // height: 900px;
        margin-top: 8%;

        // background-color: #ff0;
        .tit {
          font-size: .2105rem;
          // font-weight: 800;
          // color: rgb(59, 59, 59);
          color: #444444;
          padding-bottom: 5%;
        }

        .tex {
          font-size: .0842rem;
          padding-bottom: 1%;
          display: flex;
          color: #cdcdcd;
          justify-content: flex-start;

          span {
            margin-right: 4%;
          }
        }

        .nddmsg {
          letter-spacing: -0.0047rem;
          font-size: .1053rem;
          // color: rgb(148, 148, 148);
          color: #444444;
          text-indent: 6%;
          line-height: 180%;

          img {
            width: 100%;
            display: block;
            margin: 0 auto;
            padding-top: 8%;
            padding-bottom: 8%;
          }
        }

        .sbicon {
          width: .147368rem;
          height: .336842rem;
          margin: 0 auto;
          margin-top: 10%;

        }
      }

      .nddrig {
        // width: 314px;
        width: 40%;
        // height: 900px;
        margin-top: 5%;

        .rigtit {
          font-size: .1053rem;
          text-align: right;
          margin-top: 12%;
          height: 10%;
          margin-left: 10%;
          // color: #d5d5d5;
          color: #444444;

          img {
            margin: -1% 2% 0 0;
          }
        }

        .rigbgcolo {
          display: flex;
          flex-direction: column;
          align-items: center;
          // height: 900px;
          height: 50%;
          width: 100%;

          // padding: 25px;
          .rigscroll {
            // width: 362px;w
            width: 100%;
            height: 100%;
            // height: 900px;
          }

          .sbicon {
            width: .147368rem;
            height: .336842rem;
            margin-top: 50px;

          }

          .rigscroll::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            /**/
          }

          .rigeach {
            display: flex;
            justify-content: space-between;
            padding: 0 0 6% 0;
            margin: 0 0 12% 0;
            border-bottom: thin solid #efefef;
            cursor: pointer;

            .rigtime {
              font-size: .0842rem;
              color: #b8b8b8;
              // color:  #444444;
              // padding-bottom: .052632rem;
            }

            .rigeach_text {
              width: 55%;

              h4 {
                font-size: .0842rem;
                color: #d5d5d5;
              }

              .rigtex {
                text-align: justify;
                color: #444444;
                font-size: .1316rem;
                // font-weight: 600;
                line-height: .157895rem;
                position: relative;
                padding: 6% 0 12%;

                span {
                  padding-right: .131579rem;
                }
              }
            }

            .icon {
              line-height: .7211rem;

              img {
                width: .7211rem;
                height: .5316rem;
              }
            }

            .bicon {
              font-size: .1368rem;
              color: #ccc;
              margin-top: -0.052632rem;
            }

            &:hover {
              color: #409eff;

              .rigtime {
                color: #409eff;
              }

              .rigtex {
                color: #409eff;

                .icon {
                  background-image: url("../assets/newdetail/qianwang-xiayibu.png");
                }

                .bicon {
                  color: #409eff;
                }
              }
            }
          }

          // .rigeachtwo {
          //   background-color: #fafafa;
          //   padding: 30px 50px 0px 50px;
          //   .rigtime {
          //     font-size: 16px;
          //     color: rgb(59, 59, 59);
          //     padding-bottom: 10px;

          //     &:hover {
          //       color: #409eff;
          //     }
          //   }
          //   .rigtex {
          //     position: relative;
          //     color: rgb(59, 59, 59);
          //     font-size: 18px;
          //     line-height: 30px;
          // font-weight: 600;
          //     .icon {
          //       display: block;
          //       width: 20px;
          //       height: 20px;
          //       background-color: #fff;
          //       position: absolute;
          //       bottom: 5px;
          //       right: 15px;
          //     }
          //     &:hover {
          //       color: #409eff;
          //     }
          //   }
          //   .bicon {
          //     font-size: 25px;
          //     color: #ccc;
          //     margin-top: -10px;

          //     &:hover {
          //       color: #409eff;
          //     }
          //   }
          // }
        }
      }
    }

    .nloction {
      // top: 2764px;
      bottom: 0;
      left: 0;
    }
  }
}</style>


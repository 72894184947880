<template>
  <div class="service-case">

    <div class="banner">
      <img src="../assets/fuwuanli/serviceDemo.png" alt="" />
      <div class="bannerzhutu">
        <div>{{ $t("Service.showCase") }}</div>
        <div>{{ $t("Service.showCasesmall") }}</div>
      </div>
    </div>

    <div class="service-case-center">
      <!-- <img src="../assets/fuwuanli/beijin.jpg" alt="" /> -->

      <div class="center-box">
        <div class="navCon">
          <!-- 左箭头 -->
          <div class="left" @click="subIndex()">
            <div></div>
          </div>

          <div class="navConcenter">
            <div class="navConcenter-son" :style="{ transform: `translateX(-${this.titrans * 14.2857}%)`, }">
              <!-- <span  v-for="(item, index) in data" :key="index">{{item.title}}</span> -->
              <ul v-if="$store.state.isZh">
                <li v-for="(item, num) in datas" :key="num" :class="{ active: num == index }" @click="getIndex(num)">
                  {{ item.title }}
                </li>
              </ul>
              <ul v-else>
                <li v-for="(item, num) in datas2" style="padding: 0 .117895rem;" :key="num"
                  :class="{ active: num == index }" @click="getIndex(num)">
                  {{ item.title }}
                </li>
              </ul>
            </div>
          </div>

          <!-- 右箭头 -->
          <div class="right" @click="addIndex()">
            <div></div>
          </div>
        </div>

        <!-- <div class="henxian">
          <img
            style="width: 100%"
            src="../assets/fuwuanli/touying.png"~
            alt=""
          />
        </div> -->

        <div class="neirongCon">
          <div class="neirong" :style="{ width: isMobile || !$store.state.isZh ? '80%' : '73%' }">
            <div v-if="$store.state.isZh" class="introduceBox"
              :style="{ transform: `translateX(-${this.trans * 25}%)`, }">
              <div class="introduce">
                <div class="logoImg" ref="imgSize">
                  <!-- <img :src="datas[index].logo" :style="{ width: logoArr[index].width }" alt="" /> -->
                  <img :src="datas[index].logo" alt="" />
                </div>
                <div class="title">
                </div>
                <div class="text">
                  {{ this.datas[index].brief_introduction }}
                </div>
              </div>
              <div v-for="(activity, i) in datas[index].detail_list" :key="i"
                :class="activity.width == 1 ? i == introduceIndex ? 'introduceTwo activeTwo' : 'introduceTwo' : i == introduceIndex ? 'introduce active' : 'introduce'"
                @click="change(i, '1')">
                <div></div>
                <div class="BgLine" :style="{ top: isMobile ? '22px' : '28px' }"> </div>
                <div class="Dot">
                  {{ "·" }}
                </div>
                <div class="title">
                  {{ activity.title }}
                </div>
                <div class="text">
                  {{ activity.content }}
                </div>
              </div>
            </div>
            <!-- 英文版 -->
            <div v-else class="introduceBox" :style="{ transform: `translateX(-${this.trans * 25}%)`, }">
              <div class="introduce" :style="{width: index == 7? '33%' : index == 1? '22%' :'25%',}">
                <div class="logoImg" ref="imgSize">
                  <!-- <img :src="datas[index].logo" :style="{ width: logoArr[index].width }" alt="" /> -->
                  <img :src="datas[index].logo" alt="" />
                </div>
                <div class="title" :style="{ margin: theLan ? '25% 0 2% 0' : '73px 0 41px 0' }">
                </div>
                <!-- <div class="text" style="font-size: 14px;padding: 5% 2%; text-align: center;text-align-last: center">
                  {{ this.datas2[index].brief_introduction }}
                </div> -->
                <img :src="this.datas2[index].brief_introduction" alt="" :style="{ width: isMobile? this.datas2[index].mowidth/146 + 'rem' : this.datas2[index].width/146 + 'rem' }">
              </div>
              <div v-for="(activity, i) in datas2[index].detail_list" :key="i"
              :style="{width: activity.size,}"
                :class="activity.width == 1 ? i == introduceIndex ? 'introduceTwo activeTwo' : 'introduceTwo' : i == introduceIndex ? 'introduce active' : 'introduce'"
                @click="change2(i, '2')">
                <div></div>
                <div class="BgLine" :style="{ top: isMobile ? '22px' : '28px' }"> </div>
                <div class="Dot">
                  {{ "·" }}
                </div>
                <div class="title"
                  :style="{ whiteSpace: 'pre-wrap', fontSize: theLan ? '0.1053rem' : '16px', lineHeight: theLan ? '0.1316rem' : '18px', margin: theLan ? '25% 0 2% 0' : '0', padding: theLan ? '0' : activity.width == 1 ? '73px 0 65px' : isMobile? '73px 0 65px' : '73px 0 65px', }">
                  {{ activity.title }}
                </div>
                <!-- <div class="text"
                  :style="{ textAlign: 'center',textAlignLast: 'center',padding:'5% 2%', fontSize: theLan ? '0.1053rem' : '14px', }">
                  {{ activity.content }}
                </div> -->
                <img :src="activity.url" alt="" :style="{ width: isMobile? activity.mourlwidth/146 + 'rem' : activity.urlwidth/146 + 'rem' }">
              </div>
            </div>
          </div>
        </div>

        <div class="changtu" :style="{ width: isMobile ? '80%' : '70%' }">
          <img :src="datas2[index].img" alt="">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { findSevice, findSeviceCases } from "@/api/index.js";
// import Header from "@/components/header/Header.vue";
// import Bottom from "@/components/BottomNav/Bottom.vue";
// import Bottom from "@/components/bottomNav/Bottom.vue";
export default {
  components: {
    // Header,
    // Bottom,
  },
  data() {
    return {
      isMobile: false,                //是否移动端
      activeId: 2,
      aa: 100,
      // activeId:4,
      style: "",
      trans: 0,                 //子标题移动的距离
      titrans: 0,               //导航栏移动的距离
      reverse: false,
      index: 0,                 //访问的品牌
      introduceIndex: 0,        //访问的子标题
      queryParam: {              //查询参数
        page_no: 1,
        page_size: 50,
      },
      indexId: 0,               //访问的标题的Id
      datas: [
        {
          brief_introduction: '',     //简介
          logo: '',              //logo图
          id: 0,
          img: '',               //大图
          title: '',                 //标题
          create_time: '',
          detail_list: [
            {
              content: '',         //子级标题正文
              title: '',         //子级标题名
              width: ''          //子级标题尺寸
            }
          ],
        }
      ],
      // 英文版
      datas2: [
        {
          // brief_introduction: 'Mama & Kids is a Japanese skin care brandwhose low-irritation and high-function skincare products are developed by Japan Natural Science Co.. Ltd.together with dermatolaists.obstetrician, and pediatric dermatologists, It isspecially desianed for sensitive and delicateskin of pregnant women, newborns, and infants',
          brief_introduction: require('../assets/fuwuanli/text/mk.png'),
          width: 268,
          mowidth: 223,
          logo: 'https://www.gzruoyuchen.com/ftp/pic20230704/e1805d291ad64f509dd2db196b2e9ef5.png',
          id: 0,
          img: '//img13.360buyimg.com/zx/jfs/t1/190436/37/35382/76791/64cc7a77Fc73c8289/5f0593897dab135d.png',
          title: 'MamaAndKids',
          create_time: '',
          detail_list: [
            {
              url: require('../assets/fuwuanli/text/mk1.png'),
              urlwidth: 264,
              mourlwidth: 220,
              content: 'The size of the stretch mark cream isupgraded from 150g to 470g as part ofthe upselling/ larger-size strategy. Later.the 470g stretch mark cream topped inthe stretch mark care chart',
              title: 'Product upgrade for \n higher ARPU',
              size: '25%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/mk2.png'),
              urlwidth: 225,
              mourlwidth: 186,
              content: 'Sales of the baby & kid category increased byover 16 million year-on-year by increasing thepenetration rate among the children in theiryouth and launching a product series targetedat this age group.',
              title: 'Refined CRM for \n Target Users',
              size: '22%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/mk3.png'),
              urlwidth: 276,
              mourlwidth: 230,
              content: 'Membership benefits such as first-purchase giftslottery draws and referral awards are al availablein the integrated marketing scheme to increasethe member enrollment rate. Additionally, weimplemented tiered product/benefit promotionprograms tailored to members at different stagesAs a result,the revenue share from membersjumped to 53%',
              title: 'Opportunities Derived from \n Market Insight',
              size: '26%',
              width: 2
            }
          ],
        },
        {
          // brief_introduction: "Founded in 1940,ONE A DAY is amultivitamin brand under the BayerGroup in Germanylt specializes inwomen and men's vitamins.Thebrand has now built a wide-rangingproduct family that can meet vita-min supplementation needs ofpeople in modern life.",
          brief_introduction: require('../assets/fuwuanli/text/obo.png'),
          width: 205,
          mowidth: 172,
          logo: "https://www.gzruoyuchen.com/ftp/pic20230705/464af5115e9f4237b3bfcc3e7be62162.png",
          id: 0,
          img: '//img13.360buyimg.com/zx/jfs/t1/169632/9/39027/60857/64cc7a78F0f28795e/963a3c10b51c5d10.png',
          title: 'ONE A DAY',
          create_time: '',
          detail_list: [
            {
              url: require('../assets/fuwuanli/text/obo1.png'),
              urlwidth: 258,
              mourlwidth: 215,
              content: 'In the first stage, the goal is to acquire heavyusers in the multivitamin market and enhancebrand awareness.As we progress to thesecond stage the focus shifts to expansion ofthe qummy vitamin segment by leveragingthe krand visibility and engagement with thenext group of customers.',
              title: 'Evolving Products and Target \n Audience in Different Stages',
              size: '25%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/obo2.png'),
              urlwidth: 218,
              mourlwidth: 182,
              content: 'Following the initial explosive growthof customers through live streamingwe developed a sound client base andmaintained momentum for sustain-able growth by capitalizing on resources of the platform.',
              title: 'Traffic Capturing via \n Live Streaming',
              size: '23%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/obo3.png'),
              urlwidth: 310,
              mourlwidth: 258,
              content: 'We proactively targeted at users on Douvin ano Kuaishou to capture external traffic, while continuously optimizina ads and user labels to expand internal traffic Ultimately a virtuous cycles was formed with a stable external/internal traffic ratio.',
              title: 'Traffic on Douyin/Kuaishou \n Turned into Actual Growth',
              size: '28%',
              width: 2
            }
          ],
        },
        {
          // brief_introduction: 'Centrum is a well-known brand for vitamins and minerals introduced by Pfizer, a world-leading R&D-driven biomedical and pharmaceutical company. With a workforce of around 80,000 employees across 90 countries, Pfizer is dedicated to making a significant difference in global health.',
          brief_introduction: require('../assets/fuwuanli/text/Centrum.png'),
          width: 245,
          mowidth: 204,
          logo: "https://www.gzruoyuchen.com/ftp/pic20230705/5cda147b4a9548599fb7cbe9cb6f0210.png",
          id: 0,
          img: '//img11.360buyimg.com/zx/jfs/t1/204405/15/38650/162916/64cc7a77F1af3b9f3/2da9fed4aec2ee50.png',
          title: 'Centrum',
          create_time: '',
          detail_list: [
            {
              url: require('../assets/fuwuanli/text/Centrum1.png'),
              urlwidth: 390,
              mourlwidth: 325,
              content: 'Since the RYC team took charge of the brand’s operation, we have been embracing changes amidst fierce competition by redefining the existing product structure. By integrating and optimizing consumer touchpoints and integrated marketing schemes, we have consolidated our established categories and swiftly developed new ones. Notably, the new probiotic product achieved top 10 status in the industry within just 3 months since its launch, showcasing how effective our multi-faceted approach has been in propelling explosive growth across various channels.',
              title: 'Growth Led by Wider Categories \n and Better Refined User Groups',
              size: '40%',
              width: 1
            },
            {
              url: require('../assets/fuwuanli/text/Centrum2.png'),
              urlwidth: 241,
              mourlwidth: 200,
              content: 'Centrum ranked Top 4 for new member enrollment efficiency during the Double Eleven in 2022, with over 15% year-on-year growth in new customer acquisition.',
              title: 'Better Exposure with Brand New Customer \nAcquisition, Venues and Channels',
              size: '35%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/Centrum3.png'),
              urlwidth: 208,
              mourlwidth: 173,
              content: 'In 2022, both the GMV of Centrum’s repeat customers and the number of them witnessed a growth exceeding 30%.',
              title: 'Increased Repurchases with Stronger \n Repeat Customer Base',
              size: '25%',
              width: 2
            }
          ],
        },
        {
          // brief_introduction: 'With over 150 years of history, Bayer is an innovation-driven and leading life science company with core competitiveness in health and nutrition. It is committed to addressing major challenges confronting human society with its innovative products. ',
          brief_introduction: require('../assets/fuwuanli/text/baier.png'),
          width: 234,
          mowidth: 195,
          logo: 'https://www.gzruoyuchen.com/ftp/pic20230712/5b8f7670bd904e97be4d6bd3c26f5d84.png',
          id: 0,
          img: '//img12.360buyimg.com/zx/jfs/t1/189948/20/35515/67779/64cc7a77F3d45b661/2b96568bbb407a70.png',
          title: 'Bayer',
          create_time: '',
          detail_list: [
            {
              url: require('../assets/fuwuanli/text/baier1.png'),
              urlwidth: 288,
              mourlwidth: 240,
              content: 'To optimize promotional activities across the board, we improved the investment ratio and efficiency of Elevit/OneADay flagship stores by aligning them with market conditions and our plan for operational strategies.',
              title: 'Improvement of Flagship \nOperation Strategy for Better \nEfficiency and Productivity',
              size: '25%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/baier2.png'),
              urlwidth: 263,
              mourlwidth: 219,
              content: 'We continuously improve user experience by reducing logistics costs and mitigating risks stemming from the external environment based on separate warehouse management. Additionally, we have introduced rigorous CRM standards to enhance reception efficiency.',
              title: 'Firm Grasp of Supply Chain \nand CRM Strategy',
              size: '28%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/baier3.png'),
              urlwidth: 252,
              mourlwidth: 210,
              content: 'The product – Algae oil DHA topped the chart of maternal nutrition category. Gummy candies maintained its top position in the category of healthcare functional gummy products oversea. Sleep aids was among the top 2 in the melatonin category.',
              title: 'Exploration of Opportunities Making Us the Champion of the Category',
              size: '22%',
              width: 2
            }
          ],
        },
        {
          // brief_introduction: 'Founded in 1999 in Inner Mongolia Autonomous Region and headquartered in Hohhot, Mengniu is one of the world’s top 8 dairy companies, a prominent player in national agricultural industrialization and a market leader in China’s dairy industry, dedicating itself to the development of dairy products that meet the health needs of the Chinese consumers. ',
          brief_introduction: require('../assets/fuwuanli/text/mn.png'),
          width: 246,
          mowidth: 205,
          logo: 'https://www.gzruoyuchen.com/ftp/pic20230705/db30afec05024043a464e9265be75370.png',
          id: 0,
          img: 'https://img.alicdn.com/imgextra/i1/4045700848/O1CN01buxElY1I8QjKFYmsb_!!4045700848.png',
          title: 'Mengniu',
          create_time: '',
          detail_list: [
            {
              url: require('../assets/fuwuanli/text/mn1.png'),
              urlwidth: 286,
              mourlwidth: 238,
              content: 'With a deep understanding of the traffic system and differentiated strategy to capture traffic across the platforms, we manage to gain the support from the platform to drive traffic and achieve rapid sales growth. Furthermore, the store/KOL livestreaming matrix has been created to ensure organic traffic acquisition.',
              title: 'Massive Traffic Explosion on \n Various Platforms',
              size: '25%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/mn2.png'),
              urlwidth: 278,
              mourlwidth: 232,
              content: 'We have increased the internal penetration of both the new and established products, making pathways seamlessly connected within the stores.With faster product upgrades, we have enhanced the stickiness of both the new and repeatcustomers. ',
              title: 'Improvement of Product \n Portfolio',
              size: '28%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/mn3.png'),
              urlwidth: 252,
              mourlwidth: 210,
              content: 'We incentivize bulk purchases by offering discounts on multiple items. Many entry-level products have been upgraded to premium versions. Moreover, the CRM strategies have led to an improved conversion rate among repeat customers in a targeted manner.',
              title: 'Increase in AOV with \nDiversified Offers',
              size: '22%',
              width: 2
            }
          ],
        },
        {
          // brief_introduction: 'Free is a personal health care brand founded by Kingdom Healthcare with products tailer-made for teenagers aged between 12 and 23.',
          brief_introduction: require('../assets/fuwuanli/text/free.png'),
          width: 192,
          mowidth: 160,
          logo: 'https://www.gzruoyuchen.com/ftp/pic20230705/1ef82d2f03df476098733d801e850480.png',
          id: 0,
          img: '//img30.360buyimg.com/zx/jfs/t1/163255/26/38976/68950/64cc7a76Ff68cbb12/6c2d10932b19eb13.png',
          title: 'Free',
          create_time: '',
          detail_list: [
            {
              url: require('../assets/fuwuanli/text/free1.png'),
              urlwidth: 396,
              mourlwidth: 330,
              content: 'With the closed-loop marketing strategy proposed by Dako Markting Solutions of RYC, Free started interdisciplinary cooperation with IPs of Bilibili and launched a wide variety of marketing campaigns, including customized collaboration gift sets, content co-creation with uploaders (vloggers on Bilibili), Bilibili special pages online, offline events on campus, stop-motion animation, focus banner and GD advertisements. ',
              title: 'Creation of Best-selling Products \n through Integrated Marketing',
              size: '40%',
              width: 1
            },
            {
              url: require('../assets/fuwuanli/text/free2.png'),
              urlwidth: 364,
              mourlwidth: 303,
              content: 'To fully cover young consumers and win their mindset, we launched interdisciplinary marketing campaigns that took social media by storm. Also,we generated buzz and orchestrated hugely successful live streaming sessions which not only attracted target audience, but also channeled traffic to e-commerce platforms.',
              title: 'Coverage of Young-age Group \n to Acquire Customers',
              size: '38%',
              width: 2
            },
          ],
        },
        {
          // brief_introduction: 'Founded in 1998, Herb Kenko Honpo is a Japanese company that leads the plant enzymes market. The company is dedicated to R&D, production, and sales of healthcare products, among which the notable offerings are DOKKAN plant enzymes, MORI MORI SLIM series, and SHIBOHE-RU functional food.',
          brief_introduction: require('../assets/fuwuanli/text/hkh.png'),
          width: 227,
          mowidth: 189,
          logo: 'https://www.gzruoyuchen.com/ftp/pic20230705/7f9dad874d7d4ba395a1a564e4423410.png',
          id: 0,
          img: '//img12.360buyimg.com/zx/jfs/t1/145277/30/38316/54979/64cc7a76Fefe5f11e/f881b4dc8373c617.png',
          title: 'Herb Kenko Honpo',
          create_time: '',
          detail_list: [
            {
              url: require('../assets/fuwuanli/text/hbk1.png'),
              urlwidth: 208,
              mourlwidth: 173,
              content: 'Through concerted efforts, we efficiently solved the issue of stocking across multiple warehouses, thereby ensuring sufficient supply for demand surge in sales.With our scientific and accurate predictions about the campaign, we are able to maintain a balanced stock/inventory/sales ratio during the peak sales periods.',
              title: 'Utilization of Platform \n Resources for Free Traffic',
              size: '20%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/hbk2.png'),
              urlwidth: 229,
              mourlwidth: 190,
              content: 'By finetuning product descriptions page, descriptive pictures among all the ads materials, we have increased the time users spent on those pages.We effectively reduced the bounce rate with pathway optimization.',
              title: 'Improved Conversion Rate\n with Ads Optimization',
              size: '25%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/hbk3.png'),
              urlwidth: 310,
              mourlwidth: 258,
              content: 'Through concerted efforts, we efficiently solved the issue of stocking across multiple warehouses, thereby ensuring sufficient supply for demand surge in sales.With our scientific and accurate predictions about the campaign, we are able to maintain a balanced stock/inventory/sales ratio during the peak sales periods.',
              title: 'Scientific Management of\n Stock, Sales & Inventory',
              size: '30%',
              width: 2
            }
          ],
        },
        {
          // brief_introduction: "Founded in 1980, FANCL is Japan's largest additive-free skincare and healthy food brand, maintaining a leading position over the years. Leveraging its cutting-edge R&D and production technologies, FANCL pioneeredthe creation of preservative-free and chemical additive-free beauty products and healthy food, which help avoid skin issues associated with the use of preservative-containing skincare products. ",
          brief_introduction: require('../assets/fuwuanli/text/fancl0.png'),
          width: 309,
          mowidth: 257,
          logo: 'https://www.gzruoyuchen.com/ftp/pic20230705/fa522459ca6b43d192ad19e0ab6c4a38.png',
          id: 0,
          img: '//img20.360buyimg.com/zx/jfs/t1/195844/25/34962/58943/64cc7a76F6453a7a2/566a25083f330ffd.png',
          title: 'FANCL',
          create_time: '',
          detail_list: [
            {
              url: require('../assets/fuwuanli/text/fancl1.png'),
              urlwidth: 274,
              mourlwidth: 228,
              content: 'We examined the market opportunities from three perspectives: market share, sector growth rate, and most searched category words, which helped us accurately measure the penetration potential for different user groups.',
              title: 'Clear Understanding of Strategic User Penetration',
              size: '25%',
              width: 2
            },
            {
              url: require('../assets/fuwuanli/text/fancl2.png'),
              urlwidth: 385,
              mourlwidth: 321,
              content: 'A closed loop consisting of testing, verification, and warm-up for the campaign has been formed. This allows us to tailor-make marketing materials to specific user groups in promising categories and speed up the implementation of strategies. Moreover, we have improved the internal penetration of new and well-established products based on seamless pathways inside the stores.Accelerate products upgrading for stronger stickiness of both the new and repeat customers.',
              title: 'Penetration Test in Refined\n User Groups',
              size: '40%',
              width: 1
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
  computed: {
    theLan() {
      // 计算数值的总和
      return this.$store.state.isZh
    },
  },
  methods: {
    // left() {
    //   this.style = "";
    // },
    // right() {
    //   this.style = "transform: translateX(-140px);";
    // },
    //获取数据
    getList() {
      findSevice(this.queryParam).then((res) => {
        // this.datas = res.data.rows.reverse()
        this.datas = res.data.rows.sort(function (a, b) {
          return a.id - b.id
        })
        this.indexId = this.datas[this.index].id
        findSeviceCases(this.indexId).then((res) => {
          this.datas[this.index].detail_list = res.data.detail_list
        })

      })
    },

    //获取子级标题具体数据
    getLabel() {
      this.indexId = this.datas[this.index].id
      findSeviceCases(this.indexId).then((res) => {
        this.datas[this.index].detail_list = res.data.detail_list
        console.log(this.datas);
      })
    },

    // 获取图片真实宽高
    getImgWidth(url) {
      let img = new Image()
      img.src = url
      img.onload = function () {
        // res = {
        //   width: img.width,
        // }
        // console.log('width', img.width);
      }
      return img.width;
    },

    //选择子级标题
    change(i, len) {
      console.log('change', len);
      let Maxtrans = -3;           //最大偏移值
      this.datas[this.index].detail_list.forEach(item => {
        if (item.width == 1) {
          Maxtrans = Maxtrans + 2
        } else {
          Maxtrans = Maxtrans + 1
        }
      });
      if (Maxtrans < 0) {
        Maxtrans = 0
      }
      console.log('Maxtrans', Maxtrans);


      this.introduceIndex = i;
      if (i == 0) {
        this.trans = 0
      } else {
        if (this.datas[this.index].detail_list[i].width == 1 && this.datas[this.index].detail_list[i - 1].width == 1) {
          this.trans = i + 1
        } else {
          // if (this.datas[this.index].detail_list[i].width == 2 && this.datas[this.index].detail_list[i - 1].width == 2) {
          //   this.trans = i - 1
          // } else {
          //   this.trans = i
          // }
          this.trans = i
        }
      }
      if (this.trans > Maxtrans) {
        this.trans = Maxtrans
      }
    },

    //选择子级标题英文版
    change2(i, len) {
      console.log('change', len);
      let Maxtrans = -3;           //最大偏移值
      this.datas2[this.index].detail_list.forEach(item => {
        if (item.width == 1) {
          Maxtrans = Maxtrans + 2
        } else {
          Maxtrans = Maxtrans + 1
        }
      });
      if (Maxtrans < 0) {
        Maxtrans = 0
      }
      console.log('Maxtrans', Maxtrans);


      this.introduceIndex = i;
      if (i == 0) {
        this.trans = 0
      } else {
        if (this.datas2[this.index].detail_list[i].width == 1 && this.datas2[this.index].detail_list[i - 1].width == 1) {
          this.trans = i + 1
        } else {
          // if (this.datas[this.index].detail_list[i].width == 2 && this.datas[this.index].detail_list[i - 1].width == 2) {
          //   this.trans = i - 1
          // } else {
          //   this.trans = i
          // }
          this.trans = i
        }
      }
      if (this.trans > Maxtrans) {
        this.trans = Maxtrans
      }
    },
    //获取点击标题的序号
    getIndex(n) {
      // console.log(n);
      this.introduceIndex = 0;
      this.trans = 0;
      this.index = n;
      this.getLabel();
    },
    // 增加索引值
    addIndex() {
      this.introduceIndex = 0;
      this.trans = 0;
      if (this.index < this.datas.length - 1) {
        this.index++;
      }
      if (this.index <= 6) {
        this.titrans = 0;
      } else if (this.index < this.datas.length) {
        this.titrans = this.index - 6
      }
      // console.log(this.titrans);
      this.getLabel();
    },
    // 减少索引值
    subIndex() {
      this.introduceIndex = 0;
      this.trans = 0;
      if (this.index > 0) {
        this.index--;
      }
      if (this.index <= 6) {
        this.titrans = 0;
      } else if (this.index < this.datas.length) {
        this.titrans = this.index - 6
      }
      this.getLabel();
    },
  },
};
</script>

<style lang="less">
.service-case {
  .BgLine {
    height: .0053rem;
    border-top: .0053rem solid #b8b8b8;
    position: absolute;
    top: 14%;
    width: 100%;
  }

  width: 100%;
  position: relative;

  .service-case-top {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
  }

  .banner {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    .bannerzhutu {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      // right: 0;
      margin: auto;
      text-align: center;

      // letter-spacing: .0105rem;
      div:first-child {
        color: rgb(255, 255, 255);
        //  font-size:  .2105rem;
        font-size: .3158rem;
        text-align: center;
        //  font-weight: 500;
        margin-bottom: .052632rem;
      }

      div:last-child {
        color: rgb(255, 255, 255);
        // font-size: .1368rem;
        font-size: .0842rem;
        text-align: center;
      }
    }
  }

  .service-case-center {
    width: 100%;

    // position: relative;
    &>img {
      width: 100%;
    }

    .BgLine {
      height: .0053rem;
      border-top: .0053rem solid #b8b8b8;
      position: absolute;
      top: 14%;
      width: 100%;
    }

    .center-box {
      width: 100%;
      // height: 100%;
      // position: relative;
      // left: 0;
      // top: 0;
      background-color: #fff;

      .navCon {
        // width: 6.315789rem;
        // width: 63%;
        padding: 0 .078947rem 0;
        margin: 2% auto;
        display: flex;
        justify-content: space-between;
        border-bottom: thin solid #dedede;
        padding: 0 0 2% 0;

        .left {
          // background-color: red;
          // flex: 1;
          width: .157895rem;
          position: absolute;
          left: 18%;

          div {
            width: .105263rem;
            height: .105263rem;
            border: .015789rem solid #ddd;
            // border-right-color: transparent;
            border-color: #ddd transparent transparent #ddd;
            transform: rotate(-45deg);
            margin-top: .042105rem;
          }
        }

        .right {
          position: absolute;
          right: 18%;
          // background-color: red;
          // flex: 1;
          width: .157895rem;
          cursor: pointer;

          div {
            width: .105263rem;
            height: .105263rem;
            border: 3px solid #ddd;
            // border-right-color: transparent;
            border-color: #ddd #ddd transparent transparent;
            transform: rotate(45deg);
            margin-top: .042105rem;
          }
        }

        .navConcenter {
          width: 6.09rem;
          overflow: hidden;
          margin: 0 auto;

          .navConcenter-son {

            // transition: all 0.3s linear;
            // width: 1200px;
            // overflow-x: scroll;
            // overflow-y: hidden;
            ul {
              // width: 1200px;
              width: 100%;
              display: flex;
              font-size: .1368rem;
              // font-size: 16px;
              // background: skyblue;
              // color: rgb(148, 148, 148);
              color: #444444;
              flex-wrap: row;
              text-align: center;
              justify-content: space-between;

              li {
                // width: 14.2857%;
                flex-shrink: 0;
                white-space: nowrap;
                padding: 0 .217895rem;
                cursor: pointer;
                text-align: center;
                position: relative;
              }

              // li:nth-child(1) {
              //   width: 20%;
              // }

              // li:nth-child(2),
              // li:nth-child(3),
              // :nth-child(4) {
              //   width: 12.345%;
              // }

              .active {
                color: #009bde;
              }
            }
          }
        }
      }

      .neirongCon {
        width: 100%;
        margin: 0.1rem 0 0.4rem;
        background-color: #fff;

        .neirong {
          width: 73%;
          height: 100%;
          margin: 0 auto;
          overflow: hidden;

          .introduceBox {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            overflow-x: visible;
            transition: 0.8s;
          }

          .introduceTwo {
            text-align: center;
            flex-shrink: 0;
            width: 50%;
            cursor: pointer;
            position: relative;

            .logoImg {
              background-color: #fff;
              width: 100%;
              height: 70px;
              // line-height: .473684rem;
              display: flex;
              align-items: center;
              position: absolute;
              top: 4%;
              left: 0%;
              opacity: 1;
              text-align: center;
              z-index: 1;
            }

            .Dot {
              position: absolute;
              font-size: 40px;
              color: #d7d7d7;
              top: 0%;
              left: 47%;
            }

            .title {
              height: .157895rem;
              font-size: 0.118421rem;
              // font-size: 16px;
              margin: 12% 0 2% 0;
              // color: #5f5f5f;
              color: #444444;
            }

            .text {
              // font-weight: 400;
              //  font-size: .0842rem;
              font-size: .1053rem;
              // font-size: 16px;
              line-height: .1316rem;
              padding: 2% 8%;
              // color: #949494;
              color: #b8b8b8;
              text-align: justify;
              text-align-last: left;
              // white-space: pre-line;
              // letter-spacing: -0.0047rem;
              line-height: .1684rem;
            }
          }

          .activeTwo {
            color: #009bde;

            .BgLine {
              height: .0053rem;
              border-top: .0053rem solid #009bde;
              position: absolute;
              top: 14%;
              width: 100%;
            }

            .Dot {
              position: absolute;
              font-size: 40px;
              color: #009bde;
              top: 0%;
              left: 47%;
            }

            .title {
              height: .157895rem;
              font-size: 0.118421rem;
              // font-size: 16px;
              margin: 12% 0 2% 0;
              color: #009bde;
            }

            .text {
              // font-weight: 400;
              text-align: justify;
              text-align-last: left;
              // white-space: pre-line;
              // font-size: .0842rem;
              font-size: .1053rem;
              // font-size: 16px;
              padding: 2% 8%;
              color: #B8B8B8;
              line-height: .1684rem;
            }
          }

          .introduce {
            text-align: center;
            flex-shrink: 0;
            width: 25%;
            cursor: pointer;
            position: relative;

            .logoImg {
              background-color: #fff;
              width: 100%;
              height: .473684rem;
              // line-height: .473684rem;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 4%;
              left: 0%;
              opacity: 1;
              text-align: center;
              z-index: 1;
            }

            .Dot {
              position: absolute;
              font-size: 40px;
              color: #d7d7d7;
              top: 0%;
              left: 47%;
            }

            .title {
              height: .157895rem;
              font-size: 0.118421rem;
              // font-size: 16px;
              margin: 25% 0 2% 0;
              // color: #5f5f5f;
              color: #444444;
            }

            .text {
              text-align: justify;
              text-align-last: left;
              // white-space: pre-line;
              // font-weight: 400;
              //  font-size: .0842rem;
              font-size: .1053rem;
              // font-size: 16px;
              line-height: .1316rem;
              padding: 5% 8%;
              // color: #949494;
              color: #b8b8b8;
              line-height: .1684rem;
            }
          }

          .active {
            color: #009bde;

            .BgLine {
              height: .0053rem;
              border-top: .0053rem solid #009bde;
              position: absolute;
              width: 100%;
            }

            .Dot {
              position: absolute;
              font-size: 40px;
              color: #009bde;
              top: 0%;
              left: 47%;
            }

            .title {
              height: .157895rem;
              font-size: 0.118421rem;
              // font-size: 16px;
              margin: 25% 0 2% 0;
              color: #009bde;
            }

            .text {
              // white-space: pre-line;
              text-align: justify;
              text-align-last: left;
              // font-weight: 400;
              //  font-size: .0842rem;
              font-size: .1053rem;
              // font-size: 16px;
              line-height: .1316rem;
              padding: 5% 8%;
              color: #b8b8b8;
              line-height: .1684rem;
            }
          }
        }
      }

      .changtu {
        width: 70%;
        margin: 0 auto .421053rem;
        text-align: center;

        img {
          width: 100%;
          // display: block;
        }
      }
    }

    .bottomsss {
      width: 100%;
      // position: absolute;
      // bottom: 0;
      // left: 0;
    }
  }
}
</style>

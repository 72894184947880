<template>
    <!-- 自有品牌 -->
    <div class="service">
        <div class="top">
            <Header :activeId="activeId" />
        </div>

        <div class="zhutu">
            <img src="https://img.alicdn.com/imgextra/i3/4045700848/O1CN01gi9P4x1I8QjCZfoN4_!!4045700848.png" alt="" />

            <div class="zhutuability">
                <div>{{ $t("privateLabel.title") }}</div>
                <div>{{ $t("privateLabel.smallTitle") }}</div>
            </div>
        </div>

        <div class="box">
            <div class="box-son">
                <div class="zanjia">
                    <div class="ItemGrouP">
                        <div class="itemHead">
                            <div class="logoLink" @click="LinkClick(1)">
                                <img src="../assets/privateLabel/绽家logo_.png" alt="">
                            </div>
                            <div class="arrowhead">
                                <div class="left" @click="subIndex()"><img src="../assets/privateLabel/左键.png" alt=""></div>
                                <div class="right" @click="addIndex()"><img src="../assets/privateLabel/右键.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="itemBody" @click="LinkClick(1)">
                            <!-- <img src="../assets/privateLabel/商品组.png" alt=""> -->
                            <ul v-if="$store.state.isZh">
                                <li v-for="(item, index) in ImgZang" :key="index">
                                    <div :class="index == ImgActiveIndex ? 'activeGoods' : 'goodsBody'">
                                    </div>
                                </li>
                                <div class="ImgBox">
                                    <img :src="ImgZang[ImgActiveIndex].src" alt="">
                                </div>
                            </ul>
                            <ul v-else>
                                <li v-for="(item, index) in ImgZang2" :key="index">
                                    <div :class="index == ImgActiveIndex ? 'activeGoods' : 'goodsBody'">
                                    </div>
                                </li>
                                <div class="ImgBox">
                                    <img :src="ImgZang2[ImgActiveIndex].src" alt="">
                                </div>
                            </ul>
                        </div>

                    </div>
                    <div class="BgImg" @click="LinkClick(1)">
                        <img src="https://img.alicdn.com/imgextra/i4/4045700848/O1CN01bxIDL21I8QjGdblfu_!!4045700848.png"
                            alt="" v-if="$store.state.isZh">
                        <img src="https://img.alicdn.com/imgextra/i2/4045700848/O1CN012kILJJ1I8QjKMtgj6_!!4045700848.png"
                            alt="" v-else>
                    </div>
                </div>
                <div class="anman">
                    <div class="ItemGrouP">
                        <div class="itemHead">
                            <div class="logoLink" @click="LinkClick(2)">
                                <img src="../assets/privateLabel/安漫logo.png" alt="">
                            </div>
                            <div class="arrowhead">
                                <div class="left" @click="subIndexTwo()"><img src="../assets/privateLabel/左键.png" alt="">
                                </div>
                                <div class="right" @click="addIndexTwo()"><img src="../assets/privateLabel/右键.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="itemBody" @click="LinkClick(2)">
                            <ul v-if="$store.state.isZh">
                                <li v-for="(item, index) in ImgAN" :key="index">
                                    <div :class="index == ImgActiveIndexTwo ? 'activeGoods' : 'goodsBody'">
                                    </div>
                                </li>
                                <div class="ImgBox">
                                    <img :src="ImgAN[ImgActiveIndexTwo].src" alt="">
                                </div>
                            </ul>
                            <ul v-else>
                                <li v-for="(item, index) in ImgAN2" :key="index">
                                    <div :class="index == ImgActiveIndexTwo ? 'activeGoods' : 'goodsBody'">
                                    </div>
                                </li>
                                <div class="ImgBox">
                                    <img :src="ImgAN2[ImgActiveIndexTwo].src" alt="">
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div class="BgImg" @click="LinkClick(2)">
                        <img src="https://img.alicdn.com/imgextra/i3/4045700848/O1CN01vyA8If1I8QjGLITPI_!!4045700848.png"
                            alt="" v-if="$store.state.isZh">
                        <img src="https://img.alicdn.com/imgextra/i4/4045700848/O1CN01Vhu3v01I8QjCGTMRq_!!4045700848.png"
                            alt="" v-else>
                    </div>
                </div>
            </div>

            <div class="bottom">
                <Bottom />
            </div>
        </div>
    </div>
</template>
    
<script>
import Header from "@/components/header/Header.vue";
import Bottom from "@/components/bottomNav/Bottom.vue";
export default {
    components: {
        Header,
        Bottom,
    },
    data() {
        return {
            activeId: 3,
            ImgActiveIndex: 0,
            ImgActiveIndexTwo: 0,
            ImgZang: [
                {
                    "src": require('@/assets/privateLabel/滑块1.png'),
                },
                {
                    "src": require('@/assets/privateLabel/滑块2.png'),
                },
                {
                    "src": require('@/assets/privateLabel/滑块3.png'),
                },
                {
                    "src": require('@/assets/privateLabel/滑块4.png'),
                },
            ],
            ImgZang2: [
                {
                    "src": 'https://img.alicdn.com/imgextra/i3/4045700848/O1CN01K7KdEX1I8QjLSXuk5_!!4045700848.png',
                },
                {
                    "src": 'https://img.alicdn.com/imgextra/i4/4045700848/O1CN01RCSRcK1I8QjH2rsOU_!!4045700848.png',
                },
                {
                    "src": 'https://img.alicdn.com/imgextra/i4/4045700848/O1CN01fiwsRa1I8QjKenkxI_!!4045700848.png',
                },
                {
                    "src": 'https://img.alicdn.com/imgextra/i3/4045700848/O1CN01y1DDg21I8QjCYNtvL_!!4045700848.png',
                },
            ],
            ImgAN: [
                {
                    "src": require('@/assets/privateLabel/轮播1.png'),
                },
                {
                    "src": require('@/assets/privateLabel/轮播2.png'),
                },
                {
                    "src": require('@/assets/privateLabel/轮播3.png'),
                },
            ],
            ImgAN2: [
                {
                    "src": 'https://img.alicdn.com/imgextra/i4/4045700848/O1CN01sPpOxJ1I8QjFnsHSA_!!4045700848.png',
                },
                {
                    "src": 'https://img.alicdn.com/imgextra/i2/4045700848/O1CN01dwfCua1I8QjHsB2iA_!!4045700848.png',
                },
                {
                    "src": 'https://img.alicdn.com/imgextra/i3/4045700848/O1CN01bLueTb1I8QjEijjUN_!!4045700848.png',
                },
            ],
        };
    },
    mounted() {
    },
    methods: {
        LinkClick(n) {
            if (n == 1) {
                window.open('https://zhanjia.tmall.com/shop/view_shop.htm', '_blank')
            } else {
                window.open('https://aromoona.tmall.com/shop/view_shop.htm', '_blank')
            }
        },
        addIndex() {
            if (this.ImgActiveIndex < this.ImgZang.length - 1) {
                this.ImgActiveIndex++
            }
        },
        subIndex() {
            if (this.ImgActiveIndex > 0) {
                this.ImgActiveIndex--
            }
        },
        addIndexTwo() {
            if (this.ImgActiveIndexTwo < this.ImgAN.length - 1) {
                this.ImgActiveIndexTwo++
            }
        },
        subIndexTwo() {
            if (this.ImgActiveIndexTwo > 0) {
                this.ImgActiveIndexTwo--
            }
        },
    }
};
</script>
    
<style scoped lang="less">
.service {
    width: 100%;
    position: relative;

    .top {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
    }

    .zhutu {
        width: 100%;
        position: relative;

        &>img {
            width: 100%;
        }

        .zhutuability {
            // letter-spacing: .0105rem;
            width: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            // right: 0;
            margin: auto;
            text-align: center;

            div:first-child {
                color: rgb(255, 255, 255);
                //  font-size:  .2105rem;
                font-size: .3158rem;
                text-align: center;
                //  font-weight: 500;
                margin-bottom: .052632rem;
            }

            div:last-child {
                color: rgb(255, 255, 255);
                // font-size: .1368rem;
                font-size: .0842rem;
                text-align: center;
            }
        }
    }

    .box {
        height: auto;
        width: 100%;
        position: relative;

        &>img {
            width: 100%;
        }

        .box-son {
            width: 100%;
            position: relative;
            left: 0;
            top: 0;

            .zanjia {
                width: 100%;
                background-color: #fff;


                .ItemGrouP {
                    width: 67%;
                    height: 3.9843rem;
                    margin: 0 auto;
                    padding: .6667rem 0 .54167rem 0;

                    // background-color: red;
                    .itemHead {
                        display: flex;
                        justify-content: space-between;

                        .logoLink {
                            img {
                                width: 2.9934rem;
                            }
                        }

                        .arrowhead {
                            width: .657895rem;
                            display: flex;
                            // flex-direction: row;
                            // flex-wrap: nowrap;
                            justify-content: space-around;
                        }
                    }

                    .itemBody {
                        padding-top: .7566rem;
                        position: relative;

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            justify-content: space-between;

                            li {
                                width: 25%;
                                height: 2rem;
                                text-align: center;


                                .activeGoods {
                                    height: 2rem;
                                    border-bottom: 3px solid rgb(71, 70, 66);
                                }

                                .cc {
                                    width: 100%;
                                    margin-top: .12rem;

                                    img {
                                        width: 100%;
                                        // height: .2rem;
                                    }
                                }

                            }

                            .ImgBox {
                                position: absolute;
                                left: 0;

                                img {
                                    height: 1.6666rem;
                                }
                            }

                        }

                        // img {
                        //     width: 100%;
                        // }
                    }
                }

                .BgImg {
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }
            }

            .anman {
                width: 100%;
                background-color: #fff;


                .ItemGrouP {
                    width: 67%;
                    height: 5.5556rem;
                    margin: 0 auto;
                    padding: .6667rem 0 .54167rem 0;

                    // background-color: red;
                    .itemHead {
                        display: flex;
                        justify-content: space-between;

                        .logoLink {
                            img {
                                height: .3038rem;
                            }
                        }

                        .arrowhead {
                            width: .657895rem;
                            display: flex;
                            // flex-direction: row;
                            // flex-wrap: nowrap;
                            justify-content: space-around;
                        }
                    }

                    .itemBody {
                        padding-top: .7566rem;
                        position: relative;

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            justify-content: space-between;

                            li {
                                width: 25%;
                                height: 2.71rem;
                                text-align: center;


                                .activeGoods {
                                    height: 2.71rem;
                                    border-bottom: 3px solid rgb(71, 70, 66);
                                }

                                .cc {
                                    width: 100%;
                                    margin-top: .12rem;

                                    img {
                                        width: 100%;
                                        // height: .2rem;
                                    }
                                }

                            }

                            .ImgBox {
                                position: absolute;
                                left: 0;

                                img {
                                    height: 2.2875rem;
                                }
                            }
                        }

                        // img {
                        //     width: 100%;
                        // }
                    }
                }

                .BgImg {
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .bottom {
            width: 100%;
            // margin-top: 210px;
            position: relative;
            left: 0;
            bottom: 0;
        }
    }
}
</style>
    